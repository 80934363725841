import { Heading } from "./types";

export const skinnyPants: Heading[] = [
  {
    no: '129',
    pageName: 'Skinny Pants',
    h1: "WOMEN'S SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/',
    type: 'category',
    filter: '',
  },
  {
    no: '130',
    pageName: 'Black',
    h1: "WOMEN'S BLACK SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '131',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '132',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '133',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '134',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '135',
    pageName: 'Work',
    h1: "WOMEN'S WORK SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '136',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '137',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '138',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '139',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '140',
    pageName: 'S',
    h1: "WOMEN'S S SIZE SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '141',
    pageName: 'M',
    h1: "WOMEN'S M SIZE SKINNY PANTS",
    url: 'bottoms/womens-pants/skinny-pants/m/',
    type: 'category',
    filter: 'size',
  },
];
