import { Heading } from "./types";

export const onePieceSwimsuits: Heading[] = [
  {
    no: '26',
    pageName: 'One-Piece',
    h1: "WOMEN'S ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '27',
    pageName: 'Black',
    h1: "WOMEN'S BLACK ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '28',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '29',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '30',
    pageName: 'Pink',
    h1: "WOMEN'S PINK ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '31',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '32',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '33',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '34',
    pageName: 'S',
    h1: "WOMEN'S S SIZE ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '36',
    pageName: 'L',
    h1: "WOMEN'S L SIZE ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '37',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE ONE-PIECE SWIMSUITS",
    url: 'womens-wear/swimwear/one-piece-swimsuits/xl/',
    type: 'category',
    filter: 'size',
  },
];
