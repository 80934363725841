import { Heading } from "./types";

export const wedges: Heading[] = [
  {
    no: 200,
    pageName: 'Wedges',
    h1: "WOMEN'S WEDGES",
    url: 'shoes/wedges/',
    type: 'category',
    filter: '',
  },
  {
    no: 201,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM WEDGES",
    url: 'shoes/wedges/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 202,
    pageName: 'Denim',
    h1: "WOMEN'S DENIM WEDGES",
    url: 'shoes/wedges/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 203,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH WEDGES",
    url: 'shoes/wedges/brunch/',
    type: 'category',
    filter: 'ocassion',
  },
  {
    no: 204,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT WEDGES",
    url: 'shoes/wedges/date-night/',
    type: 'category',
    filter: 'ocassion',
  },
  {
    no: 205,
    pageName: 'Party',
    h1: "WOMEN'S PARTY WEDGES",
    url: 'shoes/wedges/party/',
    type: 'category',
    filter: 'ocassion',
  },
  {
    no: 206,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE WEDGES",
    url: 'shoes/wedges/ankle/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 207,
    pageName: 'Open-Toe',
    h1: "WOMEN'S OPEN TOE WEDGES",
    url: 'shoes/wedges/open-toe/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 208,
    pageName: 'Slip-On',
    h1: "WOMEN'S SLIP ON WEDGES",
    url: 'shoes/wedges/slip-on/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 209,
    pageName: '36',
    h1: "WOMEN'S WEDGES SIZE 36",
    url: 'shoes/wedges/36/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 210,
    pageName: '37',
    h1: "WOMEN'S WEDGES SIZE 37",
    url: 'shoes/wedges/37/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 211,
    pageName: '38',
    h1: "WOMEN'S WEDGES SIZE 38",
    url: 'shoes/wedges/38/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 212,
    pageName: '40',
    h1: "WOMEN'S WEDGES SIZE 40",
    url: 'shoes/wedges/40/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 213,
    pageName: '41',
    h1: "WOMEN'S WEDGES SIZE 41",
    url: 'shoes/wedges/41/',
    type: 'category',
    filter: 'size',
  },
];
