import { Heading } from './types';

export const tops: Heading[] = [
  {
    pageName: 'Shirt',
    h1: "WOMEN'S TOPS",
    url: 'womens-tops/',
    type: 'category',
    filter: '',
  },
  {
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM TOPS",
    url: 'womens-tops/beige-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Black',
    h1: "WOMEN'S BLACK TOPS",
    url: 'womens-tops/black-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Blue',
    h1: "WOMEN'S BLUE TOPS",
    url: 'womens-tops/blue-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Brown',
    h1: "WOMEN'S BROWN TOPS",
    url: 'womens-tops/brown-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Denim',
    h1: "WOMEN'S DENIM TOPS",
    url: 'womens-tops/denim-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE TOPS",
    url: 'womens-tops/gold-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Gold-Rose',
    h1: "WOMEN'S GOLD/ROSE TOPS",
    url: 'womens-tops/gold-rose/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Green',
    h1: "WOMEN'S GREEN TOPS",
    url: 'womens-tops/green-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Grey',
    h1: "WOMEN'S GREY TOPS",
    url: 'womens-tops/grey-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Multicolor',
    h1: "WOMEN'S MULTIcolor TOPS",
    url: 'womens-tops/multicolor-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE TOPS",
    url: 'womens-tops/orange-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Peach',
    h1: "WOMEN'S PEACH TOPS",
    url: 'womens-tops/peach-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Pink',
    h1: "WOMEN'S PINK TOPS",
    url: 'womens-tops/pink-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE TOPS",
    url: 'womens-tops/purple-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Red',
    h1: "WOMEN'S RED TOPS",
    url: 'womens-tops/red-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Silver',
    h1: "WOMEN'S SILVER TOPS",
    url: 'womens-tops/silver-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'White',
    h1: "WOMEN'S WHITE TOPS",
    url: 'womens-tops/white-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW TOPS",
    url: 'womens-tops/yellow-tops/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI TOPS",
    url: 'womens-tops/maxi-tops/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    pageName: 'Mini',
    h1: "WOMEN'S MINI TOPS",
    url: 'womens-tops/mini-tops/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY TOPS",
    url: 'womens-tops/beach-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAIDS & WEDDING GUEST TOPS",
    url: 'womens-tops/bridesmaids-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH TOPS",
    url: 'womens-tops/brunch-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL TOPS",
    url: 'womens-tops/casual-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT TOPS",
    url: 'womens-tops/date-night-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL TOPS",
    url: 'womens-tops/festival-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL TOPS",
    url: 'womens-tops/cocktail-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Homecoming',
    h1: "WOMEN'S HOMECOMING TOPS",
    url: 'womens-tops/homecoming-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Party',
    h1: "WOMEN'S PARTY TOPS",
    url: 'womens-tops/party-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Racing',
    h1: "WOMEN'S RACING TOPS",
    url: 'womens-tops/racing-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    pageName: 'Work',
    h1: "WOMEN'S WORK TOPS",
    url: 'womens-tops/work-tops/',
    type: 'filter/category',
    filter: 'occasion',
  },

  {
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN TOPS",
    url: 'womens-tops/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Spring',
    h1: "WOMEN'S SPRING TOPS",
    url: 'womens-tops/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER TOPS",
    url: 'womens-tops/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Winter',
    h1: "WOMEN'S WINTER TOPS",
    url: 'womens-tops/winter/',
    type: 'category',
    filter: 'season',
  },

  {
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED TOPS",
    url: 'womens-tops/womens-long-sleeve-tops/',
    type: 'filter/category',
    filter: 'Sleeves',
  },
  {
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED TOPS",
    url: 'womens-tops/short-sleeved-tops/',
    type: 'filter/category',
    filter: 'Sleeves',
  },
  {
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS TOPS",
    url: 'womens-tops/sleeveless-tops/',
    type: 'filter/category',
    filter: 'Sleeves',
  },
  {
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT TOPS",
    url: 'womens-tops/animal-print-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS TOPS",
    url: 'womens-tops/backless-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Bandeau',
    h1: "WOMEN'S BANDEAU TOPS",
    url: 'womens-tops/bandeau/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON TOPS",
    url: 'womens-tops/bodycon-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Bomber',
    h1: "WOMEN'S BOMBER TOPS",
    url: 'womens-tops/bomber/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Corset',
    h1: "WOMEN'S CORSET",
    url: 'womens-tops/corset/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT  TOPS",
    url: 'womens-tops/cut-out-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Embellished',
    h1: "WOMEN'S EMBELLISHED TOPS",
    url: 'womens-tops/embellished/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Faux-Fur',
    h1: "WOMEN'S FAUX FUR TOPS",
    url: 'womens-tops/faux-fur/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Faux-Leather',
    h1: "WOMEN'S FAUX LEATHER TOPS",
    url: 'womens-tops/faux-leather/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL TOPS",
    url: 'womens-tops/floral-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Halter',
    h1: "WOMEN'S HALTER TOPS",
    url: 'womens-tops/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Knit',
    h1: "WOMEN'S KNIT TOPS",
    url: 'womens-tops/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Lace',
    h1: "WOMEN'S LACE TOPS",
    url: 'womens-tops/lace-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Mesh',
    h1: "WOMEN'S MESH TOPS",
    url: 'womens-tops/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID TOPS",
    url: 'womens-tops/milkmaid-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER TOPS",
    url: 'womens-tops/off-the-shoulder-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'One-Shoulders',
    h1: "WOMEN'S ONE SHOULDERS TOPS",
    url: 'womens-tops/one-shoulders/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Puffer',
    h1: "WOMEN'S PUFFER TOPS",
    url: 'womens-tops/puffer/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED TOPS",
    url: 'womens-tops/ruched-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Satin',
    h1: "WOMEN'S SATIN TOPS",
    url: 'womens-tops/satin-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN TOPS",
    url: 'womens-tops/sequin-tops/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    pageName: 'Shirt',
    h1: "WOMEN'S SHIRT TOPS",
    url: 'womens-tops/shirt/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Short',
    h1: "WOMEN'S SHORT TOPS",
    url: 'womens-tops/short/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Tench',
    h1: "WOMEN'S TENCH TOPS",
    url: 'womens-tops/tench/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Tweed',
    h1: "WOMEN'S TWEED TOPS",
    url: 'womens-tops/tweed/',
    type: 'category',
    filter: 'trend',
  },

  {
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE TOPS",
    url: 'womens-tops/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'S',
    h1: "WOMEN'S S SIZE TOPS",
    url: 'womens-tops/s/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'M',
    h1: "WOMEN'S M SIZE TOPS",
    url: 'womens-tops/m/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'L',
    h1: "WOMEN'S L SIZE TOPS",
    url: 'womens-tops/l/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE TOPS",
    url: 'womens-tops/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'XXL',
    h1: "WOMEN'S XXL SIZE TOPS",
    url: 'womens-tops/xxl/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'XSS',
    h1: "WOMEN'S XS/S SIZE TOPS",
    url: 'womens-tops/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'S/M',
    h1: "WOMEN'S S/M SIZE TOPS",
    url: 'womens-tops/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'M/L',
    h1: "WOMEN'S M/L SIZE TOPS",
    url: 'womens-tops/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE TOPS",
    url: 'womens-tops/one-size/',
    type: 'category',
    filter: 'size',
  },
];
