import { Heading } from "./types";

export const jackets: Heading[] = [
  {
    no: '253',
    pageName: 'Jackets',
    h1: "WOMEN'S JACKETS",
    url: 'womens-wear/outerwear/jackets/',
    type: 'category',
    filter: '',
  },
  {
    no: '254',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM JACKETS",
    url: 'womens-wear/outerwear/jackets/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '255',
    pageName: 'Black',
    h1: "WOMEN'S BLACK JACKETS",
    url: 'womens-wear/outerwear/jackets/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '256',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE JACKETS",
    url: 'womens-wear/outerwear/jackets/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '257',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN JACKETS",
    url: 'womens-wear/outerwear/jackets/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '258',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM JACKETS",
    url: 'womens-wear/outerwear/jackets/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '259',
    pageName: 'Green',
    h1: "WOMEN'S GREEN JACKETS",
    url: 'womens-wear/outerwear/jackets/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '260',
    pageName: 'Grey',
    h1: "WOMEN'S GREY JACKETS",
    url: 'womens-wear/outerwear/jackets/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '261',
    pageName: 'Pink',
    h1: "WOMEN'S PINK JACKETS",
    url: 'womens-wear/outerwear/jackets/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '262',
    pageName: 'White',
    h1: "WOMEN'S WHITE JACKETS",
    url: 'womens-wear/outerwear/jackets/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '263',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST JACKETS",
    url: 'womens-wear/outerwear/jackets/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '264',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH JACKETS",
    url: 'womens-wear/outerwear/jackets/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '265',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL JACKETS",
    url: 'womens-wear/outerwear/jackets/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '266',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT JACKETS",
    url: 'womens-wear/outerwear/jackets/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '267',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL JACKETS",
    url: 'womens-wear/outerwear/jackets/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '268',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL JACKETS",
    url: 'womens-wear/outerwear/jackets/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '269',
    pageName: 'Party',
    h1: "WOMEN'S PARTY JACKETS",
    url: 'womens-wear/outerwear/jackets/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '270',
    pageName: 'Work',
    h1: "WOMEN'S WORK JACKETS",
    url: 'womens-wear/outerwear/jackets/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '271',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN JACKETS",
    url: 'womens-wear/outerwear/jackets/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '272',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING JACKETS",
    url: 'womens-wear/outerwear/jackets/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '273',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER JACKETS",
    url: 'womens-wear/outerwear/jackets/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '274',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER JACKETS",
    url: 'womens-wear/outerwear/jackets/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '275',
    pageName: 'Bomber',
    h1: "WOMEN'S BOMBER JACKETS",
    url: 'womens-wear/outerwear/jackets/bomber/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '276',
    pageName: 'Faux-Leather',
    h1: "WOMEN'S FAUX LEATHER JACKETS",
    url: 'womens-wear/outerwear/jackets/faux-leather/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '277',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL JACKETS",
    url: 'womens-wear/outerwear/jackets/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '278',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY JACKETS",
    url: 'womens-wear/outerwear/jackets/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '279',
    pageName: 'Long',
    h1: "WOMEN'S LONG JACKETS",
    url: 'womens-wear/outerwear/jackets/long/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '280',
    pageName: 'Puffer',
    h1: "WOMEN'S PUFFER JACKETS",
    url: 'womens-wear/outerwear/jackets/puffer/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '281',
    pageName: 'Trench',
    h1: "WOMEN'S TRENCH JACKETS",
    url: 'womens-wear/outerwear/jackets/trench/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '282',
    pageName: 'Tweed',
    h1: "WOMEN'S TWEED JACKETS",
    url: 'womens-wear/outerwear/jackets/tweed/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '283',
    pageName: 'Wool',
    h1: "WOMEN'S WOOL JACKETS",
    url: 'womens-wear/outerwear/jackets/wool/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '284',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '285',
    pageName: 'S',
    h1: "WOMEN'S S SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '286',
    pageName: 'M',
    h1: "WOMEN'S M SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '287',
    pageName: 'L',
    h1: "WOMEN'S L SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '288',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '289',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '290',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE JACKETS",
    url: 'womens-wear/outerwear/jackets/m-l/',
    type: 'category',
    filter: 'size',
  },
];
