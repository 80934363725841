import { Heading } from "./types";

export const sneakers: Heading[] = [
  {
    no: 234,
    pageName: 'Sneakers',
    h1: "WOMEN'S SNEAKERS",
    url: 'shoes/sneakers/',
    type: 'category',
    filter: '',
  },
  {
    no: 235,
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR SNEAKERS",
    url: 'shoes/sneakers/multicolour-sneakers',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 236,
    pageName: 'White',
    h1: "WOMEN'S WHITE  SNEAKERS",
    url: 'shoes/sneakers/white-sneakers',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 237,
    pageName: 'Beach',
    h1: "WOMEN'S BEACH & HOLIDAY SNEAKERS",
    url: 'shoes/sneakers/beach-sneakers/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 238,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SNEAKERS",
    url: 'shoes/sneakers/brunch-sneakers',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 239,
    pageName: 'Work',
    h1: "WOMEN'S WORK SNEAKERS",
    url: 'shoes/sneakers/work-sneakers',
    type: 'filter/category',
    filter: 'occasion',
  },
];
