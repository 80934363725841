import { Heading } from "./types";

export const sandals: Heading[] = [
  {
    no: 183,
    pageName: 'Sandals',
    h1: "WOMEN'S SANDALS",
    url: 'shoes/sandals/',
    type: 'category',
    filter: '',
  },
  {
    no: 184,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM SANDALS",
    url: 'shoes/sandals/beige-sandals/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 185,
    pageName: 'Black',
    h1: "WOMEN'S BLACK SANDALS",
    url: 'shoes/sandals/black-sandals/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 186,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN SANDALS",
    url: 'shoes/sandals/brown-sandals/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 187,
    pageName: 'Denim',
    h1: "WOMEN'S DENIM SANDALS",
    url: 'shoes/sandals/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 188,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SANDALS",
    url: 'shoes/sandals/brunch-sandals/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 189,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SANDALS",
    url: 'shoes/sandals/beach-sandals/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 190,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT SANDALS",
    url: 'shoes/sandals/date-night-sandals/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 191,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SANDALS",
    url: 'shoes/sandals/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 192,
    pageName: '9',
    h1: "WOMEN'S SANDALS SIZED 9",
    url: 'shoes/sandals/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 193,
    pageName: '8',
    h1: "WOMEN'S SANDALS SIZED 8",
    url: 'shoes/sandals/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 194,
    pageName: '10',
    h1: "WOMEN'S SANDALS SIZED 10",
    url: 'shoes/sandals/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 195,
    pageName: '6',
    h1: "WOMEN'S SANDALS SIZED 6",
    url: 'shoes/sandals/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 196,
    pageName: '7',
    h1: "WOMEN'S SANDALS SIZED 7",
    url: 'shoes/sandals/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 197,
    pageName: '5',
    h1: "WOMEN'S SANDALS SIZED 5",
    url: 'shoes/sandals/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 198,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE SANDALS",
    url: 'shoes/sandals/ankle/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 199,
    pageName: 'Slip-On',
    h1: "WOMEN'S SLIP ON SANDALS",
    url: 'shoes/sandals/slip-on/',
    type: 'category',
    filter: 'trend',
  },
];
