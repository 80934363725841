import { Heading } from './types';

export const bracelets: Heading[] = [
  {
    no: '116',
    pageName: 'Bracelets',
    h1: "WOMEN'S BRACELETS",
    url: 'accessories/bracelets/',
    type: 'category',
    filter: '',
  },
  {
    no: '117',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BRACELETS",
    url: 'accessories/bracelets/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '118',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL BRACELETS",
    url: 'accessories/bracelets/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '119',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BRACELETS",
    url: 'accessories/bracelets/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '120',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BRACELETS",
    url: 'accessories/bracelets/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '121',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BRACELETS",
    url: 'accessories/bracelets/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '122',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BRACELETS",
    url: 'accessories/bracelets/summer/',
    type: 'category',
    filter: 'season',
  },
];
