import { Heading } from "./types";

export const skirt: Heading[] = [
  {
    no: '2',
    pageName: 'Skirts',
    h1: 'SKIRTS',
    url: 'bottoms/skirts',
    type: 'category',
    filter: '',
  },
  {
    no: '3',
    pageName: 'Beige-Cream',
    h1: 'BEIGE/CREAM SKIRTS',
    url: 'bottoms/skirts/beige-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '4',
    pageName: 'Black',
    h1: 'BLACK SKIRTS',
    url: 'bottoms/skirts/black-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '5',
    pageName: 'Blue',
    h1: 'BLUE SKIRTS',
    url: 'bottoms/skirts/blue-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '6',
    pageName: 'Brown',
    h1: 'BROWN SKIRTS',
    url: 'bottoms/skirts/brown-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '7',
    pageName: 'Denim',
    h1: 'DENIM SKIRTS',
    url: 'bottoms/skirts/denim-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '8',
    pageName: 'Gold-Brontypee',
    h1: 'GOLD/BRONtypeE SKIRTS',
    url: 'bottoms/skirts/gold-brontypee/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '9',
    pageName: 'Gold-Rose',
    h1: 'GOLD/ROSE SKIRTS',
    url: 'bottoms/skirts/gold-rose/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '10',
    pageName: 'Green',
    h1: 'GREEN SKIRTS',
    url: 'bottoms/skirts/green-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '11',
    pageName: 'Grey',
    h1: 'GREY SKIRTS',
    url: 'bottoms/skirts/grey-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '12',
    pageName: 'Multicolour',
    h1: 'MULTICOLOUR SKIRTS',
    url: 'bottoms/skirts/multicolour-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '13',
    pageName: 'Orange',
    h1: 'ORANGE SKIRTS',
    url: 'bottoms/skirts/orange-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '14',
    pageName: 'Pink',
    h1: 'PINK SKIRTS',
    url: 'bottoms/skirts/pink-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '15',
    pageName: 'Purple',
    h1: 'PURPLE SKIRTS',
    url: 'bottoms/skirts/purple-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '16',
    pageName: 'Red',
    h1: 'RED SKIRTS',
    url: 'bottoms/skirts/red-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '17',
    pageName: 'Silver',
    h1: 'SILVER SKIRTS',
    url: 'bottoms/skirts/silver-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '18',
    pageName: 'White',
    h1: 'WHITE SKIRTS',
    url: 'bottoms/skirts/white-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '19',
    pageName: 'Yellow',
    h1: 'YELLOW SKIRTS',
    url: 'bottoms/skirts/yellow-skirts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '20',
    pageName: 'Maxi',
    h1: 'MAXI SKIRTS',
    url: 'bottoms/skirts/maxi-skirts/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    no: '21',
    pageName: 'Mini',
    h1: 'MINI SKIRTS',
    url: 'bottoms/skirts/mini-skirts/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    no: '22',
    pageName: 'Midi',
    h1: 'MIDI SKIRTS',
    url: 'bottoms/skirts/midi-skirts/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    no: '23',
    pageName: 'Beach-&-Holiday',
    h1: 'BEACH & HOLIDAY SKIRTS',
    url: 'bottoms/skirts/beach-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '24',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: 'BRIDESMAIDS & WEDDING GUEST SKIRTS',
    url: 'bottoms/skirts/bridesmaids-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '25',
    pageName: 'Brunch',
    h1: 'BRUNCH SKIRTS',
    url: 'bottoms/skirts/brunch-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '26',
    pageName: 'Casual',
    h1: 'CASUAL SKIRTS',
    url: 'bottoms/skirts/casual-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '27',
    pageName: 'Date-Night',
    h1: 'DATE NIGHT SKIRTS',
    url: 'bottoms/skirts/date-night-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '28',
    pageName: 'Festival',
    h1: 'FESTIVAL SKIRTS',
    url: 'bottoms/skirts/festival-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '29',
    pageName: 'Formal-&-Cocktail',
    h1: 'FORMAL & COCKTAIL SKIRTS',
    url: 'bottoms/skirts/cocktail-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '30',
    pageName: 'Homecoming',
    h1: 'HOMECOMING SKIRTS',
    url: 'bottoms/skirts/homecoming-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '31',
    pageName: 'Party',
    h1: 'PARTY SKIRTS',
    url: 'bottoms/skirts/party-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '32',
    pageName: 'Racing',
    h1: 'RACING SKIRTS',
    url: 'bottoms/skirts/racing-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '33',
    pageName: 'Spring-Break',
    h1: 'SPRING BREAK SKIRTS',
    url: 'bottoms/skirts/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '34',
    pageName: 'Work',
    h1: 'WORK SKIRTS',
    url: 'bottoms/skirts/work-skirts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '35',
    pageName: 'Autumn',
    h1: 'AUTUMN SKIRTS',
    url: 'bottoms/skirts/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '36',
    pageName: 'Spring',
    h1: 'SPRING SKIRTS',
    url: 'bottoms/skirts/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '37',
    pageName: 'Summer',
    h1: 'SUMMER SKIRTS',
    url: 'bottoms/skirts/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '38',
    pageName: 'Winter',
    h1: 'WINTER SKIRTS',
    url: 'bottoms/skirts/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '39',
    pageName: 'Long-Sleeved',
    h1: 'LONG SLEEVED SKIRTS',
    url: 'bottoms/skirts/long-sleeved/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    no: '40',
    pageName: 'Short-Sleeved',
    h1: 'SHORT SLEEVED SKIRTS',
    url: 'bottoms/skirts/short-sleeved/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    no: '41',
    pageName: 'Sleeveless',
    h1: 'SLEEVELESS SKIRTS',
    url: 'bottoms/skirts/sleeveless/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    no: '42',
    pageName: 'A-Line',
    h1: 'A LINE SKIRTS',
    url: 'bottoms/skirts/a-line/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '43',
    pageName: 'Animal-Prints',
    h1: 'ANIMAL PRINT SKIRTS',
    url: 'bottoms/skirts/animal-print-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '44',
    pageName: 'Bodycon',
    h1: 'BODYCON SKIRTS',
    url: 'bottoms/skirts/bodycon-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '45',
    pageName: 'Cut-Out',
    h1: 'CUT OUT SKIRTS',
    url: 'bottoms/skirts/cut-out-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '46',
    pageName: 'Floral',
    h1: 'FLORAL SKIRTS',
    url: 'bottoms/skirts/floral-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '47',
    pageName: 'Flowy',
    h1: 'FLOWY SKIRTS',
    url: 'bottoms/skirts/flowy-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '48',
    pageName: 'Knit',
    h1: 'KNIT SKIRTS',
    url: 'bottoms/skirts/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '49',
    pageName: 'Lace',
    h1: 'LACE SKIRTS',
    url: 'bottoms/skirts/lace-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '50',
    pageName: 'Low-Waisted',
    h1: 'LOW WAISTED SKIRTS',
    url: 'bottoms/skirts/low-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '51',
    pageName: 'Mesh',
    h1: 'MESH SKIRTS',
    url: 'bottoms/skirts/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '52',
    pageName: 'Mid-Waisted',
    h1: 'MID WAISTED SKIRTS',
    url: 'bottoms/skirts/mid-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '53',
    pageName: 'Ruched',
    h1: 'RUCHED SKIRTS',
    url: 'bottoms/skirts/ruched-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '54',
    pageName: 'Satin',
    h1: 'SATIN SKIRTS',
    url: 'bottoms/skirts/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '55',
    pageName: 'Sequin',
    h1: 'SEQUIN SKIRTS',
    url: 'bottoms/skirts/sequin-skirts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '56',
    pageName: 'XS',
    h1: 'XS SIZE SKIRTS',
    url: 'bottoms/skirts/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '57',
    pageName: 'S',
    h1: 'S SIZE SKIRTS',
    url: 'bottoms/skirts/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '58',
    pageName: 'M',
    h1: 'M SIZE SKIRTS',
    url: 'bottoms/skirts/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '59',
    pageName: 'L',
    h1: 'L SIZE SKIRTS',
    url: 'bottoms/skirts/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '60',
    pageName: 'XL',
    h1: 'XL SIZE SKIRTS',
    url: 'bottoms/skirts/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '61',
    pageName: 'XXL',
    h1: 'XXL SIZE SKIRTS',
    url: 'bottoms/skirts/xxl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '62',
    pageName: 'XS-S',
    h1: 'XS/S SIZE SKIRTS',
    url: 'bottoms/skirts/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '63',
    pageName: 'S-M',
    h1: 'S/M SIZE SKIRTS',
    url: 'bottoms/skirts/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '64',
    pageName: 'M-L',
    h1: 'M/L SIZE SKIRTS',
    url: 'bottoms/skirts/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '65',
    pageName: '6',
    h1: '6 SIZE SKIRTS',
    url: 'bottoms/skirts/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '66',
    pageName: '8',
    h1: '8 SIZE SKIRTS',
    url: 'bottoms/skirts/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '67',
    pageName: '10',
    h1: '10 SIZE SKIRTS',
    url: 'bottoms/skirts/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '68',
    pageName: '25',
    h1: '25 SIZE SKIRTS',
    url: 'bottoms/skirts/25/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '69',
    pageName: '27',
    h1: '27 SIZE SKIRTS',
    url: 'bottoms/skirts/27/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '70',
    pageName: '28',
    h1: '28 SIZE SKIRTS',
    url: 'bottoms/skirts/28/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '71',
    pageName: '29',
    h1: '29 SIZE SKIRTS',
    url: 'bottoms/skirts/29/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '72',
    pageName: '30',
    h1: '30 SIZE SKIRTS',
    url: 'bottoms/skirts/30/',
    type: 'category',
    filter: 'size',
  },
];
