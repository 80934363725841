import { Heading } from "./types";

export const casualWear: Heading[] = [
  {
    no: '408',
    pageName: 'Casual Wear',
    h1: "WOMEN'S CASUAL WEAR",
    url: 'womens-wear/casual-wear/',
    type: 'category',
    filter: '',
  },
  {
    no: '409',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM CASUAL WEAR",
    url: 'womens-wear/casual-wear/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '410',
    pageName: 'Black',
    h1: "WOMEN'S BLACK CASUAL WEAR",
    url: 'womens-wear/casual-wear/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '411',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE CASUAL WEAR",
    url: 'womens-wear/casual-wear/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '412',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN CASUAL WEAR",
    url: 'womens-wear/casual-wear/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '413',
    pageName: 'Clear',
    h1: "WOMEN'S CLEAR CASUAL WEAR",
    url: 'womens-wear/casual-wear/clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '414',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM CASUAL WEAR",
    url: 'womens-wear/casual-wear/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '415',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '416',
    pageName: 'Green',
    h1: "WOMEN'S GREEN CASUAL WEAR",
    url: 'womens-wear/casual-wear/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '417',
    pageName: 'Grey',
    h1: "WOMEN'S GREY CASUAL WEAR",
    url: 'womens-wear/casual-wear/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '418',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR CASUAL WEAR",
    url: 'womens-wear/casual-wear/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '419',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE CASUAL WEAR",
    url: 'womens-wear/casual-wear/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '420',
    pageName: 'Pink',
    h1: "WOMEN'S PINK CASUAL WEAR",
    url: 'womens-wear/casual-wear/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '421',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE CASUAL WEAR",
    url: 'womens-wear/casual-wear/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '422',
    pageName: 'Red',
    h1: "WOMEN'S RED CASUAL WEAR",
    url: 'womens-wear/casual-wear/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '423',
    pageName: 'Rust',
    h1: "WOMEN'S RUST CASUAL WEAR",
    url: 'womens-wear/casual-wear/rust/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '424',
    pageName: 'White',
    h1: "WOMEN'S WHITE CASUAL WEAR",
    url: 'womens-wear/casual-wear/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '425',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW CASUAL WEAR",
    url: 'womens-wear/casual-wear/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '426',
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI CASUAL WEAR",
    url: 'womens-wear/casual-wear/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '427',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI CASUAL WEAR",
    url: 'womens-wear/casual-wear/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '428',
    pageName: 'Mini',
    h1: "WOMEN'S MINI CASUAL WEAR",
    url: 'womens-wear/casual-wear/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '429',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY CASUAL WEAR",
    url: 'womens-wear/casual-wear/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '430',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST CASUAL WEAR",
    url: 'womens-wear/casual-wear/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '431',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH CASUAL WEAR",
    url: 'womens-wear/casual-wear/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '432',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT CASUAL WEAR",
    url: 'womens-wear/casual-wear/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '433',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL CASUAL WEAR",
    url: 'womens-wear/casual-wear/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '434',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL CASUAL WEAR",
    url: 'womens-wear/casual-wear/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '435',
    pageName: 'Party',
    h1: "WOMEN'S PARTY CASUAL WEAR",
    url: 'womens-wear/casual-wear/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '436',
    pageName: 'Racing',
    h1: "WOMEN'S RACING CASUAL WEAR",
    url: 'womens-wear/casual-wear/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '437',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK CASUAL WEAR",
    url: 'womens-wear/casual-wear/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '438',
    pageName: 'Work',
    h1: "WOMEN'S WORK CASUAL WEAR",
    url: 'womens-wear/casual-wear/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '439',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN CASUAL WEAR",
    url: 'womens-wear/casual-wear/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '440',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING CASUAL WEAR",
    url: 'womens-wear/casual-wear/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '441',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER CASUAL WEAR",
    url: 'womens-wear/casual-wear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '442',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER CASUAL WEAR",
    url: 'womens-wear/casual-wear/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '443',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED CASUAL WEAR",
    url: 'womens-wear/casual-wear/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '444',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED CASUAL WEAR",
    url: 'womens-wear/casual-wear/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '445',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS CASUAL WEAR",
    url: 'womens-wear/casual-wear/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '446',
    pageName: 'A-Line',
    h1: "WOMEN'S A LINE CASUAL WEAR",
    url: 'womens-wear/casual-wear/a-line/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '447',
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMA PRINT CASUAL WEAR",
    url: 'womens-wear/casual-wear/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '448',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS CASUAL WEAR",
    url: 'womens-wear/casual-wear/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '449',
    pageName: 'Bandeau',
    h1: "WOMEN'S BANDEAU CASUAL WEAR",
    url: 'womens-wear/casual-wear/bandeau/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '450',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON CASUAL WEAR",
    url: 'womens-wear/casual-wear/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '451',
    pageName: 'Corset',
    h1: "WOMEN'S CORSET CASUAL WEAR",
    url: 'womens-wear/casual-wear/corset/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '452',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT CASUAL WEAR",
    url: 'womens-wear/casual-wear/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '453',
    pageName: 'Embroidered',
    h1: "WOMEN'S EMBROIDERED CASUAL WEAR",
    url: 'womens-wear/casual-wear/embroidered/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '454',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL CASUAL WEAR",
    url: 'womens-wear/casual-wear/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '455',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY CASUAL WEAR",
    url: 'womens-wear/casual-wear/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '456',
    pageName: 'Gingham',
    h1: "WOMEN'S GINGHAM CASUAL WEAR",
    url: 'womens-wear/casual-wear/gingham/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '457',
    pageName: 'Halter',
    h1: "WOMEN'S HALTER CASUAL WEAR",
    url: 'womens-wear/casual-wear/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '458',
    pageName: 'High-Waisted',
    h1: "WOMEN'S HIGH WAISTED CASUAL WEAR",
    url: 'womens-wear/casual-wear/high-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '459',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT CASUAL WEAR",
    url: 'womens-wear/casual-wear/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '460',
    pageName: 'Lace',
    h1: "WOMEN'S LACE CASUAL WEAR",
    url: 'womens-wear/casual-wear/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '461',
    pageName: 'Mesh',
    h1: "WOMEN'S MESH CASUAL WEAR",
    url: 'womens-wear/casual-wear/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '462',
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID CASUAL WEAR",
    url: 'womens-wear/casual-wear/milkmaid/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '463',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER CASUAL WEAR",
    url: 'womens-wear/casual-wear/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '464',
    pageName: 'One-Shoulder',
    h1: "WOMEN'S ONE SHOULDER CASUAL WEAR",
    url: 'womens-wear/casual-wear/one-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '465',
    pageName: 'Puffer',
    h1: "WOMEN'S PUFFER CASUAL WEAR",
    url: 'womens-wear/casual-wear/puffer/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '466',
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED CASUAL WEAR",
    url: 'womens-wear/casual-wear/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '467',
    pageName: 'Satin',
    h1: "WOMEN'S SATIN CASUAL WEAR",
    url: 'womens-wear/casual-wear/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '468',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN CASUAL WEAR",
    url: 'womens-wear/casual-wear/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '469',
    pageName: 'Shift',
    h1: "WOMEN'S SHIFT CASUAL WEAR",
    url: 'womens-wear/casual-wear/shift/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '470',
    pageName: 'Slip',
    h1: "WOMEN'S SLIP CASUAL WEAR",
    url: 'womens-wear/casual-wear/slip/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '471',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '472',
    pageName: 'S',
    h1: "WOMEN'S S SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '473',
    pageName: 'M',
    h1: "WOMEN'S M SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '474',
    pageName: 'L',
    h1: "WOMEN'S L SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '475',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '476',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '477',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '478',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '479',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE CASUAL WEAR",
    url: 'womens-wear/casual-wear/one-size/',
    type: 'category',
    filter: 'size',
  },
];
