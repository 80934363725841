import { Heading } from "./types";

export const womensJumpers: Heading[] = [
  {
    no: '148',
    pageName: 'Jumpers',
    h1: "WOMEN'S JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/',
    type: 'category',
    filter: '',
  },
  {
    no: '149',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '150',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '151',
    pageName: 'Green',
    h1: "WOMEN'S GREEN JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '152',
    pageName: 'Pink',
    h1: "WOMEN'S PINK JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '153',
    pageName: 'White',
    h1: "WOMEN'S WHITE JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '154',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '155',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '156',
    pageName: 'Party',
    h1: "WOMEN'S PARTY JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '157',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '158',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '159',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '160',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '161',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '162',
    pageName: 'S',
    h1: "WOMEN'S S SIZE JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '163',
    pageName: 'M',
    h1: "WOMEN'S M SIZE JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '164',
    pageName: 'L',
    h1: "WOMEN'S L SIZE JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '165',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE JUMPERS",
    url: 'womens-wear/knitwear/womens-jumpers/s-m/',
    type: 'category',
    filter: 'size',
  },
];
