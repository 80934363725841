import { Heading } from "./types";

export const basicsClothing: Heading[] = [
  {
    no: '480',
    pageName: 'Basics',
    h1: "WOMEN'S BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/',
    type: 'category',
    filter: '',
  },
  {
    no: '481',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '482',
    pageName: 'Black',
    h1: "WOMEN'S BLACK BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '483',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '484',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '485',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '486',
    pageName: 'Green',
    h1: "WOMEN'S GREEN BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '487',
    pageName: 'Grey',
    h1: "WOMEN'S GREY BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '488',
    pageName: 'Pink',
    h1: "WOMEN'S PINK BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '489',
    pageName: 'White',
    h1: "WOMEN'S WHITE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '490',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '491',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '492',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '493',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '494',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '495',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '496',
    pageName: 'Party',
    h1: "WOMEN'S PARTY BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '497',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '498',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '499',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '500',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '501',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '502',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '503',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '504',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '505',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '506',
    pageName: 'Bandeau',
    h1: "WOMEN'S BANDEAU BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/bandeau/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '507',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '508',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '509',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '510',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '511',
    pageName: 'Mesh',
    h1: "WOMEN'S MESH BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '512',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '513',
    pageName: 'Shirt',
    h1: "WOMEN'S SHIRT BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/shirt/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '514',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '515',
    pageName: 'S',
    h1: "WOMEN'S S SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '516',
    pageName: 'M',
    h1: "WOMEN'S M SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '517',
    pageName: 'L',
    h1: "WOMEN'S L SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '518',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '519',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '520',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE BASICS CLOTHING",
    url: 'womens-wear/basics-clothing/s-m/',
    type: 'category',
    filter: 'size',
  },
];
