import { Heading } from "./types";

export const playsuits: Heading[] = [
  {
    no: 1,
    pageName: 'Playsuits',
    h1: "WOMEN'S PLAYSUITS",
    url: 'playsuits/',
    type: 'category',
    filter: '',
  },
  {
    no: 2,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM PLAYSUITS",
    url: 'playsuits/beige-cream/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 3,
    pageName: 'Black',
    h1: "WOMEN'S BLACK PLAYSUITS",
    url: 'playsuits/black-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 4,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE PLAYSUITS",
    url: 'playsuits/blue-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 5,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN PLAYSUITS",
    url: 'playsuits/brown-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 6,
    pageName: 'Denim',
    h1: 'DENIM PLAYSUITS',
    url: 'playsuits/denim-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 7,
    pageName: 'Green',
    h1: "WOMEN'S GREEN PLAYSUITS",
    url: 'playsuits/green-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 8,
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR PLAYSUITS",
    url: 'playsuits/multicolour-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 9,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE PLAYSUITS",
    url: 'playsuits/orange-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 10,
    pageName: 'Pink',
    h1: "WOMEN'S PINK PLAYSUITS",
    url: 'playsuits/pink-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 11,
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE PLAYSUITS",
    url: 'playsuits/purple-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 12,
    pageName: 'Red',
    h1: "WOMEN'S RED PLAYSUITS",
    url: 'playsuits/red-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 13,
    pageName: 'White',
    h1: "WOMEN'S WHITE PLAYSUITS",
    url: 'playsuits/white-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 14,
    pageName: 'Rust',
    h1: "WOMEN'S RUST PLAYSUITS",
    url: 'playsuits/rust-playsuits/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 15,
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW PLAYSUITS",
    url: 'playsuits/yellow-playsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 16,
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI PLAYSUITS",
    url: 'playsuits/maxi-playsuits/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 17,
    pageName: 'Midi',
    h1: "WOMEN'S MIDI PLAYSUITS",
    url: 'playsuits/midi-playsuits/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 18,
    pageName: 'Mini',
    h1: "WOMEN'S MINI PLAYSUITS",
    url: 'playsuits/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 19,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY PLAYSUITS",
    url: 'playsuits/beach-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 20,
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST PLAYSUITS",
    url: 'playsuits/bridesmaid-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 21,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH PLAYSUITS",
    url: 'playsuits/brunch-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 22,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL PLAYSUITS",
    url: 'playsuits/casual-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 23,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT PLAYSUITS",
    url: 'playsuits/date-night-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 24,
    pageName: 'Day',
    h1: "WOMEN'S DAY PLAYSUITS",
    url: 'playsuits/day-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 25,
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL PLAYSUITS",
    url: 'playsuits/festival-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 26,
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL PLAYSUITS",
    url: 'playsuits/formal-cocktail-playsuits/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 27,
    pageName: 'Party',
    h1: "WOMEN'S PARTY PLAYSUITS",
    url: 'playsuits/party-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 28,
    pageName: 'Racing',
    h1: "WOMEN'S RACING PLAYSUITS",
    url: 'playsuits/racing-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 29,
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK PLAYSUITS",
    url: 'playsuits/spring-break-playsuits/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 30,
    pageName: 'Printed',
    h1: "WOMEN'S PRINTED PLAYSUITS",
    url: 'playsuits/printed-playsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 31,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN PLAYSUITS",
    url: 'playsuits/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 32,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING PLAYSUITS",
    url: 'playsuits/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 33,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER PLAYSUITS",
    url: 'playsuits/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 34,
    pageName: 'Winter',
    h1: "WOMEN'S WINTER PLAYSUITS",
    url: 'playsuits/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 35,
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED PLAYSUITS",
    url: 'playsuits/long-sleeve-playsuits/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 36,
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED PLAYSUITS",
    url: 'playsuits/short-sleeved-playsuits/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 37,
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS PLAYSUITS",
    url: 'playsuits/sleeveless-playsuits/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 38,
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT PLAYSUITS",
    url: 'playsuits/animal-print-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 39,
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS PLAYSUITS",
    url: 'playsuits/backless-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 40,
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON PLAYSUITS",
    url: 'playsuits/bodycon-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 41,
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT PLAYSUITS",
    url: 'playsuits/cut-out-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 42,
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL PLAYSUITS",
    url: 'playsuits/floral-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 43,
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY PLAYSUITS",
    url: 'playsuits/flowy-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 44,
    pageName: 'Lace',
    h1: "WOMEN'S LACE PLAYSUITS",
    url: 'playsuits/lace-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 45,
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID PLAYSUITS",
    url: 'playsuits/milkmaid-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 46,
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER PLAYSUITS",
    url: 'playsuits/off-the-shoulder-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 47,
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN PLAYSUITS",
    url: 'playsuits/sequin-playsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 48,
    pageName: 'Satin',
    h1: "WOMEN'S SATIN PLAYSUITS",
    url: 'playsuits/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 49,
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE PLAYSUITS",
    url: 'playsuits/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 50,
    pageName: 'S',
    h1: "WOMEN'S S SIZE PLAYSUITS",
    url: 'playsuits/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 51,
    pageName: 'M',
    h1: "WOMEN'S M SIZE PLAYSUITS",
    url: 'playsuits/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 52,
    pageName: 'L',
    h1: "WOMEN'S L SIZE PLAYSUITS",
    url: 'playsuits/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 53,
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE PLAYSUITS",
    url: 'playsuits/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 54,
    pageName: '6',
    h1: "WOMEN'S 6 SIZE PLAYSUITS",
    url: 'playsuits/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 55,
    pageName: '8',
    h1: "WOMEN'S 8 SIZE PLAYSUITS",
    url: 'playsuits/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 56,
    pageName: '10',
    h1: "WOMEN'S 10 SIZE PLAYSUITS",
    url: 'playsuits/10/',
    type: 'category',
    filter: 'size',
  },
];
