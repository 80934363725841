import { Heading } from './types';

export const jewellery: Heading[] = [
  {
    no: '40',
    pageName: 'Jewellery',
    h1: "WOMEN'S JEWELLERY",
    url: 'accessories/jewellery/',
    type: 'category',
    filter: '',
  },
  {
    no: '41',
    pageName: 'Black',
    h1: "WOMEN'S BLACK JEWELLERY",
    url: 'accessories/jewellery/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '42',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE JEWELLERY",
    url: 'accessories/jewellery/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '43',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN JEWELLERY",
    url: 'accessories/jewellery/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '44',
    pageName: 'Clear',
    h1: "WOMEN'S CLEAR JEWELLERY",
    url: 'accessories/jewellery/clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '45',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE JEWELLERY",
    url: 'accessories/jewellery/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '46',
    pageName: 'Gold-Clear',
    h1: "WOMEN'S GOLD/CLEAR JEWELLERY",
    url: 'accessories/jewellery/gold-clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '47',
    pageName: 'Green',
    h1: "WOMEN'S GREEN JEWELLERY",
    url: 'accessories/jewellery/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '48',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR JEWELLERY",
    url: 'accessories/jewellery/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '49',
    pageName: 'Pink',
    h1: "WOMEN'S PINK JEWELLERY",
    url: 'accessories/jewellery/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '50',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE JEWELLERY",
    url: 'accessories/jewellery/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '51',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER JEWELLERY",
    url: 'accessories/jewellery/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '52',
    pageName: 'White',
    h1: "WOMEN'S WHITE JEWELLERY",
    url: 'accessories/jewellery/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '53',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY JEWELLERY",
    url: 'accessories/jewellery/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '54',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST JEWELLERY",
    url: 'accessories/jewellery/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '55',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH JEWELLERY",
    url: 'accessories/jewellery/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '56',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL JEWELLERY",
    url: 'accessories/jewellery/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '57',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT JEWELLERY",
    url: 'accessories/jewellery/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '58',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL JEWELLERY",
    url: 'accessories/jewellery/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '59',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL JEWELLERY",
    url: 'accessories/jewellery/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '60',
    pageName: 'Party',
    h1: "WOMEN'S PARTY JEWELLERY",
    url: 'accessories/jewellery/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '61',
    pageName: 'Racing',
    h1: "WOMEN'S RACING JEWELLERY",
    url: 'accessories/jewellery/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '62',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN JEWELLERY",
    url: 'accessories/jewellery/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '63',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING JEWELLERY",
    url: 'accessories/jewellery/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '64',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER JEWELLERY",
    url: 'accessories/jewellery/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '65',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL JEWELLERY",
    url: 'accessories/jewellery/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '66',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN JEWELLERY",
    url: 'accessories/jewellery/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '67',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE JEWELLERY",
    url: 'accessories/jewellery/one-size/',
    type: 'category',
    filter: 'size',
  },
];
