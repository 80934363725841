import { Heading } from "./types";

export const bodysuits: Heading[] = [
  {
    no: '64',
    pageName: 'Bodysuits',
    h1: "WOMEN'S BODYSUITS",
    url: 'womens-tops/bodysuits',
    type: 'category',
    filter: '',
  },
  {
    no: '65',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BODYSUITS",
    url: 'womens-tops/bodysuits/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '66',
    pageName: 'Black',
    h1: "WOMEN'S BLACK BODYSUITS",
    url: 'womens-tops/bodysuits/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '67',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE BODYSUITS",
    url: 'womens-tops/bodysuits/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '68',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BODYSUITS",
    url: 'womens-tops/bodysuits/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '69',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE BODYSUITS",
    url: 'womens-tops/bodysuits/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '70',
    pageName: 'Green',
    h1: "WOMEN'S GREEN HEELS",
    url: 'womens-tops/bodysuits/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '71',
    pageName: 'Grey',
    h1: "WOMEN'S GREY BODYSUITS",
    url: 'womens-tops/bodysuits/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '72',
    pageName: 'Multicolor',
    h1: "WOMEN'S MULTIcolor BODYSUITS",
    url: 'womens-tops/bodysuits/multicolor/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '73',
    pageName: 'Pink',
    h1: "WOMEN'S PINK BODYSUITS",
    url: 'womens-tops/bodysuits/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '74',
    pageName: 'White',
    h1: "WOMEN'S WHITE BODYSUITS",
    url: 'womens-tops/bodysuits/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '#By occasion',
    pageName: '',
    h1: '',
    url: '',
    type: '',
    filter: '',
  },
  {
    no: '75',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY BODYSUITS",
    url: 'womens-tops/bodysuits/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '76',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BODYSUITS",
    url: 'womens-tops/bodysuits/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '77',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL BODYSUITS",
    url: 'womens-tops/bodysuits/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '78',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BODYSUITS",
    url: 'womens-tops/bodysuits/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '79',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BODYSUITS",
    url: 'womens-tops/bodysuits/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '80',
    pageName: 'Party',
    h1: "WOMEN'S PARTY BODYSUITS",
    url: 'womens-tops/bodysuits/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '81',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK BODYSUITS",
    url: 'womens-tops/bodysuits/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '82',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN BODYSUITS",
    url: 'womens-tops/bodysuits/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '83',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BODYSUITS",
    url: 'womens-tops/bodysuits/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '84',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BODYSUITS",
    url: 'womens-tops/bodysuits/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '85',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER BODYSUITS",
    url: 'womens-tops/bodysuits/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '86',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED BODYSUITS",
    url: 'womens-tops/bodysuits/long-sleeved/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    no: '87',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED BODYSUITS",
    url: 'womens-tops/bodysuits/short-sleeved/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    no: '88',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS BODYSUITS",
    url: 'womens-tops/bodysuits/sleeveless/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    no: '89',
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT BODYSUITS",
    url: 'womens-tops/bodysuits/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '90',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS BODYSUITS",
    url: 'womens-tops/bodysuits/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '91',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON BODYSUITS",
    url: 'womens-tops/bodysuits/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '92',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT BODYSUITS",
    url: 'womens-tops/bodysuits/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '93',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL BODYSUITS",
    url: 'womens-tops/bodysuits/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '94',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT BODYSUITS",
    url: 'womens-tops/bodysuits/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '95',
    pageName: 'Lace',
    h1: "WOMEN'S LACE BODYSUITS",
    url: 'womens-tops/bodysuits/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '96',
    pageName: 'Mesh',
    h1: "WOMEN'S MESH BODYSUITS",
    url: 'womens-tops/bodysuits/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '97',
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID BODYSUITS",
    url: 'womens-tops/bodysuits/milkmaid/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '98',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER BODYSUITS",
    url: 'womens-tops/bodysuits/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '99',
    pageName: 'One-Shoulder',
    h1: "WOMEN'S ONE SHOULDER BODYSUITS",
    url: 'womens-tops/bodysuits/one-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '100',
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED BODYSUITS",
    url: 'womens-tops/bodysuits/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '101',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN BODYSUITS",
    url: 'womens-tops/bodysuits/sequin/',
    type: 'category',
    filter: '',
  },
  {
    no: '102',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE BODYSUITS",
    url: 'womens-tops/bodysuits/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '103',
    pageName: 'S',
    h1: "WOMEN'S S SIZE BODYSUITS",
    url: 'womens-tops/bodysuits/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '104',
    pageName: 'M',
    h1: "WOMEN'S M SIZE BODYSUITS",
    url: 'womens-tops/bodysuits/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '105',
    pageName: 'L',
    h1: "WOMEN'S L SIZE BODYSUITS",
    url: 'womens-tops/bodysuits/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '106',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE BODYSUITS",
    url: 'womens-tops/bodysuits/xl/',
    type: 'category',
    filter: 'size',
  },
];
