import { Heading } from "./types";

export const comfortClothing: Heading[] = [
  {
    no: '521',
    pageName: 'Comfort',
    h1: "WOMEN'S COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/',
    type: 'category',
    filter: '',
  },
  {
    no: '522',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '523',
    pageName: 'Black',
    h1: "WOMEN'S BLACK COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '524',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '525',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '526',
    pageName: 'Green',
    h1: "WOMEN'S GREEN COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '527',
    pageName: 'Grey',
    h1: "WOMEN'S GREY COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '528',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '529',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '530',
    pageName: 'Pink',
    h1: "WOMEN'S PINK COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '531',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '532',
    pageName: 'Red',
    h1: "WOMEN'S RED COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '533',
    pageName: 'White',
    h1: "WOMEN'S WHITE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '534',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '535',
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '536',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '537',
    pageName: 'Mini',
    h1: "WOMEN'S MINI COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '538',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '539',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '540',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '541',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '542',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '543',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '544',
    pageName: 'Party',
    h1: "WOMEN'S PARTY COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '545',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '546',
    pageName: 'Work',
    h1: "WOMEN'S WORK COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '547',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '548',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '549',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '550',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '551',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '552',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '553',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '554',
    pageName: 'A-Line',
    h1: "WOMEN'S A LINE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/a-line/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '555',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '556',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '557',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '558',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '559',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '560',
    pageName: 'Gingham',
    h1: "WOMEN'S GINGHAM COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/gingham/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '561',
    pageName: 'Halter',
    h1: "WOMEN'S HALTER COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '562',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '563',
    pageName: 'Lace',
    h1: "WOMEN'S LACE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '564',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '565',
    pageName: 'Puffer',
    h1: "WOMEN'S PUFFER COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/puffer/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '566',
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '567',
    pageName: 'Satin',
    h1: "WOMEN'S SATIN COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '568',
    pageName: 'Shift',
    h1: "WOMEN'S SHIFT COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/shift/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '569',
    pageName: 'Slip',
    h1: "WOMEN'S SLIP COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/slip/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '570',
    pageName: 'Wool',
    h1: "WOMEN'S WOOL COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/wool/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '571',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '572',
    pageName: 'S',
    h1: "WOMEN'S S SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '573',
    pageName: 'M',
    h1: "WOMEN'S M SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '574',
    pageName: 'L',
    h1: "WOMEN'S L SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '575',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '576',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '577',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '578',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '579',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/one-size/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '580',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '581',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '582',
    pageName: '9',
    h1: "WOMEN'S 9 SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '583',
    pageName: '10',
    h1: "WOMEN'S 10 SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '584',
    pageName: '13',
    h1: "WOMEN'S 13 SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/13/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '585',
    pageName: '14',
    h1: "WOMEN'S 14 SIZE COMFORT CLOTHING",
    url: 'womens-wear/comfort-clothing/14/',
    type: 'category',
    filter: 'size',
  },
];
