import { Heading } from "./types";

export const swimTops: Heading[] = [
  {
    no: '38',
    pageName: 'Swim Tops',
    h1: "WOMEN'S SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/',
    type: 'category',
    filter: '',
  },
  {
    no: '39',
    pageName: 'Black',
    h1: "WOMEN'S BLACK SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '40',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '41',
    pageName: 'Fuchsia',
    h1: "WOMEN'S FUCHSIA SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/fuchsia/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '42',
    pageName: 'Green',
    h1: "WOMEN'S GREEN SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '43',
    pageName: 'Ivy',
    h1: "WOMEN'S IVY SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/ivy/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '44',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '45',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '46',
    pageName: 'Peach',
    h1: "WOMEN'S PEACH SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/peach/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '47',
    pageName: 'Pink',
    h1: "WOMEN'S PINK SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '48',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '49',
    pageName: 'Red',
    h1: "WOMEN'S RED SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '50',
    pageName: 'White',
    h1: "WOMEN'S WHITE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '51',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '52',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '53',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '54',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '55',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '56',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '57',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '58',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '59',
    pageName: 'S',
    h1: "WOMEN'S S SIZE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '60',
    pageName: 'M',
    h1: "WOMEN'S M SIZE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '61',
    pageName: 'L',
    h1: "WOMEN'S L SIZE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '62',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE SWIM TOPS",
    url: 'womens-wear/swimwear/swim-tops/xl/',
    type: 'category',
    filter: 'size',
  },
];
