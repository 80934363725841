import { Heading } from "./types";

export const blazers: Heading[] = [
  {
    no: '223',
    pageName: 'Blazers',
    h1: "WOMEN'S BLAZERS",
    url: 'womens-wear/outerwear/blazers/',
    type: 'category',
    filter: '',
  },
  {
    no: '224',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BLAZERS",
    url: 'womens-wear/outerwear/blazers/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '225',
    pageName: 'Black',
    h1: "WOMEN'S BLACK BLAZERS",
    url: 'womens-wear/outerwear/blazers/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '226',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE BLAZERS",
    url: 'womens-wear/outerwear/blazers/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '227',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BLAZERS",
    url: 'womens-wear/outerwear/blazers/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '228',
    pageName: 'Green',
    h1: "WOMEN'S GREEN BLAZERS",
    url: 'womens-wear/outerwear/blazers/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '229',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR BLAZERS",
    url: 'womens-wear/outerwear/blazers/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '230',
    pageName: 'Pink',
    h1: "WOMEN'S PINK BLAZERS",
    url: 'womens-wear/outerwear/blazers/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '231',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE BLAZERS",
    url: 'womens-wear/outerwear/blazers/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '232',
    pageName: 'White',
    h1: "WOMEN'S WHITE BLAZERS",
    url: 'womens-wear/outerwear/blazers/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '233',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST BLAZERS",
    url: 'womens-wear/outerwear/blazers/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '234',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BLAZERS",
    url: 'womens-wear/outerwear/blazers/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '235',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BLAZERS",
    url: 'womens-wear/outerwear/blazers/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '236',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL BLAZERS",
    url: 'womens-wear/outerwear/blazers/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '237',
    pageName: 'Party',
    h1: "WOMEN'S PARTY BLAZERS",
    url: 'womens-wear/outerwear/blazers/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '238',
    pageName: 'Racing',
    h1: "WOMEN'S RACING BLAZERS",
    url: 'womens-wear/outerwear/blazers/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '239',
    pageName: 'Work',
    h1: "WOMEN'S WORK BLAZERS",
    url: 'womens-wear/outerwear/blazers/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '240',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN BLAZERS",
    url: 'womens-wear/outerwear/blazers/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '241',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BLAZERS",
    url: 'womens-wear/outerwear/blazers/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '242',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BLAZERS",
    url: 'womens-wear/outerwear/blazers/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '243',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER BLAZERS",
    url: 'womens-wear/outerwear/blazers/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '244',
    pageName: 'A-Line',
    h1: "WOMEN'S A LINE BLAZERS",
    url: 'womens-wear/outerwear/blazers/a-line/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '245',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT BLAZERS",
    url: 'womens-wear/outerwear/blazers/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '246',
    pageName: 'Short',
    h1: "WOMEN'S SHORT BLAZERS",
    url: 'womens-wear/outerwear/blazers/short/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '247',
    pageName: 'Tweed',
    h1: "WOMEN'S TWEED BLAZERS",
    url: 'womens-wear/outerwear/blazers/tweed/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '248',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE BLAZERS",
    url: 'womens-wear/outerwear/blazers/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '249',
    pageName: 'S',
    h1: "WOMEN'S S SIZE BLAZERS",
    url: 'womens-wear/outerwear/blazers/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '250',
    pageName: 'M',
    h1: "WOMEN'S M SIZE BLAZERS",
    url: 'womens-wear/outerwear/blazers/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '251',
    pageName: 'L',
    h1: "WOMEN'S L SIZE BLAZERS",
    url: 'womens-wear/outerwear/blazers/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '252',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE BLAZERS",
    url: 'womens-wear/outerwear/blazers/xl/',
    type: 'category',
    filter: 'size',
  },
];
