import { Heading } from "./types";

export const jumpsuits: Heading[] = [
  {
    no: 1,
    pageName: 'JUMPSUITS',
    h1: "WOMEN'S JUMPSUITS",
    url: 'jumpsuits',
    type: 'category',
    filter: '',
  },
  {
    no: 2,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM JUMPSUITS",
    url: 'jumpsuits/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 3,
    pageName: 'Black',
    h1: "WOMEN'S BLACK JUMPSUITS",
    url: 'jumpsuits/black-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 4,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE JUMPSUITS",
    url: 'jumpsuits/blue-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 5,
    pageName: 'Denim',
    h1: 'DENIM JUMPSUITS',
    url: 'jumpsuits/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 6,
    pageName: 'Green',
    h1: "WOMEN'S GREEN JUMPSUITS",
    url: 'jumpsuits/green-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 7,
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR JUMPSUITS",
    url: 'jumpsuits/multicolour-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 8,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE JUMPSUITS",
    url: 'jumpsuits/orange-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 9,
    pageName: 'Pink',
    h1: "WOMEN'S PINK JUMPSUITS",
    url: 'jumpsuits/pink-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 10,
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE JUMPSUITS",
    url: 'jumpsuits/purple-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 11,
    pageName: 'Red',
    h1: "WOMEN'S RED JUMPSUITS",
    url: 'jumpsuits/red-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 12,
    pageName: 'White',
    h1: "WOMEN'S WHITE JUMPSUITS",
    url: 'jumpsuits/white-jumpsuits/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 13,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY JUMPSUITS",
    url: 'jumpsuits/beach-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 14,
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAIDS & WEDDING GUEST JUMPSUITS",
    url: 'jumpsuits/bridesmaids-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 15,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH JUMPSUITS",
    url: 'jumpsuits/brunch-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 16,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL JUMPSUITS",
    url: 'jumpsuits/casual-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 17,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT JUMPSUITS",
    url: 'jumpsuits/date-night-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 18,
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL JUMPSUITS",
    url: 'jumpsuits/festival-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 19,
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL JUMPSUITS",
    url: 'jumpsuits/formal-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 20,
    pageName: 'Party',
    h1: "WOMEN'S PARTY JUMPSUITS",
    url: 'jumpsuits/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 21,
    pageName: 'Racing',
    h1: "WOMEN'S RACING JUMPSUITS",
    url: 'jumpsuits/racing-jumpsuits/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 22,
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK",
    url: 'jumpsuits/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 23,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN JUMPSUITS",
    url: 'jumpsuits/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 24,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING JUMPSUITS",
    url: 'jumpsuits/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 25,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER JUMPSUITS",
    url: 'jumpsuits/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 26,
    pageName: 'Winter',
    h1: "WOMEN'S WINTER JUMPSUITS",
    url: 'jumpsuits/winter/',
    type: 'filter/category',
    filter: 'season',
  },
  {
    no: 27,
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED JUMPSUITS",
    url: 'jumpsuits/long-sleeve-jumpsuits/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 28,
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED JUMPSUITS",
    url: 'jumpsuits/short-sleeved-jumpsuits/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 29,
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS JUMPSUITS",
    url: 'jumpsuits/sleeveless-jumpsuits/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 30,
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS JUMPSUITS",
    url: 'jumpsuits/backless-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 31,
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON JUMPSUITS",
    url: 'jumpsuits/bodycon-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 32,
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT JUMPSUITS",
    url: 'jumpsuits/cut-out-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 33,
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL JUMPSUITS",
    url: 'jumpsuits/floral-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 34,
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY JUMPSUITS",
    url: 'jumpsuits/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 35,
    pageName: 'Lace',
    h1: "WOMEN'S LACE JUMPSUITS",
    url: 'jumpsuits/lace-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 36,
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER JUMPSUITS",
    url: 'jumpsuits/off-the-shoulder-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 37,
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN JUMPSUITS",
    url: 'jumpsuits/sequin-jumpsuits/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 38,
    pageName: 'Straight',
    h1: "WOMEN'S STRAIGHT JUMPSUITS",
    url: 'jumpsuits/straight/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 39,
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE JUMPSUITS",
    url: 'jumpsuits/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 40,
    pageName: 'S',
    h1: "WOMEN'S S SIZE JUMPSUITS",
    url: 'jumpsuits/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 41,
    pageName: 'M',
    h1: "WOMEN'S M SIZE JUMPSUITS",
    url: 'jumpsuits/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 42,
    pageName: 'L',
    h1: "WOMEN'S L SIZE JUMPSUITS",
    url: 'jumpsuits/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 43,
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE JUMPSUITS",
    url: 'jumpsuits/xl/',
    type: 'filter/category',
    filter: 'size',
  },
];
