import { Heading } from "./types";

export const heels: Heading[] = [
  {
    no: 53,
    pageName: 'Heels',
    h1: "WOMEN'S HEELS",
    url: 'shoes/heels/',
    type: 'category',
    filter: '',
  },
  {
    no: 54,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM HEELS",
    url: 'shoes/heels/beige-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 55,
    pageName: 'Black',
    h1: "WOMEN'S BLACK HEELS",
    url: 'shoes/heels/black-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 56,
    pageName: 'White',
    h1: "WOMEN'S WHITE HEELS",
    url: 'shoes/heels/white-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 57,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN HEELS",
    url: 'shoes/heels/brown-heels-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 58,
    pageName: 'Silver',
    h1: "WOMEN'S SILVER HEELS",
    url: 'shoes/heels/silver-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 59,
    pageName: 'Pink',
    h1: "WOMEN'S PINK HEELS",
    url: 'shoes/heels/pink-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 60,
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE HEELS",
    url: 'shoes/heels/gold-bronze-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 61,
    pageName: 'Green',
    h1: "WOMEN'S GREEN HEELS",
    url: 'shoes/heels/green-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 62,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE HEELS",
    url: 'shoes/heels/blue-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 63,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE HEELS",
    url: 'shoes/heels/orange-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 64,
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE HEELS",
    url: 'shoes/heels/purple-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 65,
    pageName: 'Red',
    h1: "WOMEN'S RED HEELS",
    url: 'shoes/heels/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 66,
    pageName: 'Nude',
    h1: "WOMEN'S NUDE HEELS",
    url: 'shoes/heels/nude-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 67,
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW HEELS",
    url: 'shoes/heels/yellow-heels/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 68,
    pageName: 'Mini',
    h1: "WOMEN'S MINI HEELS",
    url: 'shoes/heels/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 69,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT HEELS",
    url: 'shoes/heels/date-night-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 70,
    pageName: 'Party',
    h1: "WOMEN'S PARTY HEELS",
    url: 'shoes/heels/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 71,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH HEELS",
    url: 'shoes/heels/brunch-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 72,
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST HEELS",
    url: 'shoes/heels/wedding-guest-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 73,
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL HEELS",
    url: 'shoes/heels/cocktail-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 74,
    pageName: 'Racing',
    h1: "WOMEN'S RACING HEELS",
    url: 'shoes/heels/racing-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 75,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY HEELS",
    url: 'shoes/heels/beach-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 76,
    pageName: 'Homecoming',
    h1: "WOMEN'S HOMECOMING HEELS",
    url: 'shoes/heels/homecoming-heels/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 77,
    pageName: 'Work',
    h1: "WOMEN'S WORK HEELS",
    url: 'shoes/heels/work/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 78,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER HEELS",
    url: 'shoes/heels/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 79,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING HEELS",
    url: 'shoes/heels/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 80,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN HEELS",
    url: 'shoes/heels/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 81,
    pageName: '7',
    h1: "WOMEN'S HEELS SIZE 7",
    url: 'shoes/heels/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 82,
    pageName: '8',
    h1: "WOMEN'S HEELS SIZE 8",
    url: 'shoes/heels/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 83,
    pageName: '6',
    h1: "WOMEN'S HEELS SIZE 6",
    url: 'shoes/heels/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 84,
    pageName: '9',
    h1: "WOMEN'S HEELS SIZE 9",
    url: 'shoes/heels/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 85,
    pageName: '10',
    h1: "WOMEN'S HEELS SIZE 10",
    url: 'shoes/heels/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 86,
    pageName: '5',
    h1: "WOMEN'S HEELS SIZE 5",
    url: 'shoes/heels/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 87,
    pageName: '37',
    h1: "WOMEN'S HEELS SIZE 37",
    url: 'shoes/heels/37/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 88,
    pageName: '38',
    h1: "WOMEN'S HEELS SIZE 38",
    url: 'shoes/heels/38/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 89,
    pageName: '39',
    h1: "WOMEN'S HEELS SIZE 39",
    url: 'shoes/heels/39/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 90,
    pageName: '41',
    h1: "WOMEN'S HEELS SIZE 41",
    url: 'shoes/heels/41/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 91,
    pageName: '36',
    h1: "WOMEN'S HEELS SIZE 36",
    url: 'shoes/heels/36/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 92,
    pageName: '40',
    h1: "WOMEN'S HEELS SIZE 40",
    url: 'shoes/heels/40/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 93,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE HEELS",
    url: 'shoes/heels/ankle/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 94,
    pageName: 'Block',
    h1: "WOMEN'S BLOCK HEELS",
    url: 'shoes/heels/block/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 95,
    pageName: 'Ankle-Strap',
    h1: "WOMEN'S ANKLE STRAP HEELS",
    url: 'shoes/heels/ankle-strap/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 96,
    pageName: 'Slip-On',
    h1: "WOMEN'S SLIP ON HEELS",
    url: 'shoes/heels/slip-on/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 97,
    pageName: 'Stiletto',
    h1: "WOMEN'S STILETTO HEELS",
    url: 'shoes/heels/stiletto/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 98,
    pageName: 'Open-Toe',
    h1: "WOMEN'S OPEN TOE HEELS",
    url: 'shoes/heels/open-toe/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 99,
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT HEELS",
    url: 'shoes/heels/animal-prints-heels/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 100,
    pageName: 'Platform',
    h1: "WOMEN'S PLATFORM HEELS",
    url: 'shoes/heels/platform/',
    type: 'category',
    filter: 'trend',
  },
];
