import { Heading } from "./types";

export const sunglasses: Heading[] = [
  {
    no: '156',
    pageName: 'Sunglasses',
    h1: "WOMEN'S SUNGLASSES",
    url: 'accessories/sunglasses/',
    type: 'category',
    filter: '',
  },
  {
    no: '157',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM SUNGLASSES",
    url: 'accessories/sunglasses/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '158',
    pageName: 'Black',
    h1: "WOMEN'S BLACK SUNGLASSES",
    url: 'accessories/sunglasses/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '159',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SUNGLASSES",
    url: 'accessories/sunglasses/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '160',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN SUNGLASSES",
    url: 'accessories/sunglasses/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '161',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE SUNGLASSES",
    url: 'accessories/sunglasses/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '162',
    pageName: 'Grey',
    h1: "WOMEN'S GREY SUNGLASSES",
    url: 'accessories/sunglasses/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '163',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SUNGLASSES",
    url: 'accessories/sunglasses/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '164',
    pageName: 'Pink',
    h1: "WOMEN'S PINK SUNGLASSES",
    url: 'accessories/sunglasses/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '165',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER SUNGLASSES",
    url: 'accessories/sunglasses/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '166',
    pageName: 'White',
    h1: "WOMEN'S WHITE SUNGLASSES",
    url: 'accessories/sunglasses/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '167',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SUNGLASSES",
    url: 'accessories/sunglasses/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '168',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SUNGLASSES",
    url: 'accessories/sunglasses/brunch/',
    type: 'category',
    filter: 'occasion',
  },
];
