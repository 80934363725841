import { Skeleton } from '@ui/components/shared';

const ProductCardSkeleton = () => (
  <div className="isolate relative group">
    <Skeleton className="w-full h-0 pb-[150%]" />
    <Skeleton className="mt-3 h-12 w-11/12" />
  </div>
);

export default ProductCardSkeleton;
