import Link from 'next/link';
import Image from 'next/image';
import cn from '@ui/utils/cn';

export type CollectionBanner = {
  url: string;
  width?: number | null;
  height?: number | null;
  altText?: string | null;
};

const CollectionBannerSection = (collectionBanner: CollectionBanner) => (
  <div className={cn('w-full max-w-[1800px] mx-auto md:px-8')}>
    <Image
      src={collectionBanner.url}
      alt={collectionBanner.altText || ''}
      width={collectionBanner.width || 1736}
      height={collectionBanner.height || 330}
      className="w-full"
    />
  </div>
);

export default CollectionBannerSection;
