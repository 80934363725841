import { Heading } from './types';

export const bottoms: Heading[] = [
  {
    no: '1',
    pageName: 'BOTTOMS',
    h1: "WOMEN'S BOTTOMS",
    url: 'bottoms/',
    type: 'category',
    filter: '',
  },
  {
    no: '284',
    pageName: 'Sweats',
    h1: "WOMEN'S SWEATPANTS",
    url: 'bottoms/sweats/',
    type: 'category',
    filter: '',
  },
  {
    no: '312',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BOTTOMS",
    url: 'bottoms/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '313',
    pageName: 'Black',
    h1: "WOMEN'S BLACK BOTTOMS",
    url: 'bottoms/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '314',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE BOTTOMS",
    url: 'bottoms/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '315',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BOTTOMS",
    url: 'bottoms/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '316',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM BOTTOMS",
    url: 'bottoms/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '317',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE BOTTOMS",
    url: 'bottoms/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '318',
    pageName: 'Gold-Rose',
    h1: "WOMEN'S GOLD/ROSE BOTTOMS",
    url: 'bottoms/gold-rose/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '319',
    pageName: 'Green',
    h1: "WOMEN'S GREEN BOTTOMS",
    url: 'bottoms/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '320',
    pageName: 'Grey',
    h1: "WOMEN'S GREY BOTTOMS",
    url: 'bottoms/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '321',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR BOTTOMS",
    url: 'bottoms/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '322',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE BOTTOMS",
    url: 'bottoms/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '323',
    pageName: 'Peach',
    h1: "WOMEN'S PEACH BOTTOMS",
    url: 'bottoms/peach/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '324',
    pageName: 'Pink',
    h1: "WOMEN'S PINK BOTTOMS",
    url: 'bottoms/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '325',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE BOTTOMS",
    url: 'bottoms/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '326',
    pageName: 'Red',
    h1: "WOMEN'S RED BOTTOMS",
    url: 'bottoms/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '327',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER BOTTOMS",
    url: 'bottoms/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '328',
    pageName: 'White',
    h1: "WOMEN'S WHITE BOTTOMS",
    url: 'bottoms/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '329',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW BOTTOMS",
    url: 'bottoms/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '330',
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI BOTTOMS",
    url: 'bottoms/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '331',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI BOTTOMS",
    url: 'bottoms/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '332',
    pageName: 'Mini',
    h1: "WOMEN'S MINI BOTTOMS",
    url: 'bottoms/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '333',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY BOTTOMS",
    url: 'bottoms/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '334',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST BOTTOMS",
    url: 'bottoms/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '335',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BOTTOMS",
    url: 'bottoms/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '336',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL BOTTOMS",
    url: 'bottoms/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '337',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BOTTOMS",
    url: 'bottoms/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '338',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BOTTOMS",
    url: 'bottoms/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '339',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL BOTTOMS",
    url: 'bottoms/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '340',
    pageName: 'Homecoming',
    h1: "WOMEN'S HOMECOMING BOTTOMS",
    url: 'bottoms/homecoming/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '341',
    pageName: 'Party',
    h1: "WOMEN'S PARTY BOTTOMS",
    url: 'bottoms/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '342',
    pageName: 'Racing',
    h1: "WOMEN'S RACING BOTTOMS",
    url: 'bottoms/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '343',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK BOTTOMS",
    url: 'bottoms/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '344',
    pageName: 'Work',
    h1: "WOMEN'S WORK BOTTOMS",
    url: 'bottoms/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '345',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN BOTTOMS",
    url: 'bottoms/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '346',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BOTTOMS",
    url: 'bottoms/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '347',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BOTTOMS",
    url: 'bottoms/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '348',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER BOTTOMS",
    url: 'bottoms/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '349',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED BOTTOMS",
    url: 'bottoms/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '350',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED BOTTOMS",
    url: 'bottoms/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '351',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS BOTTOMS",
    url: 'bottoms/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '352',
    pageName: 'A-Line',
    h1: "WOMEN'S A LINE BOTTOMS",
    url: 'bottoms/a-line/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '353',
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT BOTTOMS",
    url: 'bottoms/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '354',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON BOTTOMS",
    url: 'bottoms/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '355',
    pageName: 'Cargo',
    h1: "WOMEN'S CARGO BOTTOMS",
    url: 'bottoms/cargo/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '356',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT BOTTOMS",
    url: 'bottoms/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '357',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL BOTTOMS",
    url: 'bottoms/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '358',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY BOTTOMS",
    url: 'bottoms/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '359',
    pageName: 'High-Waisted',
    h1: "WOMEN'S HIGH WAISTED BOTTOMS",
    url: 'bottoms/high-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '360',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT BOTTOMS",
    url: 'bottoms/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '361',
    pageName: 'Lace',
    h1: "WOMEN'S LACE BOTTOMS",
    url: 'bottoms/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '362',
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED BOTTOMS",
    url: 'bottoms/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '363',
    pageName: 'Satin',
    h1: "WOMEN'S SATIN BOTTOMS",
    url: 'bottoms/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '364',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN BOTTOMS",
    url: 'bottoms/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '365',
    pageName: 'Straight-Leg',
    h1: "WOMEN'S STRAIGHT LEG BOTTOMS",
    url: 'bottoms/straight-leg/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '366',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE BOTTOMS",
    url: 'bottoms/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '367',
    pageName: 'S',
    h1: "WOMEN'S S SIZE BOTTOMS",
    url: 'bottoms/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '368',
    pageName: 'M',
    h1: "WOMEN'S M SIZE BOTTOMS",
    url: 'bottoms/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '369',
    pageName: 'L',
    h1: "WOMEN'S L SIZE BOTTOMS",
    url: 'bottoms/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '370',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE BOTTOMS",
    url: 'bottoms/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '371',
    pageName: 'XXL',
    h1: "WOMEN'S XXL SIZE BOTTOMS",
    url: 'bottoms/xxl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '372',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE BOTTOMS",
    url: 'bottoms/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '373',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE BOTTOMS",
    url: 'bottoms/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '374',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE BOTTOMS",
    url: 'bottoms/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '375',
    pageName: 'L-XL',
    h1: "WOMEN'S L/XL SIZE BOTTOMS",
    url: 'bottoms/l-xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '376',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE BOTTOMS",
    url: 'bottoms/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '377',
    pageName: '7',
    h1: "WOMEN'S 7 SIZE BOTTOMS",
    url: 'bottoms/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '378',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE BOTTOMS",
    url: 'bottoms/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '379',
    pageName: '9',
    h1: "WOMEN'S 9 SIZE BOTTOMS",
    url: 'bottoms/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '380',
    pageName: '10',
    h1: "WOMEN'S 10 SIZE BOTTOMS",
    url: 'bottoms/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '381',
    pageName: '11',
    h1: "WOMEN'S 11 SIZE BOTTOMS",
    url: 'bottoms/11/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '382',
    pageName: '12',
    h1: "WOMEN'S 12 SIZE BOTTOMS",
    url: 'bottoms/12/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '383',
    pageName: '13',
    h1: "WOMEN'S 13 SIZE BOTTOMS",
    url: 'bottoms/13/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '384',
    pageName: '14',
    h1: "WOMEN'S 14 SIZE BOTTOMS",
    url: 'bottoms/14/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '385',
    pageName: '24',
    h1: "WOMEN'S 24 SIZE BOTTOMS",
    url: 'bottoms/24/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '386',
    pageName: '25',
    h1: "WOMEN'S 25 SIZE BOTTOMS",
    url: 'bottoms/25/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '387',
    pageName: '26',
    h1: "WOMEN'S 26 SIZE BOTTOMS",
    url: 'bottoms/26/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '388',
    pageName: '27',
    h1: "WOMEN'S 27 SIZE BOTTOMS",
    url: 'bottoms/27/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '389',
    pageName: '28',
    h1: "WOMEN'S 28 SIZE BOTTOMS",
    url: 'bottoms/28/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '390',
    pageName: '29',
    h1: "WOMEN'S 29 SIZE BOTTOMS",
    url: 'bottoms/29/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '391',
    pageName: '30',
    h1: "WOMEN'S 30 SIZE BOTTOMS",
    url: 'bottoms/30/',
    type: 'category',
    filter: 'size',
  },
];
