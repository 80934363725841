import { Heading } from "./types";

export const knitwear: Heading[] = [
  {
    no: '147',
    pageName: 'Knitwear',
    h1: "WOMEN'S KNITWEAR",
    url: 'womens-wear/knitwear/',
    type: 'category',
    filter: '',
  },
  {
    no: '166',
    pageName: 'Sweaters',
    h1: "WOMEN'S SWEATERS",
    url: 'womens-wear/knitwear/womens-sweaters/',
    type: 'category',
    filter: '',
  },
  {
    no: '167',
    pageName: 'Cardigans',
    h1: "WOMEN'S CARDIGANS",
    url: 'womens-wear/knitwear/cardigans/',
    type: 'category',
    filter: '',
  },
  {
    no: '168',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM KNITWEAR",
    url: 'womens-wear/knitwear/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '169',
    pageName: 'Black',
    h1: "WOMEN'S BLACK KNITWEAR",
    url: 'womens-wear/knitwear/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '170',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE KNITWEAR",
    url: 'womens-wear/knitwear/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '171',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN KNITWEAR",
    url: 'womens-wear/knitwear/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '172',
    pageName: 'Green',
    h1: "WOMEN'S GREEN KNITWEAR",
    url: 'womens-wear/knitwear/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '173',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR KNITWEAR",
    url: 'womens-wear/knitwear/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '174',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE KNITWEAR",
    url: 'womens-wear/knitwear/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '175',
    pageName: 'Pink',
    h1: "WOMEN'S PINK KNITWEAR",
    url: 'womens-wear/knitwear/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '176',
    pageName: 'White',
    h1: "WOMEN'S WHITE KNITWEAR",
    url: 'womens-wear/knitwear/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '177',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW KNITWEAR",
    url: 'womens-wear/knitwear/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '178',
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI KNITWEAR",
    url: 'womens-wear/knitwear/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '179',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI KNITWEAR",
    url: 'womens-wear/knitwear/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '180',
    pageName: 'Mini',
    h1: "WOMEN'S MINI KNITWEAR",
    url: 'womens-wear/knitwear/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '181',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY KNITWEAR",
    url: 'womens-wear/knitwear/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '182',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST KNITWEAR",
    url: 'womens-wear/knitwear/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '183',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH KNITWEAR",
    url: 'womens-wear/knitwear/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '184',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL KNITWEAR",
    url: 'womens-wear/knitwear/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '185',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT KNITWEAR",
    url: 'womens-wear/knitwear/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '186',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL KNITWEAR",
    url: 'womens-wear/knitwear/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '187',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL KNITWEAR",
    url: 'womens-wear/knitwear/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '188',
    pageName: 'Party',
    h1: "WOMEN'S PARTY KNITWEAR",
    url: 'womens-wear/knitwear/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '189',
    pageName: 'Racing',
    h1: "WOMEN'S RACING KNITWEAR",
    url: 'womens-wear/knitwear/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '190',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK KNITWEAR",
    url: 'womens-wear/knitwear/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '191',
    pageName: 'Work',
    h1: "WOMEN'S WORK KNITWEAR",
    url: 'womens-wear/knitwear/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '192',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN KNITWEAR",
    url: 'womens-wear/knitwear/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '193',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING KNITWEAR",
    url: 'womens-wear/knitwear/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '194',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER KNITWEAR",
    url: 'womens-wear/knitwear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '195',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER KNITWEAR",
    url: 'womens-wear/knitwear/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '196',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED KNITWEAR",
    url: 'womens-wear/knitwear/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '197',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS KNITWEAR",
    url: 'womens-wear/knitwear/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '198',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS KNITWEAR",
    url: 'womens-wear/knitwear/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '199',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON KNITWEAR",
    url: 'womens-wear/knitwear/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '200',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT KNITWEAR",
    url: 'womens-wear/knitwear/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '201',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL KNITWEAR",
    url: 'womens-wear/knitwear/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '202',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY KNITWEAR",
    url: 'womens-wear/knitwear/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '203',
    pageName: 'Halter',
    h1: "WOMEN'S HALTER KNITWEAR",
    url: 'womens-wear/knitwear/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '204',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT KNITWEAR",
    url: 'womens-wear/knitwear/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '205',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER KNITWEAR",
    url: 'womens-wear/knitwear/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '206',
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED KNITWEAR",
    url: 'womens-wear/knitwear/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '207',
    pageName: 'Slip',
    h1: "WOMEN'S SLIP KNITWEAR",
    url: 'womens-wear/knitwear/slip/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '208',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE KNITWEAR",
    url: 'womens-wear/knitwear/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '209',
    pageName: 'S',
    h1: "WOMEN'S S SIZE KNITWEAR",
    url: 'womens-wear/knitwear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '210',
    pageName: 'M',
    h1: "WOMEN'S M SIZE KNITWEAR",
    url: 'womens-wear/knitwear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '211',
    pageName: 'L',
    h1: "WOMEN'S L SIZE KNITWEAR",
    url: 'womens-wear/knitwear/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '212',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE KNITWEAR",
    url: 'womens-wear/knitwear/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '213',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE KNITWEAR",
    url: 'womens-wear/knitwear/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '214',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE KNITWEAR",
    url: 'womens-wear/knitwear/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '215',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE KNITWEAR",
    url: 'womens-wear/knitwear/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '216',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE KNITWEAR",
    url: 'womens-wear/knitwear/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '217',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE KNITWEAR",
    url: 'womens-wear/knitwear/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '218',
    pageName: '9',
    h1: "WOMEN'S 9 SIZE KNITWEAR",
    url: 'womens-wear/knitwear/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '219',
    pageName: '10',
    h1: "WOMEN'S 10 SIZE KNITWEAR",
    url: 'womens-wear/knitwear/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '220',
    pageName: '13',
    h1: "WOMEN'S 13 SIZE KNITWEAR",
    url: 'womens-wear/knitwear/13/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '221',
    pageName: '14',
    h1: "WOMEN'S 14 SIZE KNITWEAR",
    url: 'womens-wear/knitwear/14/',
    type: 'category',
    filter: 'size',
  },
];
