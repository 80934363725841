import { Heading } from "./types";

export const twoPiecesSwimsuits: Heading[] = [
  {
    no: '98',
    pageName: 'Two-Pieces',
    h1: "WOMEN'S TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/',
    type: 'category',
    filter: '',
  },
  {
    no: '99',
    pageName: 'Black',
    h1: "WOMEN'S BLACK TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '100',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '101',
    pageName: 'Green',
    h1: "WOMEN'S GREEN TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '102',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '103',
    pageName: 'Peach',
    h1: "WOMEN'S PEACH TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/peach/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '104',
    pageName: 'Pink',
    h1: "WOMEN'S PINK TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '105',
    pageName: 'Red',
    h1: "WOMEN'S RED TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '106',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '107',
    pageName: 'XS',
    h1: "WOMEN'S XS TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '108',
    pageName: 'S',
    h1: "WOMEN'S S TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '109',
    pageName: 'M',
    h1: "WOMEN'S M TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '110',
    pageName: 'L',
    h1: "WOMEN'S L TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '111',
    pageName: 'XL',
    h1: "WOMEN'S XL TWO-PIECES SWIMSUITS",
    url: 'womens-wear/swimwear/two-pieces-swimsuits/xl/',
    type: 'category',
    filter: 'size',
  },
];
