import { Heading } from "./types";

export const tailoredPants: Heading[] = [
  {
    no: '142',
    pageName: 'Tailored Pants',
    h1: "WOMEN'S TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/',
    type: 'category',
    filter: '',
  },
  {
    no: '143',
    pageName: 'Black',
    h1: "WOMEN'S BLACK TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '144',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '145',
    pageName: 'Green',
    h1: "WOMEN'S GREEN TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '146',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '147',
    pageName: 'Red',
    h1: "WOMEN'S RED TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '148',
    pageName: 'White',
    h1: "WOMEN'S WHITE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '149',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '150',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '151',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '152',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '153',
    pageName: 'Racing',
    h1: "WOMEN'S RACING TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '154',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '155',
    pageName: 'Work',
    h1: "WOMEN'S WORK TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '156',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '157',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '158',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '159',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/winter/',
    type: 'category',
    filter: '',
  },
  {
    no: '160',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '161',
    pageName: 'S',
    h1: "WOMEN'S S SIZE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '162',
    pageName: 'M',
    h1: "WOMEN'S M SIZE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '163',
    pageName: 'L',
    h1: "WOMEN'S L SIZE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '164',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE TAILORED PANTS",
    url: 'bottoms/womens-pants/tailored-pants/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '165',
    pageName: 'Wide-Leg-Pants',
    h1: "WOMEN'S WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/',
    type: 'category',
    filter: 'size',
  },
];
