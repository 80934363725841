import { Heading } from "./types";

export const fauxFur: Heading[] = [
  {
    no: '313',
    pageName: 'Faux-Fur',
    h1: "WOMEN'S FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/',
    type: 'category',
    filter: '',
  },
  {
    no: '314',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '315',
    pageName: 'Black',
    h1: "WOMEN'S BLACK FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '316',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '317',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '318',
    pageName: 'Green',
    h1: "WOMEN'S GREEN FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '319',
    pageName: 'Grey',
    h1: "WOMEN'S GREY FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '320',
    pageName: 'Pink',
    h1: "WOMEN'S PINK FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '321',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '322',
    pageName: 'White',
    h1: "WOMEN'S WHITE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '323',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '324',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '325',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '326',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '327',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '328',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '329',
    pageName: 'Party',
    h1: "WOMEN'S PARTY FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '330',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '331',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '332',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '333',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '334',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '335',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '336',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '337',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '338',
    pageName: 'Long',
    h1: "WOMEN'S LONG FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/long/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '339',
    pageName: 'Wool',
    h1: "WOMEN'S WOOL FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/wool/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '340',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '341',
    pageName: 'S',
    h1: "WOMEN'S S SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '342',
    pageName: 'M',
    h1: "WOMEN'S M SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '343',
    pageName: 'L',
    h1: "WOMEN'S L SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '344',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '345',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '346',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '347',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE FAUX FUR JACKETS",
    url: 'womens-wear/outerwear/faux-fur/one-size/',
    type: 'category',
    filter: 'size',
  },
];
