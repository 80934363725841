import { Heading } from "./types";

export const womensWear: Heading[] = [
  {
    no: '1',
    pageName: "WOMEN'S WEAR",
    h1: "WOMEN'S WEAR",
    url: 'womens-wear/',
    type: 'category',
    filter: '',
  },
  {
    no: '2',
    pageName: 'Activewear',
    h1: "WOMEN'S ACTIVEWEAR",
    url: 'womens-wear/activewear/',
    type: 'category',
    filter: '',
  },
  {
    no: '597',
    pageName: 'Stay Home',
    h1: "WOMEN'S STAY HOME CLOTHING",
    url: 'womens-wear/stay-home/',
    type: 'category',
    filter: '',
  },
  {
    no: '598',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM WEAR",
    url: 'womens-wear/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '599',
    pageName: 'Black',
    h1: "WOMEN'S BLACK WEAR",
    url: 'womens-wear/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '600',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE WEAR",
    url: 'womens-wear/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '601',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN WEAR",
    url: 'womens-wear/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '602',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM WEAR",
    url: 'womens-wear/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '603',
    pageName: 'Fuchsia',
    h1: "WOMEN'S FUCHSIA WEAR",
    url: 'womens-wear/fuchsia/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '604',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE WEAR",
    url: 'womens-wear/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '605',
    pageName: 'Green',
    h1: "WOMEN'S GREEN WEAR",
    url: 'womens-wear/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '606',
    pageName: 'Grey',
    h1: "WOMEN'S GREY WEAR",
    url: 'womens-wear/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '607',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR WEAR",
    url: 'womens-wear/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '608',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE WEAR",
    url: 'womens-wear/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '609',
    pageName: 'Pink',
    h1: "WOMEN'S PINK WEAR",
    url: 'womens-wear/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '610',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE WEAR",
    url: 'womens-wear/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '611',
    pageName: 'Red',
    h1: "WOMEN'S RED WEAR",
    url: 'womens-wear/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '612',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER WEAR",
    url: 'womens-wear/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '613',
    pageName: 'White',
    h1: "WOMEN'S WHITE WEAR",
    url: 'womens-wear/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '614',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW WEAR",
    url: 'womens-wear/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '615',
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI WEAR",
    url: 'womens-wear/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '616',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI WEAR",
    url: 'womens-wear/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '617',
    pageName: 'Mini',
    h1: "WOMEN'S MINI WEAR",
    url: 'womens-wear/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '618',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY WEAR",
    url: 'womens-wear/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '619',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST WEAR",
    url: 'womens-wear/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '620',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH WEAR",
    url: 'womens-wear/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '621',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL WEAR",
    url: 'womens-wear/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '622',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT WEAR",
    url: 'womens-wear/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '623',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL WEAR",
    url: 'womens-wear/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '624',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL WEAR",
    url: 'womens-wear/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '625',
    pageName: 'Party',
    h1: "WOMEN'S PARTY WEAR",
    url: 'womens-wear/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '626',
    pageName: 'Racing',
    h1: "WOMEN'S RACING WEAR",
    url: 'womens-wear/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '627',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK WEAR",
    url: 'womens-wear/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '628',
    pageName: 'Work',
    h1: "WOMEN'S WORK WEAR",
    url: 'womens-wear/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '629',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN WEAR",
    url: 'womens-wear/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '630',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING WEAR",
    url: 'womens-wear/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '631',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER WEAR",
    url: 'womens-wear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '632',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER WEAR",
    url: 'womens-wear/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '633',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED WEAR",
    url: 'womens-wear/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '634',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED WEAR",
    url: 'womens-wear/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '635',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS WEAR",
    url: 'womens-wear/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '636',
    pageName: 'A-Line',
    h1: "WOMEN'S A LINE WEAR",
    url: 'womens-wear/a-line/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '637',
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT WEAR",
    url: 'womens-wear/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '638',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS WEAR",
    url: 'womens-wear/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '639',
    pageName: 'Bandeau',
    h1: "WOMEN'S BANDEAU WEAR",
    url: 'womens-wear/bandeau/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '640',
    pageName: 'Birthday',
    h1: "WOMEN'S BIRTHDAY WEAR",
    url: 'womens-wear/birthday/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '641',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON WEAR",
    url: 'womens-wear/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '642',
    pageName: 'Corset',
    h1: "WOMEN'S CORSET WEAR",
    url: 'womens-wear/corset/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '643',
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT WEAR",
    url: 'womens-wear/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '644',
    pageName: 'Embellished',
    h1: "WOMEN'S EMBELLISHED WEAR",
    url: 'womens-wear/embellished/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '645',
    pageName: 'Embroidered',
    h1: "WOMEN'S EMBROIDERED WEAR",
    url: 'womens-wear/embroidered/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '646',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL WEAR",
    url: 'womens-wear/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '647',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY WEAR",
    url: 'womens-wear/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '648',
    pageName: 'Gingham',
    h1: "WOMEN'S GINGHAM WEAR",
    url: 'womens-wear/gingham/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '649',
    pageName: 'Halter',
    h1: "WOMEN'S HALTER WEAR",
    url: 'womens-wear/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '650',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT WEAR",
    url: 'womens-wear/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '651',
    pageName: 'Lace',
    h1: "WOMEN'S LACE WEAR",
    url: 'womens-wear/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '652',
    pageName: 'Mesh',
    h1: "WOMEN'S MESH WEAR",
    url: 'womens-wear/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '653',
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID WEAR",
    url: 'womens-wear/milkmaid/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '654',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER WEAR",
    url: 'womens-wear/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '655',
    pageName: 'One-Shoulder',
    h1: "WOMEN'S ONE SHOULDER WEAR",
    url: 'womens-wear/one-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '656',
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED WEAR",
    url: 'womens-wear/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '657',
    pageName: 'Satin',
    h1: "WOMEN'S SATIN WEAR",
    url: 'womens-wear/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '658',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN WEAR",
    url: 'womens-wear/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '659',
    pageName: 'Shift',
    h1: "WOMEN'S SHIFT WEAR",
    url: 'womens-wear/shift/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '660',
    pageName: 'Shirt',
    h1: "WOMEN'S SHIRT WEAR",
    url: 'womens-wear/shirt/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '661',
    pageName: 'Slip',
    h1: "WOMEN'S SLIP WEAR",
    url: 'womens-wear/slip/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '662',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE WEAR",
    url: 'womens-wear/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '663',
    pageName: 'S',
    h1: "WOMEN'S S SIZE WEAR",
    url: 'womens-wear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '664',
    pageName: 'M',
    h1: "WOMEN'S M SIZE WEAR",
    url: 'womens-wear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '665',
    pageName: 'L',
    h1: "WOMEN'S L SIZE WEAR",
    url: 'womens-wear/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '666',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE WEAR",
    url: 'womens-wear/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '667',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE WEAR",
    url: 'womens-wear/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '668',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE WEAR",
    url: 'womens-wear/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '669',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE WEAR",
    url: 'womens-wear/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '670',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE WEAR",
    url: 'womens-wear/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '671',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE WEAR",
    url: 'womens-wear/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '672',
    pageName: '9',
    h1: "WOMEN'S 9 SIZE WEAR",
    url: 'womens-wear/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '673',
    pageName: '10',
    h1: "WOMEN'S 10 SIZE WEAR",
    url: 'womens-wear/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '674',
    pageName: '13',
    h1: "WOMEN'S 13 SIZE WEAR",
    url: 'womens-wear/13/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '675',
    pageName: '14',
    h1: "WOMEN'S 14 SIZE WEAR",
    url: 'womens-wear/14/',
    type: 'category',
    filter: 'size',
  },
];
