// type CollectionBanner = {
//   image: string;
//   alt: string;
//   href: string;
// };

type CollectionInlineBanner = {
  image: string;
  width: number;
  height: number;
  alt: string;
  rowPosition: number;
  viewport: 'desktop' | 'mobile';
};

// export const CollectionBannerMappings: Record<string, CollectionBanner> = {
//   new: {
//     image:
//       "https://cdn.shopify.com/s/files/1/0771/4347/4482/files/Option_1_Desktop_1.jpg?v=1713161182",
//     alt: "test alt text",
//     href: "/",
//   },
// };

export const CollectionInlineBannerMappings: Record<string, Array<CollectionInlineBanner>> = {
  'wedding-parlour': [
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-1.png?v=1712106980',
      width: 2100,
      height: 471,
      alt: 'wedding parlour',
      rowPosition: 4,
      viewport: 'desktop',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-2.png?v=1712106981',
      width: 2100,
      height: 471,
      alt: 'wedding parlour',
      rowPosition: 8,
      viewport: 'desktop',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-3.png?v=1712106981',
      width: 2100,
      height: 471,
      alt: 'wedding parlour',
      rowPosition: 12,
      viewport: 'desktop',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-4.png?v=1712106981',
      width: 2100,
      height: 471,
      alt: 'wedding parlour',
      rowPosition: 16,
      viewport: 'desktop',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-5.png?v=1712106981',
      width: 2100,
      height: 471,
      alt: 'wedding parlour',
      rowPosition: 20,
      viewport: 'desktop',
    },
    {
      image:
        'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-mobile-1.png?v=1712107253',
      width: 2400,
      height: 884,
      alt: 'wedding parlour',
      rowPosition: 7,
      viewport: 'mobile',
    },
    {
      image:
        'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-mobile-2.png?v=1712107256',
      width: 2400,
      height: 884,
      alt: 'wedding parlour',
      rowPosition: 14,
      viewport: 'mobile',
    },
    {
      image:
        'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-mobile-3.png?v=1712107260',
      width: 2400,
      height: 884,
      alt: 'wedding parlour',
      rowPosition: 21,
      viewport: 'mobile',
    },
    {
      image:
        'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-mobile-4.png?v=1712107261',
      width: 2400,
      height: 884,
      alt: 'wedding parlour',
      rowPosition: 28,
      viewport: 'mobile',
    },
    {
      image:
        'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/new-inline-collection-banner-mobile-5.png?v=1712107263',
      width: 2400,
      height: 884,
      alt: 'wedding parlour',
      rowPosition: 35,
      viewport: 'mobile',
    },
  ],
};
