import { Heading } from "./types";

export const pleasureToys: Heading[] = [
  {
    no: 1,
    pageName: 'PLEASURE TOYS',
    h1: 'PLEASURE TOYS',
    url: 'pleasure-toys/',
    type: 'category',
    filter: '',
  },
  {
    no: 2,
    pageName: 'Blue',
    h1: 'BLUE PLEASURE TOYS',
    url: 'pleasure-toys/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 3,
    pageName: 'Green',
    h1: 'GREEN PLEASURE TOYS',
    url: 'pleasure-toys/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 4,
    pageName: 'Orange',
    h1: 'ORANGE PLEASURE TOYS',
    url: 'pleasure-toys/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 5,
    pageName: 'Pink',
    h1: 'PINK PLEASURE TOYS',
    url: 'pleasure-toys/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 6,
    pageName: 'Purple',
    h1: 'PURPLE PLEASURE TOYS',
    url: 'pleasure-toys/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 7,
    pageName: 'Red',
    h1: 'RED PLEASURE TOYS',
    url: 'pleasure-toys/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 8,
    pageName: 'Yellow',
    h1: 'YELLOW PLEASURE TOYS',
    url: 'pleasure-toys/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 9,
    pageName: 'Dual-Vibrators',
    h1: 'DUAL VIBRATORS',
    url: 'pleasure-toys/dual-vibrators/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 10,
    pageName: 'Skin-Safe-Lubricant',
    h1: 'SKIN SAFE LUBRICANT',
    url: 'pleasure-toys/skin-safe-lubricant/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 11,
    pageName: 'Suction-Vibrators',
    h1: 'SUCTION VIBRATORS',
    url: 'pleasure-toys/suction-vibrators/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 12,
    pageName: 'Clitoral-Vibrators',
    h1: 'CLITORAL VIBRATORS',
    url: 'pleasure-toys/clitoral-vibrators/',
    type: 'category',
    filter: 'color',
  },
];
