import { Heading } from "./types";

export const swimwear: Heading[] = [
  {
    no: '25',
    pageName: 'Swimwear',
    h1: "WOMEN'S SWIMWEAR",
    url: 'womens-wear/swimwear/',
    type: 'category',
    filter: '',
  },
  {
    no: '112',
    pageName: 'SWIM ACCESSORIES',
    h1: "WOMEN'S SWIM ACCESSORIES",
    url: 'womens-wear/swimwear/accessories/',
    type: 'category',
    filter: '',
  },
  {
    no: '113',
    pageName: 'Black',
    h1: "WOMEN'S BLACK SWIMWEAR",
    url: 'womens-wear/swimwear/black-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '114',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SWIMWEAR",
    url: 'womens-wear/swimwear/blue-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '115',
    pageName: 'Fuchsia',
    h1: "WOMEN'S FUCHSIA SWIMWEAR",
    url: 'womens-wear/swimwear/fuchsia/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '116',
    pageName: 'Green',
    h1: "WOMEN'S GREEN SWIMWEAR",
    url: 'womens-wear/swimwear/green-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '117',
    pageName: 'Ivy',
    h1: "WOMEN'S IVY SWIMWEAR",
    url: 'womens-wear/swimwear/ivy-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '118',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR SWIMWEAR",
    url: 'womens-wear/swimwear/multicolour-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '119',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SWIMWEAR",
    url: 'womens-wear/swimwear/orange-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '120',
    pageName: 'Peach',
    h1: "WOMEN'S PEACH SWIMWEAR",
    url: 'womens-wear/swimwear/peach-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '121',
    pageName: 'Pink',
    h1: "WOMEN'S PINK SWIMWEAR",
    url: 'womens-wear/swimwear/pink-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '122',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SWIMWEAR",
    url: 'womens-wear/swimwear/purple-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '123',
    pageName: 'Red',
    h1: "WOMEN'S RED SWIMWEAR",
    url: 'womens-wear/swimwear/red-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '124',
    pageName: 'White',
    h1: "WOMEN'S WHITE SWIMWEAR",
    url: 'womens-wear/swimwear/white-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '125',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW SWIMWEAR",
    url: 'womens-wear/swimwear/yellow-swimwear/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '126',
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI SWIMWEAR",
    url: 'womens-wear/swimwear/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '127',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI SWIMWEAR",
    url: 'womens-wear/swimwear/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '128',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SWIMWEAR",
    url: 'womens-wear/swimwear/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '129',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SWIMWEAR",
    url: 'womens-wear/swimwear/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '130',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL SWIMWEAR",
    url: 'womens-wear/swimwear/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '131',
    pageName: 'Party',
    h1: "WOMEN'S PARTY SWIMWEAR",
    url: 'womens-wear/swimwear/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '132',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK SWIMWEAR",
    url: 'womens-wear/swimwear/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '133',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SWIMWEAR",
    url: 'womens-wear/swimwear/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '134',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SWIMWEAR",
    url: 'womens-wear/swimwear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '135',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED SWIMWEAR",
    url: 'womens-wear/swimwear/short-sleeves/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '136',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS SWIMWEAR",
    url: 'womens-wear/swimwear/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '137',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON SWIMWEAR",
    url: 'womens-wear/swimwear/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '138',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL SWIMWEAR",
    url: 'womens-wear/swimwear/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '139',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '140',
    pageName: 'S',
    h1: "WOMEN'S S SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '141',
    pageName: 'M',
    h1: "WOMEN'S M SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '142',
    pageName: 'L',
    h1: "WOMEN'S L SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '143',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '144',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '145',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '146',
    pageName: 'L-XL',
    h1: "WOMEN'S L/XL SIZE SWIMWEAR",
    url: 'womens-wear/swimwear/l-xl/',
    type: 'category',
    filter: 'size',
  },
];
