import { Heading } from "./types";

export const legPants: Heading[] = [
  {
    no: '165',
    pageName: 'Wide Leg Pants',
    h1: "WOMEN'S WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/',
    type: 'category',
    filter: '',
  },
  {
    no: '166',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '167',
    pageName: 'Black',
    h1: "WOMEN'S BLACK WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '168',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '169',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '170',
    pageName: 'Green',
    h1: "WOMEN'S GREEN WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '171',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '172',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '173',
    pageName: 'White',
    h1: "WOMEN'S WHITE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '174',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '175',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '176',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '177',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '178',
    pageName: 'Work',
    h1: "WOMEN'S WORK WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '179',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '180',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '181',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '182',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '183',
    pageName: 'Cargo',
    h1: "WOMEN'S CARGO WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/cargo/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '184',
    pageName: 'Straight-Leg',
    h1: "WOMEN'S STRAIGHT LEG WIDE PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/straight-leg/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '185',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '186',
    pageName: 'S',
    h1: "WOMEN'S S SIZE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '187',
    pageName: 'M',
    h1: "WOMEN'S M SIZE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '188',
    pageName: 'L',
    h1: "WOMEN'S L SIZE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '189',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg-pants/xl/',
    type: 'category',
    filter: 'size',
  },
];
