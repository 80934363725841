import { Heading } from "./types";

export const loungewear: Heading[] = [
  {
    no: '3',
    pageName: 'Loungewear',
    h1: "WOMEN'S LOUNGEWEAR",
    url: 'womens-wear/loungewear/',
    type: 'category',
    filter: '',
  },
  {
    no: '4',
    pageName: 'Lounge Shorts',
    h1: "WOMEN'S LOUNGE SHORTS",
    url: 'womens-wear/loungewear/lounge-shorts/',
    type: 'category',
    filter: '',
  },
  {
    no: '5',
    pageName: 'Lounge Pants',
    h1: "WOMEN'S LOUNGE PANTS",
    url: 'womens-wear/loungewear/lounge-pants/',
    type: 'category',
    filter: '',
  },
  {
    no: '6',
    pageName: 'Black',
    h1: "WOMEN'S BLACK LOUNGEWEAR",
    url: 'womens-wear/loungewear/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '7',
    pageName: 'Green',
    h1: "WOMEN'S GREEN LOUNGEWEAR",
    url: 'womens-wear/loungewear/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '8',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR LOUNGEWEAR",
    url: 'womens-wear/loungewear/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '9',
    pageName: 'Pink',
    h1: "WOMEN'S PINK LOUNGEWEAR",
    url: 'womens-wear/loungewear/pink/',
    type: 'category',
    filter: '',
  },
  {
    no: '10',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY LOUNGEWEAR",
    url: 'womens-wear/loungewear/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '11',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST LOUNGEWEAR",
    url: 'womens-wear/loungewear/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '12',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH LOUNGEWEAR",
    url: 'womens-wear/loungewear/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '13',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL LOUNGEWEAR",
    url: 'womens-wear/loungewear/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '14',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT LOUNGEWEAR",
    url: 'womens-wear/loungewear/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '15',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL LOUNGEWEAR",
    url: 'womens-wear/loungewear/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '16',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK LOUNGEWEAR",
    url: 'womens-wear/loungewear/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '17',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN LOUNGEWEAR",
    url: 'womens-wear/loungewear/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '18',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING LOUNGEWEAR",
    url: 'womens-wear/loungewear/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '19',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER LOUNGEWEAR",
    url: 'womens-wear/loungewear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '20',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER LOUNGEWEAR",
    url: 'womens-wear/loungewear/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '21',
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT LOUNGEWEAR",
    url: 'womens-wear/loungewear/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '22',
    pageName: 'S',
    h1: "WOMEN'S S SIZE LOUNGEWEAR",
    url: 'womens-wear/loungewear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '23',
    pageName: 'M',
    h1: "WOMEN'S M SIZE LOUNGEWEAR",
    url: 'womens-wear/loungewear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '24',
    pageName: 'L',
    h1: "WOMEN'S L SIZE LOUNGEWEAR",
    url: 'womens-wear/loungewear/l/',
    type: 'category',
    filter: 'size',
  },
];
