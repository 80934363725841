import { Heading } from "./types";

export const jeans: Heading[] = [
  {
    no: '234',
    pageName: 'Jeans',
    h1: "WOMEN'S JEANS",
    url: 'bottoms/jeans/',
    type: 'category',
    filter: '',
  },
  {
    no: '235',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE JEANS",
    url: 'bottoms/jeans/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '236',
    pageName: 'Black',
    h1: "WOMEN'S BLACK JEANS",
    url: 'bottoms/jeans/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '237',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE JEANS",
    url: 'bottoms/jeans/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '238',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN JEANS",
    url: 'bottoms/jeans/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '239',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM JEANS",
    url: 'bottoms/jeans/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '240',
    pageName: 'Green',
    h1: "WOMEN'S GREEN JEANS",
    url: 'bottoms/jeans/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '241',
    pageName: 'Pink',
    h1: "WOMEN'S PINK JEANS",
    url: 'bottoms/jeans/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '242',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE JEANS",
    url: 'bottoms/jeans/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '243',
    pageName: 'Red',
    h1: "WOMEN'S RED JEANS",
    url: 'bottoms/jeans/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '244',
    pageName: 'White',
    h1: "WOMEN'S WHITE JEANS",
    url: 'bottoms/jeans/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '245',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH JEANS",
    url: 'bottoms/jeans/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '246',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL JEANS",
    url: 'bottoms/jeans/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '247',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT JEANS",
    url: 'bottoms/jeans/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '248',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL JEANS",
    url: 'bottoms/jeans/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '249',
    pageName: 'Party',
    h1: "WOMEN'S PARTY JEANS",
    url: 'bottoms/jeans/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '250',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK JEANS",
    url: 'bottoms/jeans/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '251',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN JEANS",
    url: 'bottoms/jeans/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '252',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING JEANS",
    url: 'bottoms/jeans/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '253',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER JEANS",
    url: 'bottoms/jeans/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '254',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER JEANS",
    url: 'bottoms/jeans/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '255',
    pageName: 'Baggy',
    h1: "WOMEN'S BAGGY JEANS",
    url: 'bottoms/jeans/baggy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '256',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON JEANS",
    url: 'bottoms/jeans/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '257',
    pageName: 'Cargo',
    h1: "WOMEN'S CARGO JEANS",
    url: 'bottoms/jeans/cargo/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '258',
    pageName: 'High-Waisted',
    h1: "WOMEN'S HIGH WAISTED JEANS",
    url: 'bottoms/jeans/high-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '259',
    pageName: 'Low-Waisted',
    h1: "WOMEN'S LOW WAISTED JEANS",
    url: 'bottoms/jeans/low-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '260',
    pageName: 'Mid-Waisted',
    h1: "WOMEN'S MID WAISTED JEANS",
    url: 'bottoms/jeans/mid-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '261',
    pageName: 'Slim',
    h1: "WOMEN'S SLIM JEANS",
    url: 'bottoms/jeans/slim/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '262',
    pageName: 'Straight-Leg',
    h1: "WOMEN'S STRAIGHT LEG  JEANS",
    url: 'bottoms/jeans/straight-leg/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '263',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE JEANS",
    url: 'bottoms/jeans/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '264',
    pageName: 'S',
    h1: "WOMEN'S S SIZE JEANS",
    url: 'bottoms/jeans/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '265',
    pageName: 'M',
    h1: "WOMEN'S M SIZE JEANS",
    url: 'bottoms/jeans/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '266',
    pageName: 'L',
    h1: "WOMEN'S L SIZE JEANS",
    url: 'bottoms/jeans/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '267',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE JEANS",
    url: 'bottoms/jeans/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '268',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE JEANS",
    url: 'bottoms/jeans/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '269',
    pageName: '7',
    h1: "WOMEN'S 7 SIZE JEANS",
    url: 'bottoms/jeans/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '270',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE JEANS",
    url: 'bottoms/jeans/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '271',
    pageName: '9',
    h1: "WOMEN'S 9 SIZE JEANS",
    url: 'bottoms/jeans/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '272',
    pageName: '10',
    h1: "WOMEN'S 10 SIZE JEANS",
    url: 'bottoms/jeans/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '273',
    pageName: '11',
    h1: "WOMEN'S 11 SIZE JEANS",
    url: 'bottoms/jeans/11/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '274',
    pageName: '12',
    h1: "WOMEN'S 12 SIZE JEANS",
    url: 'bottoms/jeans/12/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '275',
    pageName: '13',
    h1: "WOMEN'S 13 SIZE JEANS",
    url: 'bottoms/jeans/13/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '276',
    pageName: '14',
    h1: "WOMEN'S 14 SIZE JEANS",
    url: 'bottoms/jeans/14/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '277',
    pageName: '24',
    h1: "WOMEN'S 24 SIZE JEANS",
    url: 'bottoms/jeans/24/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '278',
    pageName: '25',
    h1: "WOMEN'S 25 SIZE JEANS",
    url: 'bottoms/jeans/25/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '279',
    pageName: '26',
    h1: "WOMEN'S 26 SIZE JEANS",
    url: 'bottoms/jeans/26/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '280',
    pageName: '27',
    h1: "WOMEN'S 27 SIZE JEANS",
    url: 'bottoms/jeans/27/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '281',
    pageName: '28',
    h1: "WOMEN'S 28 SIZE JEANS",
    url: 'bottoms/jeans/28/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '282',
    pageName: '29',
    h1: "WOMEN'S 29 SIZE JEANS",
    url: 'bottoms/jeans/29/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '283',
    pageName: '30',
    h1: "WOMEN'S 30 SIZE JEANS",
    url: 'bottoms/jeans/30/',
    type: 'category',
    filter: 'size',
  },
];
