import { Heading } from "./types";

export const outerwear: Heading[] = [
  {
    no: '222',
    pageName: 'Outerwear',
    h1: "WOMEN'S OUTERWEAR",
    url: 'womens-wear/outerwear/',
    type: 'category',
    filter: '',
  },
  {
    no: '348',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM OUTERWEAR",
    url: 'womens-wear/outerwear/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '349',
    pageName: 'Black',
    h1: "WOMEN'S BLACK OUTERWEAR",
    url: 'womens-wear/outerwear/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '350',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE OUTERWEAR",
    url: 'womens-wear/outerwear/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '351',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN OUTERWEAR",
    url: 'womens-wear/outerwear/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '352',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM OUTERWEAR",
    url: 'womens-wear/outerwear/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '353',
    pageName: 'Green',
    h1: "WOMEN'S GREEN OUTERWEAR",
    url: 'womens-wear/outerwear/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '354',
    pageName: 'Grey',
    h1: "WOMEN'S GREY OUTERWEAR",
    url: 'womens-wear/outerwear/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '355',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR OUTERWEAR",
    url: 'womens-wear/outerwear/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '356',
    pageName: 'Pink',
    h1: "WOMEN'S PINK OUTERWEAR",
    url: 'womens-wear/outerwear/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '357',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE OUTERWEAR",
    url: 'womens-wear/outerwear/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '358',
    pageName: 'White',
    h1: "WOMEN'S WHITE OUTERWEAR",
    url: 'womens-wear/outerwear/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '359',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY OUTERWEAR",
    url: 'womens-wear/outerwear/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '360',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST OUTERWEAR",
    url: 'womens-wear/outerwear/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '361',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH OUTERWEAR",
    url: 'womens-wear/outerwear/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '362',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL OUTERWEAR",
    url: 'womens-wear/outerwear/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '363',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT OUTERWEAR",
    url: 'womens-wear/outerwear/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '364',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL OUTERWEAR",
    url: 'womens-wear/outerwear/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '365',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL OUTERWEAR",
    url: 'womens-wear/outerwear/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '366',
    pageName: 'Party',
    h1: "WOMEN'S PARTY OUTERWEAR",
    url: 'womens-wear/outerwear/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '367',
    pageName: 'Racing',
    h1: "WOMEN'S RACING OUTERWEAR",
    url: 'womens-wear/outerwear/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '368',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK OUTERWEAR",
    url: 'womens-wear/outerwear/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '369',
    pageName: 'Work',
    h1: "WOMEN'S WORK OUTERWEAR",
    url: 'womens-wear/outerwear/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '370',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN OUTERWEAR",
    url: 'womens-wear/outerwear/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '371',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING OUTERWEAR",
    url: 'womens-wear/outerwear/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '372',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER OUTERWEAR",
    url: 'womens-wear/outerwear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '373',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER OUTERWEAR",
    url: 'womens-wear/outerwear/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '374',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED OUTERWEAR",
    url: 'womens-wear/outerwear/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '375',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED OUTERWEAR",
    url: 'womens-wear/outerwear/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '376',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS OUTERWEAR",
    url: 'womens-wear/outerwear/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '377',
    pageName: 'Bomber',
    h1: "WOMEN'S BOMBER OUTERWEAR",
    url: 'womens-wear/outerwear/bomber/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '378',
    pageName: 'Faux-Leather',
    h1: "WOMEN'S FAUX LEATHER OUTERWEAR",
    url: 'womens-wear/outerwear/faux-leather/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '379',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL OUTERWEAR",
    url: 'womens-wear/outerwear/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '380',
    pageName: 'Knit',
    h1: "WOMEN'S KNIT OUTERWEAR",
    url: 'womens-wear/outerwear/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '381',
    pageName: 'Puffer',
    h1: "WOMEN'S PUFFER OUTERWEAR",
    url: 'womens-wear/outerwear/puffer/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '382',
    pageName: 'Tench',
    h1: "WOMEN'S TENCH OUTERWEAR",
    url: 'womens-wear/outerwear/tench/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '383',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '384',
    pageName: 'S',
    h1: "WOMEN'S S SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '385',
    pageName: 'M',
    h1: "WOMEN'S M SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '386',
    pageName: 'L',
    h1: "WOMEN'S L SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '387',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '388',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '389',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '390',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '391',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE OUTERWEAR",
    url: 'womens-wear/outerwear/one-size/',
    type: 'category',
    filter: 'size',
  },
];
