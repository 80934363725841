import { useQuery } from '@tanstack/react-query';
import { personalizedRecommendations } from '@ui/axios/searchSpring';
import { ProductCardCarousel } from '@ui/components/shared';
import { recommendationProductsToSearchProducts } from '@ui/helpers.export';
import { getCookie } from '@ui/hooks/useCookie';
import HomeRecentlyViewedSection from '../../home/HomeRecentlyViewedSection/HomeRecentlyViewedSection';

export default function CollectionEmptySection() {
  const { data: recommendations } = useQuery({
    queryKey: ['collectionPageRecommendations'],
    queryFn: async () => {
      const res = await personalizedRecommendations({
        tags: 'home-page-new',
        shopper: getCookie('shopper'),
        cart: getCookie('cart'),
        lastViewed: getCookie('lastViewed'),
      });

      return recommendationProductsToSearchProducts(res.data[0].results);
    },
  });

  return (
    <div className="w-full max-w-[1800px] px-4 md:px-8 mx-auto pb-12">
      <div>
        <div className="uppercase text-[20px] tracking-[0.25px] font-semibold">Sorry,</div>
        <div className="uppercase tracking-[0.04em] font-semibold mt-2">
          There are no products matching your request.
        </div>
        <div className="tracking-[0.4px] mt-2">Try adjusting your search or discover our latest releases below.</div>
      </div>
      <div className="mt-12">
        <div className="font-semibold tracking-[0.04%] uppercase mb-4">New</div>
        <ProductCardCarousel
          collection="new"
          withControls
          carousel={{
            tag: 'home-page-new',
            placement: 'home-page',
            products: recommendations || [],
          }}
          classNames={{
            root: '-mx-8 md:mx-0',
            viewport: 'px-8 md:px-0',
            slide: 'w-[70%] md:w-[18%]',
          }}
          height={570}
          width={380}
          align="start"
        />
      </div>
      <div className="mt-12">
        <HomeRecentlyViewedSection />
      </div>
    </div>
  );
}
