import { Heading } from "./types";

export const earrings: Heading[] = [
  {
    no: '91',
    pageName: 'Earrings',
    h1: "WOMEN'S EARRINGS",
    url: 'accessories/earrings/',
    type: 'category',
    filter: '',
  },
  {
    no: '92',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE EARRINGS",
    url: 'accessories/earrings/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '93',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN EARRINGS",
    url: 'accessories/earrings/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '94',
    pageName: 'Clear',
    h1: "WOMEN'S CLEAR EARRINGS",
    url: 'accessories/earrings/clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '95',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE EARRINGS",
    url: 'accessories/earrings/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '96',
    pageName: 'Green',
    h1: "WOMEN'S GREEN EARRINGS",
    url: 'accessories/earrings/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '97',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR EARRINGS",
    url: 'accessories/earrings/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '98',
    pageName: 'Pink',
    h1: "WOMEN'S PINK EARRINGS",
    url: 'accessories/earrings/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '99',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE EARRINGS",
    url: 'accessories/earrings/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '100',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER EARRINGS",
    url: 'accessories/earrings/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '101',
    pageName: 'White',
    h1: "WOMEN'S WHITE EARRINGS",
    url: 'accessories/earrings/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '102',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY EARRINGS",
    url: 'accessories/earrings/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '103',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST EARRINGS",
    url: 'accessories/earrings/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '104',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH EARRINGS",
    url: 'accessories/earrings/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '105',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL EARRINGS",
    url: 'accessories/earrings/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '106',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT EARRINGS",
    url: 'accessories/earrings/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '107',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL EARRINGS",
    url: 'accessories/earrings/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '108',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL EARRINGS",
    url: 'accessories/earrings/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '109',
    pageName: 'Party',
    h1: "WOMEN'S PARTY EARRINGS",
    url: 'accessories/earrings/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '110',
    pageName: 'Racing',
    h1: "WOMEN'S RACING EARRINGS",
    url: 'accessories/earrings/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '111',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN EARRINGS",
    url: 'accessories/earrings/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '112',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING EARRINGS",
    url: 'accessories/earrings/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '113',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER EARRINGS",
    url: 'accessories/earrings/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '114',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL EARRINGS",
    url: 'accessories/earrings/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '115',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN EARRINGS",
    url: 'accessories/earrings/sequin/',
    type: 'category',
    filter: 'trend',
  },
];
