import { Heading } from "./types";

export const coats: Heading[] = [
  {
    no: '291',
    pageName: 'Coats',
    h1: "WOMEN'S COATS",
    url: 'womens-wear/outerwear/coats/',
    type: 'category',
    filter: '',
  },
  {
    no: '292',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM COATS",
    url: 'womens-wear/outerwear/coats/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '293',
    pageName: 'Black',
    h1: "WOMEN'S BLACK COATS",
    url: 'womens-wear/outerwear/coats/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '294',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN COATS",
    url: 'womens-wear/outerwear/coats/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '295',
    pageName: 'Green',
    h1: "WOMEN'S GREEN COATS",
    url: 'womens-wear/outerwear/coats/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '296',
    pageName: 'Grey',
    h1: "WOMEN'S GREY COATS",
    url: 'womens-wear/outerwear/coats/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '297',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH COATS",
    url: 'womens-wear/outerwear/coats/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '298',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT COATS",
    url: 'womens-wear/outerwear/coats/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '299',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL COATS",
    url: 'womens-wear/outerwear/coats/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '300',
    pageName: 'Party',
    h1: "WOMEN'S PARTY COATS",
    url: 'womens-wear/outerwear/coats/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '301',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN COATS",
    url: 'womens-wear/outerwear/coats/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '302',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER COATS",
    url: 'womens-wear/outerwear/coats/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '303',
    pageName: 'Faux-Leather',
    h1: "WOMEN'S FAUX LEATHER COATS",
    url: 'womens-wear/outerwear/coats/faux-leather/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '304',
    pageName: 'Trench',
    h1: "WOMEN'S TRENCH COATS",
    url: 'womens-wear/outerwear/coats/trench/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '305',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE COATS",
    url: 'womens-wear/outerwear/coats/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '306',
    pageName: 'S',
    h1: "WOMEN'S S SIZE COATS",
    url: 'womens-wear/outerwear/coats/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '307',
    pageName: 'M',
    h1: "WOMEN'S M SIZE COATS",
    url: 'womens-wear/outerwear/coats/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '308',
    pageName: 'L',
    h1: "WOMEN'S L SIZE COATS",
    url: 'womens-wear/outerwear/coats/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '309',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE COATS",
    url: 'womens-wear/outerwear/coats/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '310',
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE COATS",
    url: 'womens-wear/outerwear/coats/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '311',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE COATS",
    url: 'womens-wear/outerwear/coats/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '312',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE COATS",
    url: 'womens-wear/outerwear/coats/m-l/',
    type: 'category',
    filter: 'size',
  },
];
