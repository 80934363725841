import { Heading } from "./types";

export const shoes: Heading[] = [
  {
    no: 1,
    pageName: 'SHOES',
    h1: "WOMEN'S SHOES",
    url: 'shoes/',
    type: 'category',
    filter: '',
  },
  {
    no: 2,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM SHOES",
    url: 'shoes/beige-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 3,
    pageName: 'Black',
    h1: "WOMEN'S BLACK SHOES",
    url: 'shoes/black-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 4,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SHOES",
    url: 'shoes/blue-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 5,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN SHOES",
    url: 'shoes/brown-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 6,
    pageName: 'Denim',
    h1: "WOMEN'S DENIM SHOES",
    url: 'shoes/denim-shoes/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 7,
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE SHOES",
    url: 'shoes/gold-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 8,
    pageName: 'Green',
    h1: "WOMEN'S GREEN SHOES",
    url: 'shoes/green-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 9,
    pageName: 'Nude',
    h1: "WOMEN'S NUDE SHOES",
    url: 'shoes/nude-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 10,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SHOES",
    url: 'shoes/orange-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 11,
    pageName: 'Pink',
    h1: "WOMEN'S PINK SHOES",
    url: 'shoes/pink-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 12,
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SHOES",
    url: 'shoes/purple-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 13,
    pageName: 'Red',
    h1: "WOMEN'S RED SHOES",
    url: 'shoes/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 14,
    pageName: 'Silver',
    h1: "WOMEN'S SILVER SHOES",
    url: 'shoes/silver-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 15,
    pageName: 'White',
    h1: "WOMEN'S WHITE SHOES",
    url: 'shoes/white-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 16,
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW SHOES",
    url: 'shoes/yellow-shoes/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 17,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SHOES",
    url: 'shoes/beach-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 18,
    pageName: 'Bridesmaids-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAIDS & WEDDING GUEST SHOES",
    url: 'shoes/bridesmaids-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 19,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SHOES",
    url: 'shoes/brunch-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 20,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL SHOES",
    url: 'shoes/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 21,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT SHOES",
    url: 'shoes/date-night-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 22,
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL SHOES",
    url: 'shoes/festival-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 23,
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL SHOES",
    url: 'shoes/cocktail-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 24,
    pageName: 'Homecoming',
    h1: "WOMEN'S HOMECOMING SHOES",
    url: 'shoes/homecoming-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 25,
    pageName: 'Party',
    h1: "WOMEN'S PARTY SHOES",
    url: 'shoes/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 26,
    pageName: 'Racing',
    h1: "WOMEN'S RACING SHOES",
    url: 'shoes/racing-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 27,
    pageName: 'Work',
    h1: "WOMEN'S WORK SHOES",
    url: 'shoes/work-shoes/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 28,
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT SHOES",
    url: 'shoes/animal-print-shoes/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 29,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE SHOES",
    url: 'shoes/ankle/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 30,
    pageName: 'Ankle-Boot',
    h1: "WOMEN'S ANKLE BOOTS",
    url: 'shoes/ankle-boot/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 31,
    pageName: 'Ankle-Strap',
    h1: "WOMEN'S ANKLE STRAP SHOES",
    url: 'shoes/ankle-strap/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 32,
    pageName: 'Block',
    h1: "WOMEN'S BLOCK SHOES",
    url: 'shoes/block/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 33,
    pageName: 'Open-Toe',
    h1: "WOMEN'S OPEN TOE SHOES",
    url: 'shoes/open-toe/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 34,
    pageName: 'Platform',
    h1: "WOMEN'S PLATFORM SHOES",
    url: 'shoes/platform/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 35,
    pageName: 'Slip-On',
    h1: "WOMEN'S SLIP ON SHOES",
    url: 'shoes/slip-on/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 36,
    pageName: 'Stiletto',
    h1: "WOMEN'S STILETTO SHOES",
    url: 'shoes/stiletto/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 37,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN SHOES",
    url: 'shoes/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 38,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SHOES",
    url: 'shoes/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 39,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SHOES",
    url: 'shoes/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 40,
    pageName: 'Winter',
    h1: "WOMEN'S WINTER SHOES",
    url: 'shoes/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 41,
    pageName: '36',
    h1: "WOMEN'S SHOES SIZE 36",
    url: 'shoes/36/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 42,
    pageName: '38',
    h1: "WOMEN'S SHOES SIZE 38",
    url: 'shoes/38/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 43,
    pageName: '37',
    h1: "WOMEN'S SHOES SIZE 37",
    url: 'shoes/37/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 44,
    pageName: '39',
    h1: "WOMEN'S SHOES SIZE 39",
    url: 'shoes/39/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 45,
    pageName: '40',
    h1: "WOMEN'S SHOES SIZE 40",
    url: 'shoes/40/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 46,
    pageName: '41',
    h1: "WOMEN'S SHOES SIZE 41",
    url: 'shoes/41/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 47,
    pageName: '5',
    h1: "WOMEN'S SHOES SIZE 5",
    url: 'shoes/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 48,
    pageName: '6',
    h1: "WOMEN'S SHOES SIZE 6",
    url: 'shoes/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 49,
    pageName: '7',
    h1: "WOMEN'S SHOES SIZE 7",
    url: 'shoes/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 50,
    pageName: '8',
    h1: "WOMEN'S SHOES SIZE 8",
    url: 'shoes/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 51,
    pageName: '9',
    h1: "WOMEN'S SHOES SIZE 9",
    url: 'shoes/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 52,
    pageName: '10',
    h1: "WOMEN'S SHOES SIZE 10",
    url: 'shoes/10/',
    type: 'category',
    filter: 'size',
  },
];
