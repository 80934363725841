import { Heading } from "./types";

export const flats: Heading[] = [
  {
    no: 214,
    pageName: 'Flats',
    h1: "WOMEN'S FLATS",
    url: 'shoes/flats/',
    type: 'category',
    filter: '',
  },
  {
    no: 215,
    pageName: 'Black',
    h1: "WOMEN'S BLACK FLATS",
    url: 'shoes/flats/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 216,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM FLATS",
    url: 'shoes/flats/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 217,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN FLATS",
    url: 'shoes/flats/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 218,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE FLATS",
    url: 'shoes/flats/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 219,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE FLATS",
    url: 'shoes/flats/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 220,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH FLATS",
    url: 'shoes/flats/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 221,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL FLATS",
    url: 'shoes/flats/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 222,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY FLATS",
    url: 'shoes/flats/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 223,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT FLATS",
    url: 'shoes/flats/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 224,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER FLATS",
    url: 'shoes/flats/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 225,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING FLATS",
    url: 'shoes/flats/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 226,
    pageName: 'Winter',
    h1: "WOMEN'S WINTER FLATS",
    url: 'shoes/flats/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 227,
    pageName: '6',
    h1: "WOMEN'S FLATS SIZE 6",
    url: 'shoes/flats/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 228,
    pageName: '5',
    h1: "WOMEN'S FLATS SIZE 5",
    url: 'shoes/flats/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 229,
    pageName: '7',
    h1: "WOMEN'S FLATS SIZE 7",
    url: 'shoes/flats/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 230,
    pageName: '8',
    h1: "WOMEN'S FLATS SIZE 8",
    url: 'shoes/flats/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 231,
    pageName: '9',
    h1: "WOMEN'S FLATS SIZE 9",
    url: 'shoes/flats/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 232,
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT FLATS",
    url: 'shoes/flats/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 233,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE FLATS",
    url: 'shoes/flats/ankle/',
    type: 'category',
    filter: 'trend',
  },
];
