import { Heading } from "./types";

export const twoPieceSets: Heading[] = [
  {
    no: 1,
    pageName: 'TWO-PIECE SETS',
    h1: "WOMEN'S TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/',
    type: 'category',
    filter: '',
  },
  {
    no: 2,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 3,
    pageName: 'Black',
    h1: "WOMEN'S BLACK TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 4,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 5,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 6,
    pageName: 'Denim',
    h1: "WOMEN'S DENIM TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 7,
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 8,
    pageName: 'Green',
    h1: "WOMEN'S GREEN TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 9,
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 10,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 11,
    pageName: 'Pink',
    h1: "WOMEN'S PINK TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 12,
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 13,
    pageName: 'Red',
    h1: "WOMEN'S RED TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 14,
    pageName: 'Silver',
    h1: "WOMEN'S SILVER TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 15,
    pageName: 'White',
    h1: "WOMEN'S WHITE TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 16,
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 17,
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 18,
    pageName: 'Midi',
    h1: "WOMEN'S MIDI TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 19,
    pageName: 'Mini',
    h1: "WOMEN'S MINI TWO PIECE & CO-ORD SETS",
    url: 'two-piece-sets/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: 20,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 21,
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 22,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 23,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 24,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 25,
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 26,
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 27,
    pageName: 'Party',
    h1: "WOMEN'S PARTY TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 28,
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 29,
    pageName: 'Work',
    h1: "WOMEN'S WORK TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/work/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 30,
    pageName: 'Racing',
    h1: "WOMEN'S RACING TWO-PIECE AND CO-ORD SETS",
    url: 'two-piece-sets/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 31,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN TWO-PIECE SETS & CO-ORD SETS",
    url: 'two-piece-sets/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 32,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING TWO-PIECE SETS & CO-ORD SETS",
    url: 'two-piece-sets/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 33,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER TWO-PIECE SETS & CO-ORD SETS",
    url: 'two-piece-sets/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 34,
    pageName: 'Winter',
    h1: "WOMEN'S WINTER TWO-PIECE SETS & CO-ORD SETS",
    url: 'two-piece-sets/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 35,
    pageName: 'Long',
    h1: "WOMEN'S LONG SLEEVE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/long/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: 36,
    pageName: 'Short',
    h1: "WOMEN'S SHORT SLEEVE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/short/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: 37,
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: 38,
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 39,
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 40,
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 41,
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 42,
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/denim/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 43,
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 44,
    pageName: 'Halter',
    h1: "WOMEN'S HALTER TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 45,
    pageName: 'Knit',
    h1: "WOMEN'S KNIT TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 46,
    pageName: 'Lace',
    h1: "WOMEN'S LACE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 47,
    pageName: 'Mesh',
    h1: "WOMEN'S MESH TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 48,
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/milkmaid/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 49,
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 50,
    pageName: 'Print',
    h1: "WOMEN'S PRINT TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/print/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 51,
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 52,
    pageName: 'Satin',
    h1: "WOMEN'S SATIN TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 53,
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 54,
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 55,
    pageName: 'S',
    h1: "WOMEN'S S SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 56,
    pageName: 'M',
    h1: "WOMEN'S M SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 57,
    pageName: 'L',
    h1: "WOMEN'S L SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 58,
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 59,
    pageName: 'XS-S',
    h1: "WOMEN'S SX/S SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 60,
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE TWO-PIECE & CO-ORD SETS",
    url: 'two-piece-sets/s-m/',
    type: 'category',
    filter: 'size',
  },
];
