import { Heading } from "./types";

export const boots: Heading[] = [
  {
    no: 158,
    pageName: 'Boots',
    h1: "WOMEN'S BOOTS",
    url: 'shoes/boots/',
    type: 'category',
    filter: '',
  },
  {
    no: 159,
    pageName: 'Black',
    h1: "WOMEN'S BLACK BOOTS",
    url: 'shoes/boots/black-boots/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 160,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BOOTS",
    url: 'shoes/boots/beige-boots/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 161,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BOOTS",
    url: 'shoes/boots/brown-boots/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 162,
    pageName: 'Green',
    h1: "WOMEN'S GREEN BOOTS",
    url: 'shoes/boots/green-boots/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 163,
    pageName: 'Silver',
    h1: "WOMEN'S SILVER BOOTS",
    url: 'shoes/boots/silver-boots/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 164,
    pageName: 'White',
    h1: "WOMEN'S WHITE BOOTS",
    url: 'shoes/boots/white-boots/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 165,
    pageName: 'Winter',
    h1: "WOMEN'S WINTER BOOTS",
    url: 'shoes/boots/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 166,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN BOOTS",
    url: 'shoes/boots/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 167,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BOOTS",
    url: 'shoes/boots/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 168,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BOOTS",
    url: 'shoes/boots/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 169,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BOOTS",
    url: 'shoes/boots/date-night-boots/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 170,
    pageName: 'Party',
    h1: "WOMEN'S PARTY BOOTS",
    url: 'shoes/boots/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 171,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BOOTS",
    url: 'shoes/boots/brunch-boots/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 172,
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BOOTS",
    url: 'shoes/boots/festival-boots/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 173,
    pageName: '6',
    h1: "WOMEN'S BOOTS SIZE 6",
    url: 'shoes/boots/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 174,
    pageName: '8',
    h1: "WOMEN'S BOOTS SIZE 8",
    url: 'shoes/boots/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 175,
    pageName: '7',
    h1: "WOMEN'S BOOTS SIZE 7",
    url: 'shoes/boots/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 176,
    pageName: '9',
    h1: "WOMEN'S BOOTS SIZE 9",
    url: 'shoes/boots/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 177,
    pageName: '10',
    h1: "WOMEN'S BOOTS SIZE 10",
    url: 'shoes/boots/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 178,
    pageName: '5',
    h1: "WOMEN'S BOOTS SIZE 5",
    url: 'shoes/boots/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 179,
    pageName: '38',
    h1: "WOMEN'S BOOTS SIZE 38",
    url: 'shoes/boots/38/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 180,
    pageName: '39',
    h1: "WOMEN'S BOOTS SIZE 39",
    url: 'shoes/boots/39/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 181,
    pageName: '40',
    h1: "WOMEN'S BOOTS SIZE 40",
    url: 'shoes/boots/40/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 182,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE BOOTS",
    url: 'shoes/boots/ankle/',
    type: 'category',
    filter: 'trend',
  },
];
