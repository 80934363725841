import { Carousel } from '@ui/components/core';
import Link from 'next/link';
import cn from '@ui/utils/cn';

interface FilterImageLink {
  image: string;
  imageAlt?: string;
  text: string;
  href: string;
}

interface FilterImageCarouselProps {
  links: FilterImageLink[];
  title?: string;
  className?: string;
  classNames?: {
    title?: string;
    slide?: string;
    slideInner?: string;
    slideText?: string;
  };
}

const FilterImageCarousel = ({ links, title, className, classNames }: FilterImageCarouselProps) => {
  return (
    <div className={className}>
      {title && <h5 className={cn('text-sm font-bold mb-4', classNames?.title)}>{title}</h5>}
      <Carousel align="start" containScroll="keepSnaps" dragFree withControls={false} slideSize="content-width">
        {links.map((link, index) => (
          <Carousel.Slide key={link.text + 'Filter Image Carousel'}>
            <div className={cn('pr-2 md:pr-3', index === links.length - 1 && 'pr-4', classNames?.slide)}>
              <div
                className={cn(
                  'group w-[9.25rem] aspect-[1/1.5] relative overflow-hidden rounded-[0.25rem] lg:w-[11.5rem]',
                  classNames?.slideInner,
                )}
              >
                <Link href={link.href}>
                  <picture className="absolute w-full h-auto left-1/2 -translate-x-1/2 object-cover origin-top-left transition-all duration-300 group-hover:scale-110">
                    <img src={link.image} alt={link.imageAlt || link.text} />
                  </picture>
                  <div
                    className={cn(
                      'h-[3rem] w-full absolute top-0 left-0 p-[0.25rem] text-white font-bold uppercase text-xs bg-gradient-to-b from-[#00000040] lg:p-[0.375rem]',
                      classNames?.slideText,
                    )}
                  >
                    <span className="group-hover:underline">{link.text}</span>
                  </div>
                </Link>
              </div>
            </div>
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
};

export default FilterImageCarousel;
