import { useRouter } from 'next/router';
import ChipCarousel from '../ChipCarousel/ChipCarousel';
import { removeFiltersFromCurrentPath } from '@ui/helpers/filter.tsx';
import type { MenuLink } from '@ui/providers/static-content-provider';
// import { MegaMenuRoutes } from '@ui/types/routing/MegaMenuRoutingTable.type.ts';
import { toUpper } from 'lodash';
import { useStaticContent } from '@ui/providers/static-content-provider';
import { parentCollectionSelectors } from '@ui/store/parentCollectionStore';
import { Carousel } from '@ui/components/core';
import React from 'react';
import Link from 'next/link';
import cn from '@ui/utils/cn';
import { useMediaQuery } from '@mantine/hooks';

type ParentCollectionFilterObject = {
  [key: string]: { label: string; icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element; href: string };
};

type CollectionFilterItemObject = ParentCollectionFilterObject[keyof ParentCollectionFilterObject];

const FilterChipCarousel = ({ className }: { className?: string }) => {
  const isMobile = useMediaQuery('(max-width: 64rem)');
  const { parentCollectionStore } = parentCollectionSelectors;
  const setParentCollection = parentCollectionStore.use.setParentCollection();
  const parentCollection = parentCollectionStore.use.parentCollection();
  const collectionFilterMapping = useStaticContent('Collection.CollectionFilterMapping');
  const ParentCollectionAssociations = useStaticContent('Collection.ParentCollectionAssociations');
  const router = useRouter();
  const [parentCollectionObject, setParentCollectionObject] = React.useState<null | ParentCollectionFilterObject>(null);
  const [activeItemParam, setActiveItemParam] = React.useState<string>('');
  const [activeItem, setActiveItem] = React.useState<null | CollectionFilterItemObject>(null);

  React.useMemo(() => {
    if (!parentCollection)
      setParentCollection(
        Array.isArray(router.query.collections)
          ? ParentCollectionAssociations[`/collections/${router.query.collections[0]}`] || router.query.collections[0]
          : '',
      );

    if (!parentCollection) return;

    setParentCollectionObject(collectionFilterMapping[parentCollection]);

    if (!parentCollectionObject) return;

    setActiveItemParam(
      Array.isArray(router.query.collections)
        ? router.query.collections.length === 1
          ? router.query.collections[0]
          : router.query.collections[router.query.collections.length - 1]
                .split('+')
                .filter((queryParam) => queryParam.includes('trend_')).length >= 1
            ? router.query.collections[router.query.collections.length - 1]
                .split('+')
                .filter((queryParam) => queryParam.includes('trend_'))
                .reverse()[0]
            : router.query.collections[0]
        : '',
    );

    if (!activeItemParam) return;

    setActiveItem(parentCollectionObject[activeItemParam]);
  }, [
    collectionFilterMapping,
    parentCollection,
    activeItemParam,
    setParentCollection,
    router.query.collections,
    parentCollectionObject,
    ParentCollectionAssociations,
  ]);

  if (!parentCollection || !parentCollectionObject || !activeItemParam || !activeItem) return <></>;

  return (
    <Carousel
      withControls={false}
      align="start"
      slideGap={8}
      className={className}
      initialSlide={isMobile ? Object.keys(parentCollectionObject).indexOf(activeItemParam) - 1 : 0}
      dragFree
      containScroll="trimSnaps"
    >
      {Object.keys(parentCollectionObject).map((filterLinkHandle, index) => {
        const filterLinkObject = parentCollectionObject[filterLinkHandle];
        const isActive = filterLinkObject.href === activeItem.href;
        return (
          <Carousel.Slide
            key={filterLinkHandle + index}
            size={isMobile ? (index === Object.keys(parentCollectionObject).length - 1 ? '6rem' : '5rem') : '7.25rem'}
            className={cn('py-2', index === Object.keys(parentCollectionObject).length - 1 && isMobile && 'pr-4')}
          >
            <Link href={filterLinkObject.href} className={cn('block text-center group')}>
              <filterLinkObject.icon
                className={cn(
                  'text-transparent rounded-full mx-auto mb-2 group-hover:bg-[#FFF1F7]  group-active:text-[#FCC6E1] group-hover:text-[#FEDCEC]',
                  isActive ? 'text-[#FCC6E1] bg-[#FFF1F7] ' : 'text-white bg-transparent',
                )}
                width="5rem"
                height="5rem"
              />
              <span
                className={cn(
                  'block uppercase text-[0.625rem] md:text-xs group-hover:underline',
                  isActive && 'underline',
                )}
              >
                {filterLinkObject.label}
              </span>
            </Link>
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
};

export default FilterChipCarousel;
