import { Heading } from "./types";

export const slides: Heading[] = [
  {
    no: 128,
    pageName: 'Slides',
    h1: "WOMEN'S SLIDES",
    url: 'shoes/slides/',
    type: 'category',
    filter: '',
  },
  {
    no: 129,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM SLIDES",
    url: 'shoes/slides/beige-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 130,
    pageName: 'Black',
    h1: "WOMEN'S BLACK SLIDES",
    url: 'shoes/slides/black-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 131,
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SLIDES",
    url: 'shoes/slides/blue-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 132,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN SLIDES",
    url: 'shoes/slides/brown-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 133,
    pageName: 'Denim',
    h1: "WOMEN'S DENIM SLIDES",
    url: 'shoes/slides/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 134,
    pageName: 'Green',
    h1: "WOMEN'S GREEN SLIDES",
    url: 'shoes/slides/green-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 135,
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SLIDES",
    url: 'shoes/slides/orange-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 136,
    pageName: 'White',
    h1: "WOMEN'S WHITE SLIDES",
    url: 'shoes/slides/white-slides/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 137,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SLIDES",
    url: 'shoes/slides/beach-slides',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 138,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SLIDES",
    url: 'shoes/slides/brunch-slides/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 139,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL SLIDES",
    url: 'shoes/slides/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 140,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT SLIDES",
    url: 'shoes/slides/date-night-slides/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 141,
    pageName: 'Party',
    h1: "WOMEN'S PARTY SLIDES",
    url: 'shoes/slides/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 142,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SLIDES",
    url: 'shoes/slides/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 143,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SLIDES",
    url: 'shoes/slides/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 144,
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT SLIDES",
    url: 'shoes/slides/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 145,
    pageName: 'Ankle',
    h1: "WOMEN'S ANKLE SLIDES",
    url: 'shoes/slides/ankle/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 146,
    pageName: 'Slip-On',
    h1: "WOMEN'S SLIP ON SLIDES",
    url: 'shoes/slides/slip-on/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 147,
    pageName: '36',
    h1: "WOMEN'S SLIDES SIZE 36",
    url: 'shoes/slides/36/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 148,
    pageName: '37',
    h1: "WOMEN'S SLIDES SIZE 37",
    url: 'shoes/slides/37/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 149,
    pageName: '38',
    h1: "WOMEN'S SLIDES SIZE 38",
    url: 'shoes/slides/38/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 150,
    pageName: '39',
    h1: "WOMEN'S SLIDES SIZE 39",
    url: 'shoes/slides/39/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 151,
    pageName: '40',
    h1: "WOMEN'S SLIDES SIZE 40",
    url: 'shoes/slides/40/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 152,
    pageName: '5',
    h1: "WOMEN'S SLIDES SIZE 5",
    url: 'shoes/slides/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 153,
    pageName: '6',
    h1: "WOMEN'S SLIDES SIZE 6",
    url: 'shoes/slides/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 154,
    pageName: '7',
    h1: "WOMEN'S SLIDES SIZE 7",
    url: 'shoes/slides/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 155,
    pageName: '8',
    h1: "WOMEN'S SLIDES SIZE 8",
    url: 'shoes/slides/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 156,
    pageName: '9',
    h1: "WOMEN'S SLIDES SIZE 9",
    url: 'shoes/slides/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 157,
    pageName: '10',
    h1: "WOMEN'S SLIDES SIZE 10",
    url: 'shoes/slides/10/',
    type: 'category',
    filter: 'size',
  },
];
