import { Heading } from "./types";

export const coverUps: Heading[] = [
  {
    no: '88',
    pageName: 'Cover-Ups',
    h1: "WOMEN'S COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/',
    type: 'category',
    filter: '',
  },
  {
    no: '89',
    pageName: 'Green',
    h1: "WOMEN'S GREEN COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '90',
    pageName: 'Black',
    h1: "WOMEN'S BLACK COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '91',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '92',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '93',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '94',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '95',
    pageName: 'L-XL',
    h1: "WOMEN'S L/XL SIZE COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/l-xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '96',
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/m-l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '97',
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE COVER-UPS",
    url: 'womens-wear/swimwear/cover-ups/s-m/',
    type: 'category',
    filter: 'size',
  },
];
