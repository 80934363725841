import { Heading } from "./types";

export const necklaces: Heading[] = [
  {
    no: '68',
    pageName: 'Necklaces',
    h1: "WOMEN'S NECKLACES",
    url: 'accessories/necklaces/',
    type: 'category',
    filter: '',
  },
  {
    no: '69',
    pageName: 'Black',
    h1: "WOMEN'S BLACK NECKLACES",
    url: 'accessories/necklaces/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '70',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE NECKLACES",
    url: 'accessories/necklaces/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '71',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD NECKLACES",
    url: 'accessories/necklaces/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '72',
    pageName: 'Green',
    h1: "WOMEN'S GREEN NECKLACES",
    url: 'accessories/necklaces/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '73',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR NECKLACES",
    url: 'accessories/necklaces/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '74',
    pageName: 'Pink',
    h1: "WOMEN'S PINK NECKLACES",
    url: 'accessories/necklaces/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '75',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER NECKLACES",
    url: 'accessories/necklaces/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '76',
    pageName: 'White',
    h1: "WOMEN'S WHITE NECKLACES",
    url: 'accessories/necklaces/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '77',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY NECKLACES",
    url: 'accessories/necklaces/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '78',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST NECKLACES",
    url: 'accessories/necklaces/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '79',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH NECKLACES",
    url: 'accessories/necklaces/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '80',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL NECKLACES",
    url: 'accessories/necklaces/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '81',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT NECKLACES",
    url: 'accessories/necklaces/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '82',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL NECKLACES",
    url: 'accessories/necklaces/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '83',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL NECKLACES",
    url: 'accessories/necklaces/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '84',
    pageName: 'Party',
    h1: "WOMEN'S PARY NECKLACES",
    url: 'accessories/necklaces/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '85',
    pageName: 'Racing',
    h1: "WOMEN'S RACING NECKLACES",
    url: 'accessories/necklaces/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '86',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN NECKLACES",
    url: 'accessories/necklaces/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '87',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING NECKLACES",
    url: 'accessories/necklaces/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '88',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER NECKLACES",
    url: 'accessories/necklaces/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '89',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL NECKLACES",
    url: 'accessories/necklaces/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '90',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN NECKLACES",
    url: 'accessories/necklaces/sequin/',
    type: 'category',
    filter: 'trend',
  },
];
