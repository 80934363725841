import { Heading } from "./types";

export const mules: Heading[] = [
  {
    no: 101,
    pageName: 'Mules',
    h1: "WOMEN'S MULES",
    url: 'shoes/mules/',
    type: 'category',
    filter: '',
  },
  {
    no: 102,
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM MULES",
    url: 'shoes/mules/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 103,
    pageName: 'Black',
    h1: "WOMEN'S BLACK MULES",
    url: 'shoes/mules/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 104,
    pageName: 'Brown',
    h1: "WOMEN'S BROWN MULES",
    url: 'shoes/mules/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 105,
    pageName: 'Denim',
    h1: "WOMEN'S DENIM MULES",
    url: 'shoes/mules/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 106,
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE MULES",
    url: 'shoes/mules/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 107,
    pageName: 'Green',
    h1: "WOMEN'S GREEN MULES",
    url: 'shoes/mules/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 108,
    pageName: 'White',
    h1: "WOMEN'S WHITE MULES",
    url: 'shoes/mules/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: 109,
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY MULES",
    url: 'shoes/mules/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 110,
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAIDS & WEDDING GUEST MULES",
    url: 'shoes/mules/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 111,
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH MULES",
    url: 'shoes/mules/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 112,
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL MULES",
    url: 'shoes/mules/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 113,
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT MULES",
    url: 'shoes/mules/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 114,
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL MULES",
    url: 'shoes/mules/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 115,
    pageName: 'Party',
    h1: "WOMEN'S PARTY MULES",
    url: 'shoes/mules/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 116,
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN MULES",
    url: 'shoes/mules/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 117,
    pageName: 'Spring',
    h1: "WOMEN'S SPRING MULES",
    url: 'shoes/mules/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 118,
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER MULES",
    url: 'shoes/mules/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 119,
    pageName: 'Open-Toe',
    h1: "WOMEN'S OPEN TOE MULES",
    url: 'shoes/mules/open-toe/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 120,
    pageName: 'Slip-On',
    h1: "WOMEN'S SLIP ON MULES",
    url: 'shoes/mules/slip-on/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 121,
    pageName: 'Stiletto',
    h1: "WOMEN'S STILETTO MULES",
    url: 'shoes/mules/stiletto/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 122,
    pageName: '5',
    h1: "WOMEN'S MULES SIZE 5",
    url: 'shoes/mules/5/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 123,
    pageName: '6',
    h1: "WOMEN'S MULES SIZE 6",
    url: 'shoes/mules/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 124,
    pageName: '7',
    h1: "WOMEN'S MULES SIZE 7",
    url: 'shoes/mules/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 125,
    pageName: '8',
    h1: "WOMEN'S MULES SIZE 8",
    url: 'shoes/mules/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 126,
    pageName: '9',
    h1: "WOMEN'S MULES SIZE 9",
    url: 'shoes/mules/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 127,
    pageName: '10',
    h1: "WOMEN'S MULES SIZE 10",
    url: 'shoes/mules/10/',
    type: 'category',
    filter: 'size',
  },
];
