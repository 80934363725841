import { Heading } from "./types";

export const swimBottoms: Heading[] = [
  {
    no: '63',
    pageName: 'Swim Bottoms',
    h1: "WOMEN'S SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/',
    type: 'category',
    filter: '',
  },
  {
    no: '64',
    pageName: 'Black',
    h1: "WOMEN'S BLACK SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '65',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '66',
    pageName: 'Fuchsia',
    h1: "WOMEN'S FUCHSIA SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/fuchsia/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '67',
    pageName: 'Green',
    h1: "WOMEN'S GREEN SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '68',
    pageName: 'Ivy',
    h1: "WOMEN'S IVY SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/ivy/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '69',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/multicolour/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '70',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '71',
    pageName: 'Peach',
    h1: "WOMEN'S PEACH SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/peach/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '72',
    pageName: 'Pink',
    h1: "WOMEN'S PINK SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '73',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '74',
    pageName: 'Red',
    h1: "WOMEN'S RED SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/red/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '75',
    pageName: 'White',
    h1: "WOMEN'S WHITE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '76',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '77',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '78',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '79',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/spring-break/',
    type: 'category',
    filter: '',
  },
  {
    no: '80',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '81',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '82',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '83',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '84',
    pageName: 'S',
    h1: "WOMEN'S S SIZE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '85',
    pageName: 'M',
    h1: "WOMEN'S M SIZE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '86',
    pageName: 'L',
    h1: "WOMEN'S L SIZE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '87',
    pageName: 'Xl',
    h1: "WOMEN'S XL SIZE SWIM BOTTOMS",
    url: 'womens-wear/swimwear/swim-bottoms/xl/',
    type: 'category',
    filter: 'size',
  },
];
