import { Heading } from "./types";

export const accessories: Heading[] = [
  {
    no: '1',
    pageName: 'ACCESSORIES',
    h1: "WOMEN'S ACCESSORIES",
    url: 'accessories/',
    type: 'category',
    filter: '',
  },
  {
    no: '2',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM ACCESSORIES",
    url: 'accessories/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '3',
    pageName: 'Black',
    h1: "WOMEN'S BLACK ACCESSORIES",
    url: 'accessories/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '4',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE ACCESSORIES",
    url: 'accessories/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '5',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN ACCESSORIES",
    url: 'accessories/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '6',
    pageName: 'Clear',
    h1: "WOMEN'S CLEAR ACCESSORIES",
    url: 'accessories/clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '7',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM ACCESSORIES",
    url: 'accessories/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '8',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE ACCESSORIES",
    url: 'accessories/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '9',
    pageName: 'Gold-Clear',
    h1: "WOMEN'S GOLD/CLEAR ACCESSORIES",
    url: 'accessories/gold-clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '10',
    pageName: 'Green',
    h1: "WOMEN'S GREEN ACCESSORIES",
    url: 'accessories/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '11',
    pageName: 'Grey',
    h1: "WOMEN'S GREY ACCESSORIES",
    url: 'accessories/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '12',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR ACCESSORIES",
    url: 'accessories/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '13',
    pageName: 'Nude',
    h1: "WOMEN'S NUDE ACCESSORIES",
    url: 'accessories/nude/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '14',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE ACCESSORIES",
    url: 'accessories/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '15',
    pageName: 'Pink',
    h1: "WOMEN'S PINK ACCESSORIES",
    url: 'accessories/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '16',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE ACCESSORIES",
    url: 'accessories/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '17',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER ACCESSORIES",
    url: 'accessories/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '18',
    pageName: 'White',
    h1: "WOMEN'S WHITE ACCESSORIES",
    url: 'accessories/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '19',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW ACCESSORIES",
    url: 'accessories/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '20',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY ACCESSORIES",
    url: 'accessories/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '21',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST ACCESSORIES",
    url: 'accessories/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '22',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH ACCESSORIES",
    url: 'accessories/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '23',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL ACCESSORIES",
    url: 'accessories/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '24',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT ACCESSORIES",
    url: 'accessories/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '25',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL ACCESSORIES",
    url: 'accessories/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '26',
    pageName: 'Formal-&-Cocktail',
    h1: 'WOMENS FORMAL & COCKTAIL ACCESSORIES',
    url: 'accessories/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '27',
    pageName: 'Party',
    h1: "WOMEN'S PARTY ACCESSORIES",
    url: 'accessories/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '28',
    pageName: 'Racing',
    h1: "WOMEN'S RACING ACCESSORIES",
    url: 'accessories/racing/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '29',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN ACCESSORIES",
    url: 'accessories/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '30',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING ACCESSORIES",
    url: 'accessories/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '31',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER ACCESSORIES",
    url: 'accessories/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '32',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS ACCESSORIES",
    url: 'accessories/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '33',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER ACCESSORIES",
    url: 'accessories/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '34',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL ACCESSORIES",
    url: 'accessories/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '35',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN ACCESSORIES",
    url: 'accessories/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '36',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE ACCESSORIES",
    url: 'accessories/one-size/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '37',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE ACCESSORIES",
    url: 'accessories/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '38',
    pageName: '7',
    h1: "WOMEN'S 7 SIZE ACCESSORIES",
    url: 'accessories/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '39',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE ACCESSORIES",
    url: 'accessories/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '194',
    pageName: 'Belts',
    h1: "WOMEN'S BELTS",
    url: 'accessories/belts/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '195',
    pageName: 'Bandanas',
    h1: "WOMEN'S BANDANAS",
    url: 'accessories/bandanas/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '196',
    pageName: 'Rings',
    h1: "WOMEN'S RINGS",
    url: 'accessories/rings/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '197',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST RINGS",
    url: 'accessories/rings/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '198',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH RINGS",
    url: 'accessories/rings/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '199',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT RINGS",
    url: 'accessories/rings/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '200',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL RINGS",
    url: 'accessories/rings/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '201',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL RINGS",
    url: 'accessories/rings/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '202',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER RINGS",
    url: 'accessories/rings/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '203',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING RINGS",
    url: 'accessories/rings/spring/',
    type: 'category',
    filter: 'season',
  },
];
