import { Heading } from "./types";

export const womensBras: Heading[] = [
  {
    no: '392',
    pageName: "Women's Bras",
    h1: "WOMEN'S BRAS",
    url: 'womens-wear/womens-bras/',
    type: 'category',
    filter: '',
  },
  {
    no: '393',
    pageName: 'Sports Bras',
    h1: 'SPORTS BRAS',
    url: 'womens-wear/womens-bras/sports-bras/',
    type: 'category',
    filter: '',
  },
  {
    no: '394',
    pageName: 'Bra Pasties',
    h1: 'BRA PASTIES',
    url: 'womens-wear/womens-bras/bra-pasties/',
    type: 'category',
    filter: '',
  },
  {
    no: '395',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BRAS",
    url: 'womens-wear/womens-bras/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '396',
    pageName: 'Black',
    h1: "WOMEN'S BLACK BRAS",
    url: 'womens-wear/womens-bras/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '397',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BRAS",
    url: 'womens-wear/womens-bras/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '398',
    pageName: 'Clear',
    h1: "WOMEN'S CLEAR BRAS",
    url: 'womens-wear/womens-bras/clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '399',
    pageName: 'Nude',
    h1: "WOMEN'S NUDE BRAS",
    url: 'womens-wear/womens-bras/nude/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '400',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BRAS",
    url: 'womens-wear/womens-bras/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '401',
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS BRAS",
    url: 'womens-wear/womens-bras/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '402',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER BRAS",
    url: 'womens-wear/womens-bras/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '403',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE BRAS",
    url: 'womens-wear/womens-bras/one-size/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '404',
    pageName: 'A',
    h1: "WOMEN'S SIZE A BRAS",
    url: 'womens-wear/womens-bras/a/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '405',
    pageName: 'B',
    h1: "WOMEN'S SIZE B BRAS",
    url: 'womens-wear/womens-bras/b/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '406',
    pageName: 'C',
    h1: "WOMEN'S SIZE C BRAS",
    url: 'womens-wear/womens-bras/c/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '407',
    pageName: 'D',
    h1: "WOMEN'S SIZE D BRAS",
    url: 'womens-wear/womens-bras/d/',
    type: 'category',
    filter: 'size',
  },
];
