import { Heading } from './types';

export const shorts: Heading[] = [
  {
    no: '73',
    pageName: 'Shorts',
    h1: "WOMEN'S SHORTS",
    url: 'bottoms/womens-shorts/',
    type: 'category',
    filter: '',
  },
  {
    no: '74',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM SHORTS",
    url: 'bottoms/womens-shorts/beige-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '75',
    pageName: 'Black',
    h1: "WOMEN'S BLACK SHORTS",
    url: 'bottoms/womens-shorts/black-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '76',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE SHORTS",
    url: 'bottoms/womens-shorts/blue-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '77',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM SHORTS",
    url: 'bottoms/womens-shorts/denim-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '78',
    pageName: 'Green',
    h1: "WOMEN'S GREEN SHORTS",
    url: 'bottoms/womens-shorts/green-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '79',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR SHORTS",
    url: 'bottoms/womens-shorts/multicolour-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '80',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE SHORTS",
    url: 'bottoms/womens-shorts/orange-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '81',
    pageName: 'Pink',
    h1: "WOMEN'S PINK SHORTS",
    url: 'bottoms/womens-shorts/pink-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '82',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SHORTS",
    url: 'bottoms/womens-shorts/purple-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '83',
    pageName: 'Red',
    h1: "WOMEN'S RED SHORTS",
    url: 'bottoms/womens-shorts/red-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '84',
    pageName: 'White',
    h1: "WOMEN'S WHITE SHORTS",
    url: 'bottoms/womens-shorts/white-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '85',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW SHORTS",
    url: 'bottoms/womens-shorts/yellow-shorts/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '86',
    pageName: 'Midi',
    h1: "WOMEN'S MIDI SHORTS",
    url: 'bottoms/womens-shorts/midi/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '87',
    pageName: 'Mini',
    h1: "WOMEN'S MINI SHORTS",
    url: 'bottoms/womens-shorts/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    no: '88',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY SHORTS",
    url: 'bottoms/womens-shorts/beach-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '89',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAIDS & WEDDING GUEST SHORTS",
    url: 'bottoms/womens-shorts/bridesmaids-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '90',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH SHORTS",
    url: 'bottoms/womens-shorts/brunch-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '91',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL SHORTS",
    url: 'bottoms/womens-shorts/formal-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '92',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL SHORTS",
    url: 'bottoms/womens-shorts/casual-shorts',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '93',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT SHORTS",
    url: 'bottoms/womens-shorts/date-night-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '94',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL SHORTS",
    url: 'bottoms/womens-shorts/festival-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '95',
    pageName: 'Party',
    h1: "WOMEN'S PARTY SHORTS",
    url: 'bottoms/womens-shorts/party-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '96',
    pageName: 'Racing',
    h1: "WOMEN'S RACING SHORTS",
    url: 'bottoms/womens-shorts/racing-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '97',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK SHORTS",
    url: 'bottoms/womens-shorts/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '98',
    pageName: 'Work',
    h1: "WOMEN'S WORK SHORTS",
    url: 'bottoms/womens-shorts/work-shorts/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '99',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN SHORTS",
    url: 'bottoms/womens-shorts/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '100',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING SHORTS",
    url: 'bottoms/womens-shorts/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '101',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SHORTS",
    url: 'bottoms/womens-shorts/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '102',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER SHORTS",
    url: 'bottoms/womens-shorts/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '103',
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED SHORTS",
    url: 'bottoms/womens-shorts/short-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '104',
    pageName: 'Sleeveless',
    h1: "WOME'S SLEEVELESS SHORTS",
    url: 'bottoms/womens-shorts/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '105',
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL SHORTS",
    url: 'bottoms/womens-shorts/floral-shorts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '106',
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY SHORTS",
    url: 'bottoms/womens-shorts/flowy-shorts/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '107',
    pageName: 'Fashion',
    h1: "WOMEN'S FASHION SHORTS",
    url: 'bottoms/womens-shorts/fashion-shorts',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '108',
    pageName: 'High-Waisted',
    h1: "WOMEN'S HIGH WAISTED SHORTS",
    url: 'bottoms/womens-shorts/high-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '109',
    pageName: 'Lace',
    h1: "WOMEN'S LACE SHORTS",
    url: 'bottoms/womens-shorts/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '110',
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER SHORTS",
    url: 'bottoms/womens-shorts/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '111',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE SHORTS",
    url: 'bottoms/womens-shorts/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '112',
    pageName: 'S',
    h1: "WOMEN'S S SIZE SHORTS",
    url: 'bottoms/womens-shorts/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '113',
    pageName: 'M',
    h1: "WOMEN'S M SIZE SHORTS",
    url: 'bottoms/womens-shorts/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '114',
    pageName: 'L',
    h1: "WOMEN'S L SIZE SHORTS",
    url: 'bottoms/womens-shorts/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '115',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE SHORTS",
    url: 'bottoms/womens-shorts/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '116',
    pageName: '6',
    h1: "WOMEN'S 6 SIZE SHORTS",
    url: 'bottoms/womens-shorts/6/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '117',
    pageName: '7',
    h1: "WOMEN'S 7 SIZE SHORTS",
    url: 'bottoms/womens-shorts/7/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '118',
    pageName: '8',
    h1: "WOMEN'S 8 SIZE SHORTS",
    url: 'bottoms/womens-shorts/8/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '119',
    pageName: '9',
    h1: "WOMEN'S 9 SIZE SHORTS",
    url: 'bottoms/womens-shorts/9/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '120',
    pageName: '10',
    h1: "WOMEN'S 10 SIZE SHORTS",
    url: 'bottoms/womens-shorts/10/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '121',
    pageName: '11',
    h1: "WOMEN'S 11 SIZE SHORTS",
    url: 'bottoms/womens-shorts/11/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '122',
    pageName: '12',
    h1: "WOMEN'S 12 SIZE SHORTS",
    url: 'bottoms/womens-shorts/12/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '123',
    pageName: '24',
    h1: "WOMEN'S 24 SIZE SHORTS",
    url: 'bottoms/womens-shorts/24/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '124',
    pageName: '25',
    h1: "WOMEN'S 25 SIZE SHORTS",
    url: 'bottoms/womens-shorts/25/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '125',
    pageName: '26',
    h1: "WOMEN'S 26 SIZE SHORTS",
    url: 'bottoms/womens-shorts/26/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '126',
    pageName: '27',
    h1: "WOMEN'S 27 SIZE SHORTS",
    url: 'bottoms/womens-shorts/27/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '127',
    pageName: '30',
    h1: "WOMEN'S 30 SIZE SHORTS",
    url: 'bottoms/womens-shorts/30/',
    type: 'category',
    filter: 'size',
  },
];
