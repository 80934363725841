import { Heading } from "./types";

export const bags: Heading[] = [
  {
    no: '123',
    pageName: 'Bags',
    h1: "WOMEN'S BAGS",
    url: 'accessories/bags/',
    type: 'category',
    filter: '',
  },
  {
    no: '124',
    pageName: 'Black',
    h1: "WOMEN'S BLACK BAGS",
    url: 'accessories/bags/black/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '125',
    pageName: 'Pink',
    h1: "WOMEN'S PINK BAGS",
    url: 'accessories/bags/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '126',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM BAGS",
    url: 'accessories/bags/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '127',
    pageName: 'White',
    h1: "WOMEN'S WHITE BAGS",
    url: 'accessories/bags/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '128',
    pageName: 'Silver',
    h1: "WOMEN'S SILVER BAGS",
    url: 'accessories/bags/silver/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '129',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE BAGS",
    url: 'accessories/bags/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '130',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN BAGS",
    url: 'accessories/bags/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '131',
    pageName: 'Green',
    h1: "WOMEN'S GREEN BAGS",
    url: 'accessories/bags/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '132',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE BAGS",
    url: 'accessories/bags/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '133',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR BAGS",
    url: 'accessories/bags/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '134',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE BAGS",
    url: 'accessories/bags/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '135',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW BAGS",
    url: 'accessories/bags/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '136',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM BAGS",
    url: 'accessories/bags/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '137',
    pageName: 'Nude',
    h1: "WOMEN'S NUDE BAGS",
    url: 'accessories/bags/nude/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '138',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE BAGS",
    url: 'accessories/bags/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '139',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT BAGS",
    url: 'accessories/bags/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '140',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH BAGS",
    url: 'accessories/bags/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '141',
    pageName: 'Party',
    h1: "WOMEN'S PARTY BAGS",
    url: 'accessories/bags/party/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '142',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL BAGS",
    url: 'accessories/bags/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '143',
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST BAGS",
    url: 'accessories/bags/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '144',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY BAGS",
    url: 'accessories/bags/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '145',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL BAGS",
    url: 'accessories/bags/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '146',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL BAGS",
    url: 'accessories/bags/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '147',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER BAGS",
    url: 'accessories/bags/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '148',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING BAGS",
    url: 'accessories/bags/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '149',
    pageName: 'Shoulder-Bag',
    h1: "WOMEN'S SHOULDER BAGS",
    url: 'accessories/bags/shoulder-bags/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '150',
    pageName: 'Crossbody',
    h1: "WOMEN'S CROSSBODY BAGS",
    url: 'accessories/bags/crossbody/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '151',
    pageName: 'Mini',
    h1: "WOMEN'S MINI BAGS",
    url: 'accessories/bags/mini/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '152',
    pageName: 'Top-Handle',
    h1: "WOMEN'S TOP HANDLE BAGS",
    url: 'accessories/bags/top-handle/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '153',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN BAGS",
    url: 'accessories/bags/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '154',
    pageName: 'Tote',
    h1: "WOMEN'S TOTE BAGS",
    url: 'accessories/bags/tote/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '155',
    pageName: 'One-Size',
    h1: "WOMEN'S ONE SIZE BAGS",
    url: 'accessories/bags/one-size/',
    type: 'category',
    filter: 'size',
  },
];
