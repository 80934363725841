import { Heading } from "./types";

export const cropTops: Heading[] = [
  {
    pageName: 'Shirt',
    h1: "WOMEN'S SHIRT CROP TOPS",
    url: 'womens-tops/crop-tops/shirt/',
    type: 'category',
    filter: '',
  },
  {
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM CROP TOPS",
    url: 'womens-tops/crop-tops/beige-cream/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Black',
    h1: "WOMEN'S BLACK CROP TOPS",
    url: 'womens-tops/crop-tops/black/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Blue',
    h1: "WOMEN'S BLUE CROP TOPS",
    url: 'womens-tops/crop-tops/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Brown',
    h1: "WOMEN'S BROWN CROP TOPS",
    url: 'womens-tops/crop-tops/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Denim',
    h1: "WOMEN'S DENIM CROP TOPS",
    url: 'womens-tops/crop-tops/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE CROP TOPS",
    url: 'womens-tops/crop-tops/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Green',
    h1: "WOMEN'S GREEN CROP TOPS",
    url: 'womens-tops/crop-tops/green/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Grey',
    h1: "WOMEN'S GREY CROP TOPS",
    url: 'womens-tops/crop-tops/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Multicolor',
    h1: "WOMEN'S MULTIcolor CROP TOPS",
    url: 'womens-tops/crop-tops/multicolor/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE CROP TOPS",
    url: 'womens-tops/crop-tops/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Pink',
    h1: "WOMEN'S PINK CROP TOPS",
    url: 'womens-tops/crop-tops/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE CROP TOPS",
    url: 'womens-tops/crop-tops/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Red',
    h1: "WOMEN'S RED CROP TOPS",
    url: 'womens-tops/crop-tops/red/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'White',
    h1: "WOMEN'S WHITE CROP TOPS",
    url: 'womens-tops/crop-tops/white/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW CROP TOPS",
    url: 'womens-tops/crop-tops/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    pageName: 'Maxi',
    h1: "WOMEN'S MAXI CROP TOPS",
    url: 'womens-tops/crop-tops/maxi/',
    type: 'category',
    filter: 'length',
  },
  {
    pageName: 'Mini',
    h1: "WOMEN'S MINI CROP TOPS",
    url: 'womens-tops/crop-tops/mini/',
    type: 'category',
    filter: 'length',
  },
  {
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY CROP TOPS",
    url: 'womens-tops/crop-tops/beach-holiday/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Bridesmaid-&-Wedding-Guest',
    h1: "WOMEN'S BRIDESMAID & WEDDING GUEST CROP TOPS",
    url: 'womens-tops/crop-tops/bridesmaid-wedding-guest/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH CROP TOPS",
    url: 'womens-tops/crop-tops/brunch/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL CROP TOPS",
    url: 'womens-tops/crop-tops/casual/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT CROP TOPS",
    url: 'womens-tops/crop-tops/date-night/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL CROP TOPS",
    url: 'womens-tops/crop-tops/festival/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL CROP TOPS",
    url: 'womens-tops/crop-tops/formal-cocktail/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Party',
    h1: "WOMEN'S PARTY CROP TOPS",
    url: 'womens-tops/crop-tops/party/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Racing',
    h1: "WOMEN'S RACING CROP TOPS",
    url: 'womens-tops/crop-tops/racing/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK CROP TOPS",
    url: 'womens-tops/crop-tops/spring-break/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Work',
    h1: "WOMEN'S WORK CROP TOPS",
    url: 'womens-tops/crop-tops/work/',
    type: 'category',
    filter: 'Occassion',
  },
  {
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN CROP TOPS",
    url: 'womens-tops/crop-tops/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Spring',
    h1: "WOMEN'S SPRING CROP TOPS",
    url: 'womens-tops/crop-tops/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER CROP TOPS",
    url: 'womens-tops/crop-tops/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Winter',
    h1: "WOMEN'S WINTER CROP TOPS",
    url: 'womens-tops/crop-tops/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED CROP TOPS",
    url: 'womens-tops/crop-tops/long-sleeved/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    pageName: 'Short-Sleeved',
    h1: "WOMEN'S SHORT SLEEVED CROP TOPS",
    url: 'womens-tops/crop-tops/short-sleeved/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS CROP TOPS",
    url: 'womens-tops/crop-tops/sleeveless/',
    type: 'category',
    filter: 'Sleeves',
  },
  {
    pageName: 'Animal-Prints',
    h1: "WOMEN'S ANIMAL PRINT CROP TOPS",
    url: 'womens-tops/crop-tops/animal-prints/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Backless',
    h1: "WOMEN'S BACKLESS CROP TOPS",
    url: 'womens-tops/crop-tops/backless/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Bandeau',
    h1: "WOMEN'S BANDEAU CROP TOPS",
    url: 'womens-tops/crop-tops/bandeau/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON CROP TOPS",
    url: 'womens-tops/crop-tops/bodycon/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Cut-Out',
    h1: "WOMEN'S CUT OUT CROP TOPS",
    url: 'womens-tops/crop-tops/cut-out/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Floral',
    h1: "WOMEN'S FLORAL CROP TOPS",
    url: 'womens-tops/crop-tops/floral/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Flowy',
    h1: "WOMEN'S FLOWY CROP TOPS",
    url: 'womens-tops/crop-tops/flowy/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Halter',
    h1: "WOMEN'S HALTER CROP TOPS",
    url: 'womens-tops/crop-tops/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Knit',
    h1: "WOMEN'S KNIT CROP TOPS",
    url: 'womens-tops/crop-tops/knit/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Lace',
    h1: "WOMEN'S LACE CROP TOPS",
    url: 'womens-tops/crop-tops/lace/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Mesh',
    h1: "WOMEN'S MESH CROP TOPS",
    url: 'womens-tops/crop-tops/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Milkmaid',
    h1: "WOMEN'S MILKMAID CROP TOPS",
    url: 'womens-tops/crop-tops/milkmaid/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Off-The-Shoulder',
    h1: "WOMEN'S OFF THE SHOULDER CROP TOPS",
    url: 'womens-tops/crop-tops/off-the-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'One-Shoulder',
    h1: "WOMEN'S ONE SHOULDER CROP TOPS",
    url: 'womens-tops/crop-tops/one-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Ruched',
    h1: "WOMEN'S RUCHED CROP TOPS",
    url: 'womens-tops/crop-tops/ruched/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Satin',
    h1: "WOMEN'S SATIN CROP TOPS",
    url: 'womens-tops/crop-tops/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN CROP TOPS",
    url: 'womens-tops/crop-tops/sequin/',
    type: 'category',
    filter: 'trend',
  },
  {
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'S',
    h1: "WOMEN'S S SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/s/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'M',
    h1: "WOMEN'S M SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/m/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'L',
    h1: "WOMEN'S L SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/l/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'XS-S',
    h1: "WOMEN'S XS/S SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'S-M',
    h1: "WOMEN'S S/M SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    pageName: 'M-L',
    h1: "WOMEN'S M/L SIZE CROP TOPS",
    url: 'womens-tops/crop-tops/m-l/',
    type: 'category',
    filter: 'size',
  },
];
