import { Heading } from "./types";

export const children: Heading[] = [
  {
    no: 240,
    pageName: 'Children Shoes',
    h1: 'CHILDREN SHOES',
    url: 'shoes/children-shoes/',
    type: 'category',
    filter: '',
  },
  {
    no: 241,
    pageName: 'Brunch',
    h1: "CHILDREN'S BRUNCH SHOES",
    url: 'shoes/children-shoes/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 242,
    pageName: 'Casual',
    h1: "CHILDREN'S CASUAL SHOES",
    url: 'shoes/children-shoes/casual/',
    type: 'category',
    filter: 'occasion',
  },
];
