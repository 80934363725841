import { Heading } from './types';

export const dresses: Heading[] = [
  {
    no: 1,
    pageName: 'DRESSES',
    h1: 'DRESSES',
    url: 'dresses',
    type: 'category',
    filter: '',
  },
  {
    no: 2,
    pageName: 'Beige-Cream',
    h1: 'BEIGE DRESSES',
    url: 'dresses/beige-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 3,
    pageName: 'Black',
    h1: 'BLACK DRESSES',
    url: 'dresses/black-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 4,
    pageName: 'Blue',
    h1: 'BLUE DRESSES',
    url: 'dresses/blue-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 5,
    pageName: 'Brown',
    h1: 'BROWN DRESSES',
    url: 'dresses/brown-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 6,
    pageName: 'Denim',
    h1: 'DENIM DRESSES',
    url: 'dresses/denim-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 7,
    pageName: 'Fuchsia',
    h1: 'FUCHSIA DRESSES',
    url: 'dresses/fuchsia-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 8,
    pageName: 'Gold-Bronze',
    h1: 'GOLD/BRONZE DRESSES',
    url: 'dresses/gold-bronze/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 9,
    pageName: 'Green',
    h1: 'GREEN DRESSES',
    url: 'dresses/green-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 10,
    pageName: 'Grey',
    h1: 'GREY DRESSES',
    url: 'dresses/grey-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 11,
    pageName: 'Multipattern',
    h1: 'MULTICOLOUR DRESSES',
    url: 'dresses/multicolour-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 12,
    pageName: 'Orange',
    h1: 'ORANGE DRESSES',
    url: 'dresses/orange-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 13,
    pageName: 'Pink',
    h1: 'PINK DRESSES',
    url: 'dresses/pink-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 14,
    pageName: 'Purple',
    h1: 'PURPLE DRESSES',
    url: 'dresses/purple-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 15,
    pageName: 'Red',
    h1: 'RED DRESSES',
    url: 'dresses/red-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 16,
    pageName: 'Silver',
    h1: 'SILVER DRESSES',
    url: 'dresses/silver-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 17,
    pageName: 'White',
    h1: 'WHITE DRESSES',
    url: 'dresses/white-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 18,
    pageName: 'Yellow',
    h1: 'YELLOW DRESSES',
    url: 'dresses/yellow-dresses/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: 19,
    pageName: 'Maxi',
    h1: 'MAXI DRESSES',
    url: 'dresses/maxi-dresses/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    no: 20,
    pageName: 'Midi',
    h1: 'MIDI DRESSES',
    url: 'dresses/midi-dresses/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    no: 21,
    pageName: 'Mini',
    h1: 'MINI DRESSES',
    url: 'dresses/mini-dresses/',
    type: 'filter/category',
    filter: 'length',
  },
  {
    no: 22,
    pageName: 'Beach-&-Holiday',
    h1: 'BEACH DRESSES',
    url: 'dresses/beach-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 23,
    pageName: 'Bridesmaids',
    h1: 'BRIDESMAIDS DRESSES',
    url: 'dresses/bridesmaids-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 24,
    pageName: 'Brunch',
    h1: 'BRUNCH DRESSES',
    url: 'dresses/brunch-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 25,
    pageName: 'Casual',
    h1: 'CASUAL DRESSES',
    url: 'dresses/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 26,
    pageName: 'Date-Night',
    h1: 'DATE NIGHT DRESSES',
    url: 'dresses/date-night-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 27,
    pageName: 'Festival',
    h1: 'FESTIVAL DRESSES',
    url: 'dresses/festival-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 28,
    pageName: 'Formal-&-Cocktail',
    h1: 'COCKTAIL DRESSES',
    url: 'dresses/formal-cocktail-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 29,
    pageName: 'Formal',
    h1: 'FORMAL DRESSES',
    url: 'dresses/formal-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 30,
    pageName: 'Party',
    h1: 'PARTY DRESSES',
    url: 'dresses/party-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 31,
    pageName: 'Racing',
    h1: 'RACING DRESSES',
    url: 'dresses/racing-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 32,
    pageName: 'Spring-Break',
    h1: 'SPRING BREAK DRESSES',
    url: 'dresses/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 33,
    pageName: 'Work',
    h1: 'WORK DRESSES',
    url: 'dresses/work-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 34,
    pageName: 'Homecoming',
    h1: 'HOMECOMING DRESSES',
    url: 'dresses/homecoming-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 35,
    pageName: 'Prom',
    h1: 'PROM DRESSES',
    url: 'dresses/prom-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 36,
    pageName: 'Wedding-Guest',
    h1: 'WEDDING GUEST DRESSES',
    url: 'dresses/wedding-guest-dresses/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: 37,
    pageName: 'Valentines-Day',
    h1: "WOMEN'S VALENTINES DAY DRESSES",
    url: 'dresses/valentines-day/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 38,
    pageName: 'Halloween',
    h1: "WOMEN'S HALLOWEEN DRESSES",
    url: 'dresses/halloween-dresses/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: 39,
    pageName: 'Autumn',
    h1: 'AUTUMN DRESSES',
    url: 'dresses/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 40,
    pageName: 'Spring',
    h1: 'SPRING DRESSES',
    url: 'dresses/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 41,
    pageName: 'Summer',
    h1: 'SUMMER DRESSES',
    url: 'dresses/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 42,
    pageName: 'Winter',
    h1: 'WINTER DRESSES',
    url: 'dresses/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: 43,
    pageName: 'Long-Sleeved',
    h1: 'LONG SLEEVED DRESSES',
    url: 'dresses/long-sleeve-dresses/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 44,
    pageName: 'Short-Sleeved',
    h1: 'SHORT SLEEVED DRESSES',
    url: 'dresses/short-sleeved-dresses/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 45,
    pageName: 'Sleeveless',
    h1: 'SLEEVELESS DRESSES',
    url: 'dresses/sleeveless-dresses/',
    type: 'filter/category',
    filter: 'sleeves',
  },
  {
    no: 46,
    pageName: 'A-Line',
    h1: 'A LINE DRESSES',
    url: 'dresses/a-line-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 47,
    pageName: 'Animal-Prints',
    h1: 'ANIMAL PRINTED DRESSES',
    url: 'dresses/animal-print-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 48,
    pageName: 'Backless',
    h1: 'BACKLESS DRESSES',
    url: 'dresses/backless-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 49,
    pageName: 'Bodycon',
    h1: 'BODYCON DRESSES',
    url: 'dresses/bodycon-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 50,
    pageName: 'Corset',
    h1: 'CORSET DRESSES',
    url: 'dresses/corset/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 51,
    pageName: 'Cut-Out',
    h1: 'CUT OUT DRESSES',
    url: 'dresses/cut-out-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 52,
    pageName: 'Embellished',
    h1: 'EMBELLISHED DRESSES',
    url: 'dresses/embellished/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 53,
    pageName: 'Embroidered',
    h1: 'EMBROIDERED DRESSES',
    url: 'dresses/embroidered/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 54,
    pageName: 'Floral',
    h1: 'FLORAL DRESSES',
    url: 'dresses/floral-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 55,
    pageName: 'Flowy',
    h1: 'FLOWY DRESSES',
    url: 'dresses/flowy-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 56,
    pageName: 'Gingham',
    h1: 'GINGHAM DRESSES',
    url: 'dresses/gingham/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 57,
    pageName: 'Halter',
    h1: 'HALTER DRESSES',
    url: 'dresses/halter/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 58,
    pageName: 'Knit',
    h1: 'KNIT DRESSES',
    url: 'dresses/knit-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 59,
    pageName: 'Lace',
    h1: 'LACE DRESSES',
    url: 'dresses/lace-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 60,
    pageName: 'Mesh',
    h1: 'MESH DRESSES',
    url: 'dresses/mesh/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 61,
    pageName: 'Milkmaid',
    h1: 'MILKMAID DRESSES',
    url: 'dresses/milkmaid-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 62,
    pageName: 'Off-The-Shoulder',
    h1: 'OFF THE SHOULDER DRESSES',
    url: 'dresses/off-the-shoulder-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 63,
    pageName: 'One-Shoulder',
    h1: 'ONE SHOULDER DRESSES',
    url: 'dresses/one-shoulder/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 64,
    pageName: 'Ruched',
    h1: 'RUCHED DRESSES',
    url: 'dresses/ruched-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 65,
    pageName: 'Satin',
    h1: 'SATIN DRESSES',
    url: 'dresses/satin-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 66,
    pageName: 'Sequin',
    h1: 'SEQUIN DRESSES',
    url: 'dresses/sequin-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 67,
    pageName: 'Shift',
    h1: 'SHIFT DRESSES',
    url: 'dresses/shift/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: 68,
    pageName: 'Slip',
    h1: 'SLIP DRESSES',
    url: 'dresses/slip-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 69,
    pageName: 'Printed',
    h1: 'PRINTED DRESSES',
    url: 'dresses/printed-dresses/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 70,
    pageName: 'Day',
    h1: 'DAY DRESSES',
    url: 'dresses/day-dresses',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: 71,
    pageName: 'XS',
    h1: 'XS SIZE DRESSES',
    url: 'dresses/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 72,
    pageName: 'S',
    h1: 'S SIZE DRESSES',
    url: 'dresses/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 73,
    pageName: 'M',
    h1: 'M SIZE DRESSES',
    url: 'dresses/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 74,
    pageName: 'L',
    h1: 'L SIZE DRESSES',
    url: 'dresses/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 75,
    pageName: 'XL',
    h1: 'XL SIZE DRESSES',
    url: 'dresses/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 76,
    pageName: 'XS-S',
    h1: 'XS/S SIZE DRESSES',
    url: 'dresses/xs-s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 77,
    pageName: 'S-M',
    h1: 'S/M SIZE DRESSES',
    url: 'dresses/s-m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: 78,
    pageName: 'M-L',
    h1: 'M/L SIZE DRESSES',
    url: 'dresses/m-l/',
    type: 'category',
    filter: 'size',
  },
];
