import { Heading } from "./types";

export const hairAccessories: Heading[] = [
  {
    no: '169',
    pageName: 'Hair Accessories',
    h1: "WOMEN'S HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/',
    type: 'category',
    filter: '',
  },
  {
    no: '170',
    pageName: 'Hair Claws',
    h1: "WOMEN'S HAIR CLAWS",
    url: 'accessories/hair-accessories/hair-claws/',
    type: 'category',
    filter: '',
  },
  {
    no: '171',
    pageName: 'Pins',
    h1: "WOMEN'S HAIR PINS",
    url: 'accessories/hair-accessories/pins/',
    type: 'category',
    filter: '',
  },
  {
    no: '172',
    pageName: 'Clips',
    h1: "WOMEN'S HAIR CLIPS",
    url: 'accessories/hair-accessories/clips/',
    type: 'category',
    filter: '',
  },
  {
    no: '173',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/blue/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '174',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/brown/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '175',
    pageName: 'Clear',
    h1: "WOMEN'S CLEAR HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/clear/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '176',
    pageName: 'Gold-Bronze',
    h1: "WOMEN'S GOLD/BRONZE HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/gold-bronze/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '177',
    pageName: 'Green',
    h1: "WOMEN'S GREEN HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/green/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '178',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/multi-pattern/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '179',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/orange/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '180',
    pageName: 'Pink',
    h1: "WOMEN'S PINK HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '181',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '182',
    pageName: 'White',
    h1: "WOMEN'S WHITE HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/white/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '183',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/yellow/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '184',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/beach-holiday/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '185',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/brunch/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '186',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/casual/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '187',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGHT HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/date-night/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '188',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/festival/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '189',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/formal-cocktail/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '190',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '191',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '192',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '193',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN HAIR ACCESSORIES",
    url: 'accessories/hair-accessories/sequin/',
    type: 'category',
    filter: 'trend',
  },
];
