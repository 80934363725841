import { Heading } from "./types";

export const sleepwear: Heading[] = [
  {
    no: '586',
    pageName: 'Sleepwear',
    h1: "WOMEN'S SLEEPWEAR",
    url: 'womens-wear/sleepwear/',
    type: 'category',
    filter: '',
  },
  {
    no: '587',
    pageName: 'Grey',
    h1: "WOMEN'S GREY SLEEPWEAR",
    url: 'womens-wear/sleepwear/grey/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '588',
    pageName: 'Pink',
    h1: "WOMEN'S PINK SLEEPWEAR",
    url: 'womens-wear/sleepwear/pink/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '589',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE SLEEPWEAR",
    url: 'womens-wear/sleepwear/purple/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '590',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER SLEEPWEAR",
    url: 'womens-wear/sleepwear/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '591',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER SLEEPWEAR",
    url: 'womens-wear/sleepwear/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '592',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE SLEEPWEAR",
    url: 'womens-wear/sleepwear/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '593',
    pageName: 'S',
    h1: "WOMEN'S S SIZE SLEEPWEAR",
    url: 'womens-wear/sleepwear/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '594',
    pageName: 'M',
    h1: "WOMEN'S M SIZE SLEEPWEAR",
    url: 'womens-wear/sleepwear/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '595',
    pageName: 'L',
    h1: "WOMEN'S L SIZE SLEEPWEAR",
    url: 'womens-wear/sleepwear/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '596',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE SLEEPWEAR",
    url: 'womens-wear/sleepwear/xl/',
    type: 'category',
    filter: 'size',
  },
];
