import React from 'react';
import { ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Box, Divider, DownChevronIcon, Group, HorizontalSliderIcon } from '@ui/components/core';
import {
  CollectionFilterDrawer,
  CollectionSortDrawer,
  FilterBadge,
  FilterMenuGroup,
  NotificationBadge,
  Button,
} from '@ui/components/shared';
import { responsiveStyles } from '@ui/components/styles';
import { useRouter } from 'next/router';
import { generateFilters, getFiltersUrl, toggleFilter, updateFilters } from '@ui/helpers/filter';

type CollectionFilterSectionProps = {
  facets: Array<any>;
  sort: string;
  sortingOptions: any[];
};

const CollectionFilterSection = ({
  facets,
  sortingOptions,
  sort,
}: CollectionFilterSectionProps): React.ReactElement => {
  const [filterDrawerOpened, { toggle: filterDrawerOpen, close: filterDrawerClose }] = useDisclosure(false);
  const [sortDrawerOpened, { toggle: sortDrawerOpen, close: sortDrawerClose }] = useDisclosure(false);

  const responsive = responsiveStyles();
  const router = useRouter();
  const filters = generateFilters(router);
  const appliedFilters = filters.filter((filter) => !filter.startsWith('category_') || filter.includes('promo'));
  return (
    <>
      <Box>
        <Box className={responsive.classes.hiddenMobile}>
          <FilterMenuGroup facets={facets.filter((facet) => facet.label.toUpperCase() !== 'CATEGORY')} />
        </Box>
        <Group px="1rem" className={responsive.classes.hiddenDesktop} spacing="0.75rem" grow>
          <NotificationBadge
            color="brand.8"
            w="0.75rem"
            h="0.75rem"
            show={filters.some((filter) => !filter.startsWith('category_'))}
            animate={false}
          >
            <Button
              className="md:text-[13px] w-full font-normal justify-between"
              variant="outline"
              rightIcon={<HorizontalSliderIcon width={16} height={16} />}
              onClick={() => filterDrawerOpen()}
            >
              Filter
            </Button>
          </NotificationBadge>
          <Button
            className="font-normal md:text-[13px] justify-between"
            variant="outline"
            onClick={() => sortDrawerOpen()}
            rightIcon={<DownChevronIcon width={16} height={16} />}
          >
            Sort
          </Button>
        </Group>
        {appliedFilters.length > 0 && (
          <div className="mt-3.5 md:mt-3 flex pl-4 md:pl-0">
            <Button
              variant="outline"
              className="h-6 text-[10px] md:text-[10px] px-2 justify-between flex-shrink-0"
              onClick={() => {
                const newFilters = generateFilters(router).filter((f) => f.startsWith('category_'));
                updateFilters(router, newFilters);
              }}
            >{`CLEAR ALL (${appliedFilters.length})`}</Button>
            <ScrollArea w="100%" type="never" className="pl-2">
              <div className="flex items-center flex-nowrap space-x-2 pr-4 md:pr-0">
                {appliedFilters.map((filter) => (
                  <FilterBadge
                    key={filter}
                    value={filter}
                    closeHref={getFiltersUrl(router, toggleFilter(router, filter))}
                  />
                ))}
              </div>
            </ScrollArea>
          </div>
        )}
        <Divider
          mt="1.5rem"
          color="black.8"
          className={responsive.classes.hiddenDesktop}
          sx={{ borderTopWidth: '0.5px' }}
        />
      </Box>
      <CollectionFilterDrawer
        facets={facets.filter((facet) => facet.label.toUpperCase() !== 'CATEGORY')}
        opened={filterDrawerOpened}
        onClose={filterDrawerClose}
      />
      <CollectionSortDrawer
        sort={sort}
        sortingOptions={sortingOptions}
        opened={sortDrawerOpened}
        onClose={sortDrawerClose}
      />
    </>
  );
};

export default CollectionFilterSection;
