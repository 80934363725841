import { accessories } from './CollectionAccessories';
import { bags } from './CollectionBags.ts';
import { basicsClothing } from './CollectionBasicsClothing.ts';
import { blazers } from './CollectionBlazers.ts';
import { bodysuits } from './CollectionBodySuits';
import { boots } from './CollectionBoots.ts';
import { bottoms } from './CollectionBottoms.ts';
import { bracelets } from './CollectionBracelets.ts';
import { casualWear } from './CollectionCasualWear.ts';
import { children } from './CollectionChildren.ts';
import { coats } from './CollectionCoats.ts';
import { comfortClothing } from './CollectionComfortClothing.ts';
import { coverUps } from './CollectionCoverUps.ts';
import { cropTops } from './CollectionCropTops';
import { dresses } from './CollectionDresses';
import { earrings } from './CollectionEarrings.ts';
import { fauxFur } from './CollectionFauxFur.ts';
import { flats } from './CollectionFlats.ts';
import { hairAccessories } from './CollectionHairAccessories.ts';
import { heels } from './CollectionHeels.ts';
import { jackets } from './CollectionJackets.ts';
import { jeans } from './CollectionJeans';
import { jewellery } from './CollectionJewellery';
import { jumpsuits } from './CollectionJumpsuits.ts';
import { knitwear } from './CollectionKnitwear.ts';
import { legPants } from './CollectionLegPants';
import { loungewear } from './CollectionLoungewear.ts';
import { mules } from './CollectionMules.ts';
import { necklaces } from './CollectionNecklaces.ts';
import { onePieceSwimsuits } from './CollectionOnePieceSwimsuits.ts';
import { outerwear } from './CollectionOuterwear.ts';
import { pants } from './CollectionPants';
import { playsuits } from './CollectionPlaysuits.ts';
import { pleasureToys } from './CollectionPleasureToys.ts';
import { sandals } from './CollectionSandals.ts';
import { shoes } from './CollectionShoes.ts';
import { shorts } from './CollectionShorts';
import { skinnyPants } from './CollectionSkinnyPants';
import { skirt } from './CollectionSkirts';
import { sleepwear } from './CollectionSleepwear.ts';
import { slides } from './CollectionSlides.ts';
import { sneakers } from './CollectionSneakers.ts';
import { sunglasses } from './CollectionSunglasses.ts';
import { swimBottoms } from './CollectionSwimBottoms.ts';
import { swimTops } from './CollectionSwimTops.ts';
import { swimwear } from './CollectionSwimwear.ts';
import { tailoredPants } from './CollectionTailoredPants';
import { tops } from './CollectionTops';
import { twoPieceSets } from './CollectionTwoPieceSets.ts';
import { twoPiecesSwimsuits } from './CollectionTwoPiecesSwimsuits.ts';
import { wedges } from './CollectionWedges.ts';
import { womensBras } from './CollectionWomensBras.ts';
import { womensJumpers } from './CollectionWomensJumpers.ts';
import { womensWear } from './CollectionWomensWear.ts';
import { Heading } from './types.ts';

const headings: Record<string, Heading[]> = {
  accessories,
  bags,
  'basics-clothing': basicsClothing,
  blazers,
  'bodysuits-bralets': bodysuits,
  boots,
  bottoms,
  bracelets,
  'casual-bras': casualWear,
  children,
  coats,
  'comfort-clothing': comfortClothing,
  'cover-ups': coverUps,
  'crop-tops': cropTops,
  dresses,
  earrings,
  'faux-fur': fauxFur,
  flats,
  'hair-accessories': hairAccessories,
  heels,
  jackets,
  jeans: jeans,
  jewellery,
  jumpsuits,
  knitwear,
  loungewear,
  mules,
  necklaces,
  'one-piece-swimsuits': onePieceSwimsuits,
  outerwear,
  pants: pants,
  playsuits,
  'pleasure-toys': pleasureToys,
  sandals,
  shoes,
  shorts: shorts,
  'skinny-pants': skinnyPants,
  skirts: skirt,
  sleepwear,
  slides,
  sneakers,
  sunglasses,
  'swim-bottoms': swimBottoms,
  'swim-tops': swimTops,
  swimwear,
  'tailored-pants': tailoredPants,
  tops,
  'two-piece-sets': twoPieceSets,
  'two-pieces-swimsuits': twoPiecesSwimsuits,
  wedges,
  'wide-leg-pants': legPants,
  'womens-bras': womensBras,
  'womens-jumpers': womensJumpers,
  'womens-wear': womensWear,
};

export default headings;
