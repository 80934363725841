import { Heading } from "./types";

export const pants: Heading[] = [
  {
    no: '128',
    pageName: 'Pants',
    h1: "WOMEN'S PANTS",
    url: 'bottoms/womens-pants/',
    type: 'category',
    filter: '',
  },
  {
    no: '190',
    pageName: 'Beach-&-Holiday',
    h1: "WOMEN'S BEACH & HOLIDAY PANTS",
    url: 'bottoms/womens-pants/beach-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '191',
    pageName: 'Brunch',
    h1: "WOMEN'S BRUNCH PANTS",
    url: 'bottoms/womens-pants/brunch-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '192',
    pageName: 'Casual',
    h1: "WOMEN'S CASUAL PANTS",
    url: 'bottoms/womens-pants/casual-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '193',
    pageName: 'Date-Night',
    h1: "WOMEN'S DATE NIGH PANTS",
    url: 'bottoms/womens-pants/date-night-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '194',
    pageName: 'Festival',
    h1: "WOMEN'S FESTIVAL PANTS",
    url: 'bottoms/womens-pants/festival-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '195',
    pageName: 'Formal-&-Cocktail',
    h1: "WOMEN'S FORMAL & COCKTAIL PANTS",
    url: 'bottoms/womens-pants/cocktail-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '196',
    pageName: 'Party',
    h1: "WOMEN'S PARTY PANTS",
    url: 'bottoms/womens-pants/party-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '197',
    pageName: 'Racing',
    h1: "WOMEN'S RACING PANTS",
    url: 'bottoms/womens-pants/racing-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '198',
    pageName: 'Spring-Break',
    h1: "WOMEN'S SPRING BREAK PANTS",
    url: 'bottoms/womens-pants/spring-break/',
    type: 'category',
    filter: 'occasion',
  },
  {
    no: '199',
    pageName: 'Work',
    h1: "WOMEN'S WORK PANTS",
    url: 'bottoms/womens-pants/work-pants/',
    type: 'filter/category',
    filter: 'occasion',
  },
  {
    no: '200',
    pageName: 'Beige-Cream',
    h1: "WOMEN'S BEIGE/CREAM PANTS",
    url: 'bottoms/womens-pants/beige-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '201',
    pageName: 'Black',
    h1: "WOMEN'S BLACK PANTS",
    url: 'bottoms/womens-pants/black-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '202',
    pageName: 'Blue',
    h1: "WOMEN'S BLUE PANTS",
    url: 'bottoms/womens-pants/blue-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '203',
    pageName: 'Brown',
    h1: "WOMEN'S BROWN PANTS",
    url: 'bottoms/womens-pants/brown-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '204',
    pageName: 'Denim',
    h1: "WOMEN'S DENIM PANTS",
    url: 'bottoms/womens-pants/denim/',
    type: 'category',
    filter: 'color',
  },
  {
    no: '205',
    pageName: 'Green',
    h1: "WOMEN'S GREEN PANTS",
    url: 'bottoms/womens-pants/green-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '206',
    pageName: 'Grey',
    h1: "WOMEN'S BLUE PANTS",
    url: 'bottoms/womens-pants/grey-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '207',
    pageName: 'Multicolour',
    h1: "WOMEN'S MULTICOLOUR PANTS",
    url: 'bottoms/womens-pants/multicolour-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '208',
    pageName: 'Orange',
    h1: "WOMEN'S ORANGE PANTS",
    url: 'bottoms/womens-pants/orange-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '209',
    pageName: 'Peach',
    h1: "WOMEN'S PEACH PANTS",
    url: 'bottoms/womens-pants/peach-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '210',
    pageName: 'Pink',
    h1: "WOMEN'S PINK PANTS",
    url: 'bottoms/womens-pants/pink-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '211',
    pageName: 'Purple',
    h1: "WOMEN'S PURPLE PANTS",
    url: 'bottoms/womens-pants/purple-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '212',
    pageName: 'Red',
    h1: "WOMEN'S RED PANTS",
    url: 'bottoms/womens-pants/red-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '213',
    pageName: 'White',
    h1: "WOMEN'S WHITE PANTS",
    url: 'bottoms/womens-pants/white-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '214',
    pageName: 'Yellow',
    h1: "WOMEN'S YELLOW PANTS",
    url: 'bottoms/womens-pants/yellow-pants/',
    type: 'filter/category',
    filter: 'color',
  },
  {
    no: '215',
    pageName: 'Autumn',
    h1: "WOMEN'S AUTUMN PANTS",
    url: 'bottoms/womens-pants/autumn/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '216',
    pageName: 'Spring',
    h1: "WOMEN'S SPRING PANTS",
    url: 'bottoms/womens-pants/spring/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '217',
    pageName: 'Summer',
    h1: "WOMEN'S SUMMER PANTS",
    url: 'bottoms/womens-pants/summer/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '218',
    pageName: 'Winter',
    h1: "WOMEN'S WINTER PANTS",
    url: 'bottoms/womens-pants/winter/',
    type: 'category',
    filter: 'season',
  },
  {
    no: '219',
    pageName: 'Long-Sleeved',
    h1: "WOMEN'S LONG SLEEVED PANTS",
    url: 'bottoms/womens-pants/long-sleeved/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '220',
    pageName: 'Sleeveless',
    h1: "WOMEN'S SLEEVELESS PANTS",
    url: 'bottoms/womens-pants/sleeveless/',
    type: 'category',
    filter: 'sleeves',
  },
  {
    no: '221',
    pageName: 'Bodycon',
    h1: "WOMEN'S BODYCON PANTS",
    url: 'bottoms/womens-pants/bodycon-pants/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '222',
    pageName: 'Cargo',
    h1: "WOMEN'S CARGO PANTS",
    url: 'bottoms/womens-pants/cargo/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '223',
    pageName: 'High-Waisted',
    h1: "WOMEN'S HIGH WAISTED PANTS",
    url: 'bottoms/womens-pants/high-waisted/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '224',
    pageName: 'Satin',
    h1: "WOMEN'S SATIN PANTS",
    url: 'bottoms/womens-pants/satin/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '225',
    pageName: 'Sequin',
    h1: "WOMEN'S SEQUIN PANTS",
    url: 'bottoms/womens-pants/sequin-pants/',
    type: 'filter/category',
    filter: 'trend',
  },
  {
    no: '226',
    pageName: 'Straight Leg',
    h1: "WOMEN'S STRAIGHT LEG PANTS",
    url: 'bottoms/womens-pants/straight-leg/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '227',
    pageName: 'Wide Leg',
    h1: "WOMEN'S WIDE LEG PANTS",
    url: 'bottoms/womens-pants/wide-leg/',
    type: 'category',
    filter: 'trend',
  },
  {
    no: '228',
    pageName: 'XS',
    h1: "WOMEN'S XS SIZE PANTS",
    url: 'bottoms/womens-pants/xs/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '229',
    pageName: 'S',
    h1: "WOMEN'S S SIZE PANTS",
    url: 'bottoms/womens-pants/s/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '230',
    pageName: 'M',
    h1: "WOMEN'S M SIZE PANTS",
    url: 'bottoms/womens-pants/m/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '231',
    pageName: 'L',
    h1: "WOMEN'S L SIZE PANTS",
    url: 'bottoms/womens-pants/l/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '232',
    pageName: 'XL',
    h1: "WOMEN'S XL SIZE PANTS",
    url: 'bottoms/womens-pants/xl/',
    type: 'category',
    filter: 'size',
  },
  {
    no: '233',
    pageName: '7',
    h1: "WOMEN'S 7 SIZE PANTS",
    url: 'bottoms/womens-pants/7/',
    type: 'category',
    filter: 'size',
  },
];
